import { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";

import classNames from "classnames";
import { MEDIA_PLANS_ROUTE } from "common/const";
import { handleDate } from "common/utils/handleDate";
import { Pagination } from "components/UI/Pagination";
import { MediaUpload } from "services/store/reducers/media/types";

import commonStyles from "../common-styles.module.sass";
import { columns } from "./const";
import styles from "./styles.module.sass";

interface Data {
  file: JSX.Element;
  date: string;
  status: JSX.Element;
  media: JSX.Element;
}

interface Props {
  data: MediaUpload[] | null;
}

export default function MediaPlanTable({ data }: Props) {
  const [page, setPage] = useState(0);
  const [rowsPerPage] = useState(4);

  const navigate = useNavigate();

  const { pathname } = useLocation();

  const rows: Data[] = data
    ? data.map((row: MediaUpload) => {
        return {
          file: <>{row.recr_plan_id}</>,
          date: handleDate(row.uploaded_at, true, true),
          status: <>{row.uploader}</>,
          media: <>{row.id}</>,
        };
      })
    : [];

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  return (
    <>
      <Paper className={commonStyles.table}>
        <TableContainer sx={{ maxHeight: 440 }}>
          <Table stickyHeader aria-label="sticky table">
            <TableHead>
              <TableRow>
                {columns.map((column, id) => (
                  <TableCell className={commonStyles.tableHead} key={id}>
                    {column.label}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {rows
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row, rowId) => {
                  return (
                    <TableRow role="checkbox" tabIndex={-1} key={rowId} hover>
                      {columns.map((column) => {
                        const value = row[column.id];
                        const tableCellClasses = classNames(
                          commonStyles.dataCell,
                          (column.id === "file" || column.id === "media") &&
                            styles.clickable,
                          column.id === "filteredMedia" && styles.centered,
                        );
                        return (
                          <TableCell
                            className={tableCellClasses}
                            onClick={(): void => {
                              data && column.id === "media"
                                ? pathname === MEDIA_PLANS_ROUTE
                                  ? navigate(
                                      `/mediaplan-multi?id=${Number(data[rowId + page * rowsPerPage].id)}&uploader=${data[rowId + page * rowsPerPage].uploader}`,
                                    )
                                  : navigate(
                                      `${pathname}/${Number(data[rowId + page * rowsPerPage].id)}`,
                                    )
                                : data && column.id === "file"
                                  ? navigate(
                                      `/mediaplan?plan=selection&id=${Number(data[rowId + page * rowsPerPage].recr_plan_id)}`,
                                    )
                                  : navigate("#");
                            }}
                            key={column.id}
                          >
                            {value}
                          </TableCell>
                        );
                      })}
                    </TableRow>
                  );
                })}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
      <Pagination
        total={rows.length}
        limit={rowsPerPage}
        currentPage={page}
        onPageChange={handleChangePage}
        className={commonStyles.pagination}
      />
    </>
  );
}
