import { API_URL, AUTH_API_URL } from 'api/endpoint';
import axios from 'axios';
import { getAccessToken, getRefreshToken } from 'shared/config/auth/lib/getTokens';

// Создаем экземпляр axios
const axiosInstance = axios.create({
  baseURL: API_URL,
});

// Добавляем интерсептор для запросов
axiosInstance.interceptors.request.use((config) => {
  const accessToken = getAccessToken();
  if (accessToken) {
    config.headers['Authorization'] = `Bearer ${accessToken}`;
  }
  return config;
});

// Добавляем интерсептор для ответов
axiosInstance.interceptors.response.use(
  (response) => response, // Если запрос успешный, просто возвращаем ответ
  async (error) => {
    const originalRequest = error.config;

    if ((error.response?.status === 401 || error.response?.status === 403) && !originalRequest._retry) {
      originalRequest._retry = true;
      try {
        const refreshToken = getRefreshToken(); // Получаем refresh token из хранилища
        if (!refreshToken) {
          window.location.replace('/login')
          throw new Error('Отсутствует refresh token');
        }

        // Отправляем запрос на обновление токена
        const {data} = await axios.post(`${AUTH_API_URL}/v1/auth/refresh_token?refresh_token=${refreshToken}`);

        const newAccessToken = data.access_token;
        const newRefreshToken = data.refresh_token; // Предположим, сервер возвращает оба токена

        // Обновляем токены в хранилище
        document.cookie = `access_token=${newAccessToken}; path=/; max-age=${900}`;
        document.cookie = `refresh_token=${newRefreshToken}; path=/; max-age=${3600 * 24}`;

        // Повторяем исходный запрос с новым токеном
        originalRequest.headers['Authorization'] = `Bearer ${newAccessToken}`;
        return axiosInstance(originalRequest);
      } catch (refreshError) {
        console.error('Ошибка обновления токена:', refreshError);
        window.location.replace('/login')
      }
    }
    return Promise.reject(error);
  }
);

export default axiosInstance;