import { createSlice } from "@reduxjs/toolkit";
import { StateType } from "./types";
import { getProjects, setProject } from "./actions";

const initialState: StateType = {
  getProjectData: null,
  getProjectLoading: false,
  getProjectError: null,
  setProjectData: null,
  setProjectLoading: false,
  setProjectError: null,
};

const projectSlice = createSlice({
  name: 'project',
  initialState,
  reducers: {
    clearSetProjectData: (state) => {
      state.setProjectData = null;
      state.setProjectError = null;
    }
  },
  extraReducers: (builder) => {
    builder.addCase(getProjects.pending, state => {
      state.getProjectLoading = true;
      state.getProjectError = null;
    })
    .addCase(getProjects.fulfilled, (state, action) => {
      state.getProjectLoading = false;
      state.getProjectData = action.payload[0];
    })
    .addCase(getProjects.rejected, (state, action) => {
      state.getProjectLoading = false;
      if (typeof action.payload === "string")
        state.getProjectError = action.payload;
    });
    builder.addCase(setProject.pending, state => {
      state.setProjectLoading = true;
      state.setProjectError = null;
    })
    .addCase(setProject.fulfilled, (state, action) => {
      state.setProjectLoading = false;
      state.setProjectData = action.payload;
    })
    .addCase(setProject.rejected, (state, action) => {
      state.setProjectLoading = false;
      if (typeof action.payload === "string")
        state.setProjectError = action.payload;
    });
  }
})

export const { clearSetProjectData } = projectSlice.actions;
export default projectSlice.reducer;