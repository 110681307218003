import { z } from 'zod';

export const createSchema = (
  fields: string[],
) => z.object(
  fields.reduce((acc, field) => {
    acc[field] = z.boolean();
    return acc;
  }, {}),
);
