import { useEffect, useState } from "react";
import { useNavigate } from "react-router";

import BasicSelect from "components/UI/Select";
import { changeClientId } from "services/store/reducers/globalParams";
import { useAppDispatch, useAppSelector } from "services/store/store";

import styles from "./styles.module.sass";

const clients = [
  { name: "client103", value: "103" },
  { name: "client111", value: "111" },
  { name: "client112", value: "112" },
  { name: "client113", value: "113" },
  { name: "client114", value: "114" },
  { name: "MTS", value: "66" },
];

export default function SelectClient() {
  const [client, setClient] = useState("");
  const { role, clientId } = useAppSelector((state) => state.globalParams);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    if (clientId) {
      setClient(clientId.toString());
    }
  }, [clientId]);

  const handleChangeClient = (value: string) => {
    dispatch(changeClientId(parseInt(value)));
    navigate("/");
  };

  return (
    role === "admin" && (
      <BasicSelect
        options={clients}
        label="Выберите клиента"
        select={client}
        setSelect={handleChangeClient}
        className={styles.select}
      />
    )
  );
}
