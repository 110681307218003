import { FormEvent, useEffect, useMemo, useState } from "react";
import { useLocation, useNavigate } from "react-router";

import { FloatingLabelInput } from "components/UI/FloatingLabelInput";

interface Values {
  uploader_name: string;
  client_id: string;
  role: string;
  client_name: string;
}

const URLInputs: React.FC = (): JSX.Element => {
  const { search } = useLocation();
  const navigate = useNavigate();

  const initialValues = useMemo(() => {
    const urlParams = new URLSearchParams(search);
    return {
      uploader_name: urlParams.get("uploader_name") ?? "",
      client_name: urlParams.get("client_name") ?? "",
      client_id: urlParams.get("client_id") ?? "0",
      role: urlParams.get("role") ?? "",
    };
  }, [search]);

  const [values, setValues] = useState<Values>(initialValues);

  const changeValue = (e: FormEvent<HTMLDivElement>, label: keyof Values) => {
    if ("value" in e.target && typeof e.target.value === "string") {
      const { value } = e.target;
      setValues((prev) => ({ ...prev, [label]: value }));

      const params = new URLSearchParams(window.location.search);
      params.set(label, value);
      navigate(`?${params.toString()}`, { replace: true });
    }
  };

  useEffect(() => {
    setValues(initialValues);
  }, [initialValues]);

  return (
    <div className="hidden">
      <FloatingLabelInput
        onChange={(e) => changeValue(e, "uploader_name")}
        value={values.uploader_name}
        label="uploader_name"
        theme="white"
      />
      <FloatingLabelInput
        onChange={(e) => changeValue(e, "client_id")}
        value={values.client_id}
        label="client_id"
        theme="white"
      />
      <FloatingLabelInput
        onChange={(e) => changeValue(e, "role")}
        label="role"
        value={values.role}
        theme="white"
      />
      <FloatingLabelInput
        onChange={(e) => changeValue(e, "client_name")}
        value={values.client_name}
        label="client_name"
        theme="white"
      />
    </div>
  );
};
export default URLInputs;
