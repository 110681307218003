import { MediaPlanMultiColumnId } from "common/const";
import { Record } from "services/store/reducers/media/types";

export const FILTERED_KEYS: MediaPlanMultiColumnId[] = [
  "region",
  "city",
  "profile",
];

export const INITIAL_EMPTY_ROW: Record = {
  budgets: 0,
  client_id: 0,
  callbacks_count: 0,
  city: "",
  need: 0,
  data: [],
  factors_1_count: 0,
  factors_2_count: 0,
  factors_3_count: 0,
  profile: "",
};
