import { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";

import { Box, Modal as MUIModal, Typography } from "@mui/material";

import classNames from "classnames";
import {
  CANDIDATES_DATA_COLUMNS,
  CANDIDATES_FILTERS,
  EXCEL_ACCEPT_FORMAT,
  TEMPLATE_CANDIDATES_URL,
} from "common/const";
import useNotify from "common/hooks/useNotify";
import useSetMessages from "common/hooks/useSetMessages";
import { useUploadHandler } from "common/hooks/useUploadHandler";
import { UploadMessage } from "common/interfaces";
import { addOptionalParams } from "common/utils/addOptionalParams";
import { Filters, FilterValues } from "components/Filters";
import CandidatesDataTable from "components/tables/IssuedTable";
import { H1 } from "components/typography";
import { Button } from "components/UI/Button";
import { Message } from "components/UI/Message";
import Upload from "components/UI/Upload";
import { clearData } from "services/store/reducers/issued";
import {
  fetchCandidatesData,
  postCandidatesData,
} from "services/store/reducers/issued/actions";
import { FetchCandidatesDataParams } from "services/store/reducers/issued/types";
import { useAppDispatch, useAppSelector } from "services/store/store";
import useSessionStorageState from "use-session-storage-state";

import commonStyles from "../common-styles.module.sass";
import styles from "./styles.module.sass";

const IssuedPage: React.FC = (): JSX.Element => {
  const { uploaderName, clientId } = useAppSelector(
    (state) => state.globalParams,
  );

  const {
    uploadCandidatesData,
    uploadCandidatesLoading,
    uploadCandidatesError,
  } = useAppSelector((state) => state.issued);

  const [uploadMessage, setUploadMessage] =
    useSessionStorageState<UploadMessage | null>("upload-message-issued");
  const { fileName, file, handleUpload } = useUploadHandler();
  const { ToastContainer, notifyUploading } = useNotify();
  const dispatch = useAppDispatch();
  const downloadRef = useRef<HTMLAnchorElement>(null);
  const uploadRef = useRef<HTMLInputElement>(null);
  const [loadModalOpen, setLoadModalOpen] = useState(false);

  const handleDownloadClick = (): void => {
    if (downloadRef.current) downloadRef.current.click();
  };

  const handleUploadClick = (): void => {
    if (uploadRef.current) uploadRef.current.click();
  };

  const handleClearMessage = (): void => {
    setUploadMessage(null);
  };

  const handleSaveClick = (): void => {
    if (file && !uploadCandidatesLoading) {
      setUploadMessage(null);
      const params = {
        client_id: clientId,
        uploader_name: uploaderName,
      };
      dispatch(postCandidatesData({ params, file }));
    }
  };

  useSetMessages({
    data: uploadCandidatesData,
    fileName,
    notifyUploading,
    setUploadMessage,
    error: uploadCandidatesError,
  });

  const handleUpdateFilters = (filterValues: FilterValues): void => {
    const sendParams: FetchCandidatesDataParams = {
      client_id: clientId,
    };
    addOptionalParams(sendParams, filterValues);
    dispatch(fetchCandidatesData(sendParams));
  };

  useEffect(() => {
    dispatch(
      fetchCandidatesData({
        client_id: clientId,
      }),
    );

    return () => {
      dispatch(clearData());
    };
  }, [dispatch, clientId]);

  return (
    <>
      <section className={classNames(commonStyles.heading)}>
        <H1 color="#0C3953">Оформленные</H1>
        <Filters
          onUpdateFilters={handleUpdateFilters}
          configuration={CANDIDATES_FILTERS}
        />
      </section>
      <section className={styles.upload}>
        <Button variant="download" onClick={() => setLoadModalOpen(true)}>
          Загрузка
        </Button>
        <Button variant="primary">Обновить из CRM</Button>
        {uploadMessage && (
          <Message
            text={uploadMessage.message}
            success={uploadMessage.success}
            close={handleClearMessage}
          />
        )}
      </section>
      <section>
        <CandidatesDataTable columns={CANDIDATES_DATA_COLUMNS} />
      </section>
      <MUIModal open={loadModalOpen} onClose={() => setLoadModalOpen(false)}>
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            transform: "translate(-50%, -50%)",
            width: 400,
            bgcolor: "background.paper",
            border: "2px solid #000",
            boxShadow: 24,
            p: 4,
            borderRadius: 5,
          }}
        >
          <Typography variant="h6" component="h2" sx={{ marginBottom: 2 }}>
            Загрузка
          </Typography>
          <div>
            <Link
              ref={downloadRef}
              to={TEMPLATE_CANDIDATES_URL}
              style={{ display: "none" }}
            />
            <Button
              style={{ margin: "0 auto", width: "100%" }}
              variant="download"
              onClick={handleDownloadClick}
            >
              Скачать шаблон XLSX
            </Button>
            <div className={styles.confirmation}>
              <Upload
                forwardRef={uploadRef}
                id="upload"
                onUpload={handleUpload}
                accept={EXCEL_ACCEPT_FORMAT}
                label={fileName}
              >
                <Button
                  variant="upload"
                  loading={uploadCandidatesLoading}
                  disabled={uploadCandidatesLoading}
                  onClick={handleUploadClick}
                >
                  Выберите файл
                </Button>
              </Upload>
            </div>
          </div>
          <div className="flex flex-row w-full mt-10 justify-between px-6">
            <Button
              variant="primary"
              onClick={handleSaveClick}
              loading={uploadCandidatesLoading}
              disabled={uploadCandidatesLoading}
            >
              Сохранить
            </Button>
            <Button variant="download" onClick={() => setLoadModalOpen(false)}>
              Закрыть
            </Button>
          </div>
        </Box>
      </MUIModal>
      <ToastContainer />
    </>
  );
};

export default IssuedPage;
