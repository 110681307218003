import { combineReducers } from "@reduxjs/toolkit";
import { mediaPlanReducer } from "./media";
import { wsDataReducer } from "./ws-data";
import { settingsReducer } from "./settings";
import { globalParamsReducer } from "./globalParams";
import { issuedReducer } from "./issued";
import recruitmentReducer from "./recruitment"
import configReducer from "./config"
import authReducer from './authentication';
import projectReducer from './projects';
import objectReducer from './objects';
import hirePlanReducer from './hirePlan';
import { mappingReducer } from "./mapping";

const rootReducer = combineReducers({
  mediaplan: mediaPlanReducer,
  wsdata: wsDataReducer,
  issued: issuedReducer,
  settings: settingsReducer,
  globalParams: globalParamsReducer,
  auth: authReducer,
  recr: recruitmentReducer,
  config: configReducer,
  project: projectReducer,
  objects: objectReducer,
  hirePlan: hirePlanReducer,
  mapping: mappingReducer,
});

export type RootState = ReturnType<typeof rootReducer>;

export default rootReducer;
