const Dixi: React.FC = (): JSX.Element => (
  <main className="flex flex-col w-full overflow-hidden flex-grow h-screen">
    <iframe
      className="h-full"
      src="https://datalens.yandex/b2waaqeojik2x"
      title="HR-Rocket"
    ></iframe>
  </main>
);

export default Dixi;
