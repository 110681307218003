import { Column } from "./types";

export const columns: readonly Column[] = [
  {id: 'region', label: 'Регион'},
  {id: 'city', label: 'Город'},
  {id: 'profile', label: 'Профиль'},
  {id: 'need', label: 'Потребность(план)'},
  {id: 'callbacks_count', label: 'Лидов в месяц'},
  {id: 'lead_cost', label: 'Стоимость лида, руб.'},
  {id: 'budgets', label: 'Бюджет в неделю'},
  {id: 'predict', label: 'Прогноз оформленных в месяц'}
]