import { toast, ToastContainer } from "react-toastify";

import {
  ERROR_FILE_UPLOAD,
  ERROR_LEARNING_START,
  SUCCESS_FILE_UPLOAD,
  SUCCESS_LEARNING_START,
} from "common/const";

import "react-toastify/dist/ReactToastify.css";

import { useCallback } from "react";

export default function useNotify() {
  const notifyUploading = useCallback(
    (name: string, type: "success" | "error") =>
      toast[type](
        `${name} - ${type === "success" ? SUCCESS_FILE_UPLOAD : ERROR_FILE_UPLOAD}`,
        {
          position: "bottom-right",
          autoClose: 5000,
        },
      ),
    [],
  );

  const notifyMlStart = useCallback(
    (name: string, type: "success" | "error") =>
      toast[type](
        `${name} - ${type === "success" ? SUCCESS_LEARNING_START : ERROR_LEARNING_START}`,
        {
          position: "bottom-right",
          autoClose: 5000,
        },
      ),
    [],
  );

  const notifyClusterisationStart = useCallback(
    (type: "success" | "error") =>
      toast[type](
        `${
          type === "success"
            ? "Кластеризация успешно завершена, обновите страницу"
            : "Не удалось запустить кластеризацию"
        }`,
        {
          position: "bottom-right",
          autoClose: 5000,
        },
      ),
    [],
  );

  const notifyProjectSet = useCallback(
    (type: "success" | "error") =>
      toast[type](
        `${
          type === "success"
            ? "Проект успешно добавлен"
            : "Не удалось добавить проект"
        }`,
        {
          position: "bottom-right",
          autoClose: 3000,
        },
      ),
    [],
  );

  return {
    notifyUploading,
    notifyMlStart,
    notifyClusterisationStart,
    notifyProjectSet,
    ToastContainer,
  };
}
