// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.styles_pagination__\\+dxV3 {
  display: flex;
  justify-content: flex-end;
  line-height: 20px;
  color: #0C3953;
  font-size: 14px;
  gap: 32px;
}

.styles_pages__fJP1O {
  display: flex;
  gap: 4px;
}

.styles_pageItem__kzZIe {
  width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.styles_active__mukMt {
  color: #FFFFFF;
  background: #217EC6;
  border-radius: 4px;
}

.styles_clickable__ovapC {
  cursor: pointer;
  border-radius: 8px;
  transition: 0.3s background-color;
}
.styles_clickable__ovapC:hover {
  background-color: #9bc3ff;
}

.styles_arrow__SG3Tb {
  background: transparent;
  display: inline-flex;
  padding: 0;
  height: auto;
}
.styles_arrow__SG3Tb, .styles_arrow__SG3Tb span {
  max-height: 24px;
}

.styles_reversed__8I7lD {
  rotate: 180deg;
}`, "",{"version":3,"sources":["webpack://./src/components/UI/Pagination/styles.module.sass"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,yBAAA;EACA,iBAAA;EACA,cAAA;EACA,eAAA;EACA,SAAA;AACF;;AACA;EACE,aAAA;EACA,QAAA;AAEF;;AAAA;EACE,WAAA;EACA,YAAA;EACA,aAAA;EACA,mBAAA;EACA,uBAAA;AAGF;;AADA;EACE,cAAA;EACA,mBAAA;EACA,kBAAA;AAIF;;AAFA;EACE,eAAA;EACA,kBAAA;EACA,iCAAA;AAKF;AAHE;EACE,yBAAA;AAKJ;;AAHA;EACE,uBAAA;EACA,oBAAA;EACA,UAAA;EACA,YAAA;AAMF;AAJE;EACE,gBAAA;AAMJ;;AAJA;EACE,cAAA;AAOF","sourcesContent":[".pagination\n  display: flex\n  justify-content: flex-end\n  line-height: 20px\n  color: #0C3953\n  font-size: 14px\n  gap: 32px\n\n.pages\n  display: flex\n  gap: 4px\n\n.pageItem\n  width: 24px\n  height: 24px\n  display: flex\n  align-items: center\n  justify-content: center\n\n.active\n  color: #FFFFFF\n  background: #217EC6\n  border-radius: 4px\n\n.clickable\n  cursor: pointer\n  border-radius: 8px\n  transition: 0.3s background-color\n\n  &:hover\n    background-color: #9bc3ff\n\n.arrow\n  background: transparent\n  display: inline-flex\n  padding: 0\n  height: auto\n\n  &, & span\n    max-height: 24px\n\n.reversed\n  rotate: 180deg\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"pagination": `styles_pagination__+dxV3`,
	"pages": `styles_pages__fJP1O`,
	"pageItem": `styles_pageItem__kzZIe`,
	"active": `styles_active__mukMt`,
	"clickable": `styles_clickable__ovapC`,
	"arrow": `styles_arrow__SG3Tb`,
	"reversed": `styles_reversed__8I7lD`
};
export default ___CSS_LOADER_EXPORT___;
