// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.styles_paper__SMiiK {
  box-shadow: 0px 2px 12px rgba(26, 61, 150, 0.0784313725) !important;
  font-family: "Montserrat", sans-serif;
}

.styles_tableHeadCell__R\\+\\+q1, .styles_tableBodyCell__xuaKK {
  font-size: 13px !important;
  line-height: 14px !important;
  padding: 12.5px 16px !important;
}

.styles_tableHeadCell__R\\+\\+q1 {
  color: #54687D !important;
  border-bottom: 1px solid #C6CED8 !important;
}

.styles_tableBodyCell__xuaKK {
  color: #0C3953 !important;
  font-weight: 400 !important;
  border-bottom: 1px solid #f2f2f2 !important;
}`, "",{"version":3,"sources":["webpack://./src/components/tables/ListObjectsTable/styles.module.sass"],"names":[],"mappings":"AAAA;EACE,mEAAA;EACA,qCAAA;AACF;;AACA;EACE,0BAAA;EACA,4BAAA;EACA,+BAAA;AAEF;;AAAA;EACE,yBAAA;EACA,2CAAA;AAGF;;AADA;EACE,yBAAA;EACA,2BAAA;EACA,2CAAA;AAIF","sourcesContent":[".paper\n  box-shadow: 0px 2px 12px #1A3D9614 !important\n  font-family: 'Montserrat', sans-serif\n\n.tableHeadCell, .tableBodyCell\n  font-size: 13px !important\n  line-height: 14px !important\n  padding: 12.5px 16px !important\n\n.tableHeadCell\n  color: #54687D !important\n  border-bottom: 1px solid #C6CED8 !important\n\n.tableBodyCell\n  color: #0C3953 !important\n  font-weight: 400 !important\n  border-bottom: 1px solid #f2f2f2 !important\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"paper": `styles_paper__SMiiK`,
	"tableHeadCell": `styles_tableHeadCell__R++q1`,
	"tableBodyCell": `styles_tableBodyCell__xuaKK`
};
export default ___CSS_LOADER_EXPORT___;
