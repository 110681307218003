import { createAsyncThunk } from "@reduxjs/toolkit";
import { SendUploadConfigParams } from "./types";
import { handleParams } from "common/utils/handleParams";
import axios from "axios";
import axiosInstance from "services/store/axiosInstance";

export const postUploadConfig = createAsyncThunk(
  "media/postUploadConfig",
  async (params: SendUploadConfigParams, { rejectWithValue }) => {
    try {
      const urlParams = handleParams(params);
      const response = await axiosInstance.post(
        `/config/upload-config${urlParams}`
      );
      return response.data;
    } catch (error) {
      return axios.isAxiosError(error) && error.response
        ? rejectWithValue(error.response.data)
        : rejectWithValue(error.message);
    }
  },
);