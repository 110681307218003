import { createAsyncThunk } from "@reduxjs/toolkit";
import axios, { AxiosResponse } from "axios";
import { HirePlan } from "./types";
import { PUBLISH_API_URL } from "api/endpoint";
import axiosInstance from "services/store/axiosInstance";

export const getHirePlan = createAsyncThunk(
  "hirePlan/getHirePlan",
  async (client_id: number, { rejectWithValue}) => {
    try {
      const response: AxiosResponse<HirePlan[]> = await axiosInstance.get(
        `${PUBLISH_API_URL}/hire_plan?client_id=${client_id}`
      );
      return response.data;
    } catch (error) {
      return axios.isAxiosError(error) && error.response
       ? rejectWithValue(error.response.data)
        : rejectWithValue(error.message);
    }
  }
)