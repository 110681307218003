import { createAsyncThunk } from "@reduxjs/toolkit";
import { AUTH_API_URL } from "api/endpoint";
import axios from "axios";
import { getTokenData } from "./types";
import { handleParams } from "common/utils/handleParams";

export const userLogin = createAsyncThunk(
  'auth/login',
  async (loginData: string, {rejectWithValue}) => {
    try {
      const config = {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded'
        },
      }
      const {data}: {data: getTokenData} = await axios.post(`${AUTH_API_URL}/v1/auth/token`, loginData, config);
      document.cookie = `access_token=${data.access_token}; path=/; max-age=${900}`;
      document.cookie = `refresh_token=${data.refresh_token}; path=/; max-age=${3600 * 24}`;
      return data
    } catch (error) {
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message)
      } else {
        return rejectWithValue(error.message)
      }
    }
  }
)

export const refreshAccessToken = createAsyncThunk(
  'auth/token',
  async (refreshToken: string, {rejectWithValue}) => {
    try {
      const params = handleParams({refresh_token: refreshToken});
      const { data }: {data: getTokenData } = await axios.post(`${AUTH_API_URL}/v1/auth/refresh_token${params}`);
      document.cookie = `access_token=${data.access_token}; path=/; max-age=${900}`;
      document.cookie = `refresh_token=${data.refresh_token}; path=/; max-age=${3600 * 24}`;
      return data;
    } catch (error) {
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message)
      } else {
        return rejectWithValue(error.message)
      }
    }
  }
)