import { useEffect, useRef, useState } from "react";

import classNames from "classnames";
import {
  EMPTY_DATA,
  EXCEL_ACCEPT_FORMAT,
  MEDIAPLANS_FILTERS,
  SOURCE_OPTIONS,
} from "common/const";
import { useModal } from "common/hooks/useModal";
import useNotify from "common/hooks/useNotify";
import useSetMessages from "common/hooks/useSetMessages";
import { useUploadHandler } from "common/hooks/useUploadHandler";
import { UploadMessage } from "common/interfaces";
import { addOptionalParams } from "common/utils/addOptionalParams";
import { handleDate } from "common/utils/handleDate";
import { checkOnPositiveInteger } from "common/utils/zodValidators";
import { ConfigForm } from "components/ConfigMediaplan";
import { Filters, FilterValues } from "components/Filters";
import MediaPlanTable from "components/tables/MediaPlanTable";
import PlanUploadsTable from "components/tables/PlanUploadsTable";
import { H1, H2, H3 } from "components/typography";
import { Button } from "components/UI/Button";
import { Loader } from "components/UI/Loader";
import { Message } from "components/UI/Message";
import { Modal } from "components/UI/Modal";
import BasicSelect from "components/UI/Select";
import Upload from "components/UI/Upload";
import { setConfigData } from "services/store/reducers/config";
import { postUploadConfig } from "services/store/reducers/config/actions";
import { FormDataConfig } from "services/store/reducers/config/types";
import { clearMediaPlansData } from "services/store/reducers/media";
import {
  fetchMediaPlans,
  startLearnMl,
  startML,
} from "services/store/reducers/media/actions";
import { FetchMediaUploadsParams } from "services/store/reducers/media/types";
import {
  getRecruitmentPlans,
  postRecruitmentPlan,
} from "services/store/reducers/recruitment/actions";
import { selectSortedMediaPlans } from "services/store/selectors";
import { useAppDispatch, useAppSelector } from "services/store/store";
import useSessionStorageState from "use-session-storage-state";
import { SafeParseReturnType } from "zod";

import commonStyles from "../common-styles.module.sass";
import styles from "./styles.module.sass";

const MediaPage: React.FC = (): JSX.Element => {
  const dispatch = useAppDispatch();

  const { mediaUploadsLoading, startMLLoading, startMLData, startMLError } =
    useAppSelector((state) => state.mediaplan);

  const sortedMediaUploadsData = useAppSelector(selectSortedMediaPlans);

  const {
    getRecruitmentPlansData,
    getRecruitmentPlansLoading,
    uploadRecruitmentPlanData,
    uploadRecruitmentPlanLoading,
    uploadRecruitmentPlanError,
  } = useAppSelector((state) => state.recr);

  const {
    uploaderName: uploader_name,
    clientId: client_id,
    clientName,
  } = useAppSelector((state) => state.globalParams);

  const { uploadConfigLoading, uploadConfigParams, uploadConfigError } =
    useAppSelector((state) => state.config);

  const [isOpenUploads, setIsOpenUploads] = useState(false);
  const [isOpenLearning, setIsOpenLearning] = useState(false);
  const [errors, setErrors] = useState<string[]>();

  const [uploadMessage, setUploadMessage] =
    useSessionStorageState<UploadMessage | null>("upload-message-media");
  const { fileName, file, handleUpload } = useUploadHandler();
  const { ToastContainer, notifyUploading, notifyMlStart } = useNotify();
  const { isOpen, openModal, closeModal } = useModal();
  const uploadRef = useRef<HTMLInputElement>(null);
  const downloadRef = useRef<HTMLAnchorElement>(null);
  const [selectSource, setSelectSource] = useState("");
  const [recrPlanId, setRecrPlanId] = useState<number | null>(0);

  const handleOpenUploads = () => {
    dispatch(
      getRecruitmentPlans({
        client_id,
      }),
    );
    setIsOpenUploads(true);
  };
  const handleCloseUploads = () => setIsOpenUploads(false);
  const handleCloseLearning = () => {
    setIsOpenLearning(false);
    setSelectSource("");
  };

  const handleSubmit = (formData: FormDataConfig) => {
    console.log("Данные формы:", formData);
    sendUploadConfig(formData);
    closeModal();
    handleOpenUploads();
  };

  const handleSumbitLearning = () => {
    if (selectSource !== "") {
      dispatch(
        startLearnMl({
          client_id: client_id,
          uploader_name: uploader_name,
          client_name: clientName,
          source_id: 1,
          source_name: selectSource,
        }),
      );
    }
    handleCloseLearning();
    notifyUploading("Обучение запущено", "success");
  };

  const handleUploadClick = (): void => {
    if (uploadRef.current) uploadRef.current.click();
  };

  const handleDownloadClick = (): void => {
    if (downloadRef.current) downloadRef.current.click();
  };

  const handleSaveClick = async () => {
    if (uploadConfigParams && !uploadConfigLoading && recrPlanId) {
      await dispatch(
        postUploadConfig({
          ...uploadConfigParams,
          recruitment_plan_id: recrPlanId,
        }),
      );
      if (!uploadConfigError)
        dispatch(
          startML({
            client_id: client_id,
            date: handleDate(new Date()),
            recruitment_plan_id: recrPlanId,
            caller_name: clientName,
            call_id: 777,
          }),
        );
    }
  };

  const handleClearMessage = (): void => {
    setUploadMessage(null);
  };

  const sendUploadConfig = (formData: FormDataConfig) => {
    const payload = {
      ...formData,
      uploader_name: uploader_name,
      client_id,
      client_name: clientName,
      date_from: handleDate(formData.date_from || new Date()),
      date_to: handleDate(formData.date_to || new Date()),
      mp_start: handleDate(formData.mp_start || new Date()),
      mp_finish: handleDate(formData.mp_finish || new Date()),
      sr_start: handleDate(formData.sr_start || new Date("2023-01-01")),
      sr_finish: handleDate(formData.sr_finish || new Date()),
      sr_upload: handleDate(new Date()),
      prev_mp_start: handleDate(formData.prev_mp_start || new Date()),
      prev_mp_finish: handleDate(formData.prev_mp_finish || new Date()),
      finish_date:
        formData.finish_date !== undefined && formData.finish_date !== ""
          ? +formData.finish_date
          : 4,
    };
    dispatch(setConfigData(payload));
  };

  const handleUpdateFilters = (filterValues: FilterValues): void => {
    const sendParams: FetchMediaUploadsParams = {
      client_id: client_id,
    };
    addOptionalParams(sendParams, filterValues);

    let result: SafeParseReturnType<number, number> | undefined;
    result = checkOnPositiveInteger(
      filterValues.id,
      MEDIAPLANS_FILTERS.errorsPhrases.id,
    );
    const errorMessage = result?.error?.issues[0].message;

    if (errorMessage) {
      setErrors([errorMessage]);
      return;
    }

    setErrors(undefined);
    dispatch(fetchMediaPlans(sendParams));
  };

  useEffect(() => {
    dispatch(fetchMediaPlans({ client_id }));

    return () => {
      dispatch(clearMediaPlansData());
    };
  }, [dispatch, client_id]);

  useEffect(() => {
    if (startMLData && !startMLLoading) {
      notifyMlStart(
        `Медиаплан :${startMLData.ml_output_upload_id} готов`,
        "success",
      );
      handleCloseUploads();
    } else if (startMLError && !startMLLoading) {
      notifyMlStart(startMLError, "error");
    }
  }, [
    notifyMlStart,
    startMLData,
    startMLLoading,
    startMLError,
    client_id,
    dispatch,
  ]);

  useEffect(() => {
    if (file && uploader_name) {
      setUploadMessage(null);
      const params = {
        client_id: client_id.toString(),
        uploader_name: uploader_name,
      };
      dispatch(postRecruitmentPlan({ params, file }));
    }
  }, [dispatch, file, uploader_name, client_id, setUploadMessage]);

  useSetMessages({
    data: uploadRecruitmentPlanData,
    fileName,
    notifyUploading,
    setUploadMessage,
    error: uploadRecruitmentPlanError,
  });

  return (
    <>
      <section className={classNames(commonStyles.heading)}>
        <H1 color="#0C3953">Медиапланы</H1>
        <Filters
          onUpdateFilters={handleUpdateFilters}
          configuration={MEDIAPLANS_FILTERS}
          errors={errors}
        />
      </section>
      <section className={styles.optionsBox}>
        <div className="pl-4">
          <section className={styles.selectionPlan}>
            <H3 color="#0C3953">План подбора</H3>
          </section>
          <Button variant="primary" onClick={openModal}>
            Выбор плана подбора
          </Button>
        </div>
        <div className={styles.machineLearning}>
          <Button variant="primary" onClick={() => setIsOpenLearning(true)}>
            Обучить ML
          </Button>
        </div>
      </section>
      <section className={styles.wrapper}>
        {mediaUploadsLoading ? (
          <Loader />
        ) : sortedMediaUploadsData && !mediaUploadsLoading ? (
          <MediaPlanTable data={sortedMediaUploadsData} />
        ) : (
          <span>{EMPTY_DATA}</span>
        )}
        <div>
          <Modal open={isOpen} onClose={closeModal} classNames="rounded-lg">
            <div>
              <H2>Заполните конфигурацию</H2>
              <ConfigForm onSubmit={handleSubmit} />
            </div>
          </Modal>
          <Modal
            open={isOpenUploads}
            onClose={handleCloseUploads}
            classNames="rounded-lg"
          >
            <div className={styles.content}>
              <H2>Выберите план подбора</H2>
              {getRecruitmentPlansLoading ? (
                <Loader />
              ) : getRecruitmentPlansData ? (
                <PlanUploadsTable
                  data={getRecruitmentPlansData}
                  choosePlan={setRecrPlanId}
                />
              ) : (
                EMPTY_DATA
              )}
              <div className={styles.newPlan}>
                <Upload
                  forwardRef={uploadRef}
                  id="upload"
                  onUpload={handleUpload}
                  accept={EXCEL_ACCEPT_FORMAT}
                  label={fileName}
                >
                  <Button
                    variant="upload"
                    loading={uploadRecruitmentPlanLoading}
                    disabled={uploadRecruitmentPlanLoading}
                    onClick={handleUploadClick}
                  >
                    Добавьте новый
                  </Button>
                </Upload>
                <Button variant="download" onClick={handleDownloadClick}>
                  Скачать шаблон XLSX
                </Button>
              </div>
              {uploadMessage && (
                <Message
                  text={uploadMessage.message}
                  success={uploadMessage.success}
                  close={handleClearMessage}
                />
              )}
              <Button
                variant="primary"
                loading={startMLLoading}
                disabled={startMLLoading}
                onClick={handleSaveClick}
              >
                Сохранить
              </Button>
            </div>
          </Modal>
          <Modal
            open={isOpenLearning}
            onClose={handleCloseLearning}
            classNames="rounded-lg"
          >
            <div className={styles.learnBox}>
              <BasicSelect
                className="min-w-60"
                label="Выберите источник"
                options={SOURCE_OPTIONS}
                variant="standard"
                select={selectSource}
                setSelect={setSelectSource}
              />
              <Button
                variant="primary"
                className="min-w-32"
                onClick={handleSumbitLearning}
                disabled={selectSource === ""}
              >
                Начать
              </Button>
            </div>
          </Modal>
        </div>
        <ToastContainer />
      </section>
    </>
  );
};
export default MediaPage;
