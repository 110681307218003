import { useEffect } from "react";

import classNames from "classnames";
import { EMPTY_DATA } from "common/const";
import HirePlanTable from "components/tables/HirePlanTable";
import { H1 } from "components/typography";
import { Loader } from "components/UI/Loader";
import { getHirePlan } from "services/store/reducers/hirePlan/actions";
import { useAppDispatch, useAppSelector } from "services/store/store";

import commonStyles from "../common-styles.module.sass";

const PublishSelection: React.FC = (): JSX.Element => {
  const { clientId } = useAppSelector((state) => state.globalParams);
  const { getHirePlanData, getHirePlanLoading } = useAppSelector(
    (state) => state.hirePlan,
  );
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(getHirePlan(clientId));
  }, [dispatch, clientId]);

  return (
    <>
      <section className={classNames(commonStyles.heading)}>
        <H1 color="#0C3953">План подбора</H1>
      </section>
      {getHirePlanLoading ? (
        <Loader />
      ) : getHirePlanData && getHirePlanData.length > 0 ? (
        <HirePlanTable data={getHirePlanData} />
      ) : (
        EMPTY_DATA
      )}
    </>
  );
};

export default PublishSelection;
