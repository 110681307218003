import { Column } from "./types";

export const columns: readonly Column[] = [
  {id: 'city', label: 'Город'},
  {id: 'profile', label: 'Профиль'},
  {id: 'object_id', label: 'id объекта'},
  {id: 'demand', label: 'Потребность'},
  {id: 'source', label: 'Источник'},
  {id: 'factor', label: 'Опции'},
  {id: 'publication_date', label: 'Дата публикации'},
  {id: 'publication_time', label: 'Время публикации'},
  {id: 'cluster_label', label: 'Кластер'},
]
