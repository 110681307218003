import { createAsyncThunk } from "@reduxjs/toolkit";
import axios, { AxiosResponse } from "axios";
import { IProjects, setProjectParams, setProjectResponse } from "./types";
import { PUBLISH_API_URL } from "api/endpoint";
import axiosInstance from "services/store/axiosInstance";

export const getProjects = createAsyncThunk(
  "projects/getProjects",
  async (client_id: number, { rejectWithValue}) => {
    try {
      const response: AxiosResponse<IProjects> = await axiosInstance.get(
        `${PUBLISH_API_URL}/projects?client_id=${client_id}`
      );
      return response.data;
    } catch (error) {
      return axios.isAxiosError(error) && error.response
       ? rejectWithValue(error.response.data)
        : rejectWithValue(error.message);
    }
  }
)

export const setProject = createAsyncThunk(
  "projects/setProject",
  async (body: setProjectParams, {rejectWithValue}) => {
    try {
      const response: AxiosResponse<setProjectResponse> = await axiosInstance.post(
        `${PUBLISH_API_URL}/project/add`,
        body,
        { headers: { 'Content-Type': 'application/json'}}
      )
      return response.data;
    } catch (error) {
      return axios.isAxiosError(error) && error.response
       ? rejectWithValue(error.response.data)
        : rejectWithValue(error.message);
    }  }
)