import { FilterValues } from "components/Filters";
import { Route } from "../types";
import { optionType } from "common/types";
import { CandidatesData } from "services/store/reducers/issued/types";
import { MediaPlanUpload } from "services/store/reducers/media/types";
import { HvAvData } from "services/store/reducers/ws-data/types";
import { PlanLine } from "services/store/reducers/recruitment/types";

export const MEDIA_PLAN_ROUTE = "/mediaplan";
export const MEDIA_PLANS_ROUTE = "/mediaplans";
export const ISSUED_ROUTE = "/issued";
export const WORK_SITE_DATA_ROUTE = "/wsdata";
export const SELECTION_PLAN_ROUTE = "/selection";
export const MEDIA_PLAN_MULTI_ROUTE = "/mediaplan-multi";
export const SETTINGS_ROUTE = "/settings";
export const ANALYTICS_ROUTE = "/analytics";
export const OBJECT_LIST_ROUTE = "/object-list";
export const UPLOAD_PLAN_ROUTE = "/upload-plan";
export const CHOOSE_MEDIA_PLAN_ROUTE = "/choose-media-plan";
export const CLUSTER_MEDIA_PLAN_ROUTE = "/cluster-media-plan";
export const REESTR_PLAN_PUBLISH_ROUTE = "/reestr";
export const PUPLISH_PLAN_ROUTE = "/publish-plan";
  
export const SIDEBAR_ROUTES: (Route & { subroutes: Route[] })[] = [
  { 
    name: "Медиапланы",
    value: 'blanc',
    icon: '/mediaplans-tab-icon.svg', 
    subroutes: [
      {
        name: "Данные работников сайтов",
        value: WORK_SITE_DATA_ROUTE,
      },
      {
        name: "Источники",
        value: SETTINGS_ROUTE
      },
      {
        name: "Планы подбора",
        value: SELECTION_PLAN_ROUTE,
      },
      {
        name: "Оформленные",
        value: ISSUED_ROUTE,
      },
      {
        name: "Медиапланы",
        value: MEDIA_PLANS_ROUTE,
       },
    ]
  },
  { name: "План публикаций", value: 'blanc' , icon: '/publication-plan-tab-icon.svg', subroutes: [
    {
      name: 'Список объектов',
      value: OBJECT_LIST_ROUTE,
    },
    {
      name: "Выбор медиаплана",
      value: CHOOSE_MEDIA_PLAN_ROUTE,
    },
    {
      name: "Реестр планов публикаций",
      value: REESTR_PLAN_PUBLISH_ROUTE,
    },
    {
      name: "План публикаций",
      value: PUPLISH_PLAN_ROUTE
    },
    {
      name: "План подбора",
      value: UPLOAD_PLAN_ROUTE,
    },
    {
      name: "Медиаплан по кластерам",
      value: CLUSTER_MEDIA_PLAN_ROUTE,
    },
  ] },
  { name: "Автопубликации", value: '', icon: '/autopublications-tab-icon.svg', subroutes: [] },
  {
    name: "Аналитика",
    value: ANALYTICS_ROUTE,
    icon: '/analytics-tab-icon.svg', subroutes: []
  },
];

export const tabsBreadCrumps = [
  { text: "Медиапланы", link: MEDIA_PLANS_ROUTE },
  { text: "План публикаций", link: PUPLISH_PLAN_ROUTE},
  { text: "Автопубликации", link: ''}
]

export const FILE_TYPE = "text/plain;charset=utf8;";

export const EXCEL_ACCEPT_FORMAT =
  "application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";

export const SUCCESS_FILE_UPLOAD = "Файл успешно загружен";
export const ERROR_FILE_UPLOAD = "Файл содержит ошибки";
export const SUCCESS_LEARNING_START = "Обучение успешно завершено, обновите страницу"
export const ERROR_LEARNING_START = "Не удалось запустить обучение"

export const SOURCE_OPTIONS = [
  { name: "hh.ru", value: "hv" },
  { name: "av.ru", value: "av" },
];

interface MediaPlanTable extends Omit<MediaPlanUpload, "id" | "client_id"> {}
interface PlanUploadTable
  extends Omit<PlanLine, "id" | "date" | "hire" | "closed"> {}
interface HvAvDataTable
  extends Pick<HvAvData, "id" | "uploaded_at" | "profile" | "region" | "days"> {
  manage: string;
}
interface CandidatesDataTable
  extends Omit<
    CandidatesData,
    "candidate_id" | "real_location" | "upload" | "real_source" | "client_id"
  > {}
export type MediaPlanMultiData = Pick<
  MediaPlanUpload,
  | "region"
  | "city"
  | "profile"
  | "month_plan"
  | "leads_for_week"
  | "price_for_lead"
  | "amount_on_start"
  | "future_registered"
>;

type MediaPlanColumnId = keyof MediaPlanTable;
type PlanUploadColumnId = keyof PlanUploadTable;
type HvAvDataColumnId = keyof HvAvDataTable;
type CandidatesDataColumnId = keyof CandidatesDataTable;
export type MediaPlanMultiColumnId = keyof MediaPlanMultiData;
export interface MediaPlanColumn {
  id: MediaPlanColumnId;
  label: string;
}
export interface PlanUploadColumn {
  id: PlanUploadColumnId;
  label: string;
}
export interface HvAvDataColumn {
  id: HvAvDataColumnId;
  label: string;
}
export interface CandidatesDataColumn {
  id: CandidatesDataColumnId;
  label: string;
}
export interface MediaPlanMultiColumn {
  id: MediaPlanMultiColumnId;
  label: string;
}
export type Caption = {
  unions: number;
  label: string;
};
export interface Plans {
  [key: string]: {
    label: string;
    description: string;
    bgColor: string;
    bgDataColor: string;
    textColor: string;
  };
}
export const MEDIA_PLAN_CAPTIONS: Caption[] = [
  { unions: 6, label: "" },
  { unions: 5, label: "План на неделю" },
  { unions: 7, label: "План на месяц" },
  { unions: 5, label: "Период" },
];
export const MEDIA_PLAN_COLUMNS: readonly MediaPlanColumn[] = [
  { id: "region", label: "Регион" },
  { id: "city", label: "Населенный пункт" },
  { id: "profile", label: "Профиль" },
  { id: "source", label: "Источник" },
  { id: "source_type", label: "Полное наименование источника" },
  { id: "month_plan", label: "Потребность (план в месяц)" },
  { id: "priority", label: "Приоритет размещения" },
  { id: "publications_for_week", label: "Кол-во размещений в неделю" },
  { id: "leads_for_week", label: "Лидов в неделю" },
  { id: "price_for_lead", label: "Стоимость лида в неделю" },
  { id: "amount_on_start", label: "Бюджет на неделю" },
  { id: "publications_for_month", label: "Кол-во размещений в месяц" },
  { id: "leads_for_month", label: "Лидов в месяц" },
  { id: "price_for_lead_month", label: "Стоимость лида в месяц" },
  { id: "future_registered", label: "Прогноз оформленных в месяц" },
  { id: "hire_chance", label: "Вероятность найма" },
  { id: "registered_price", label: "Стоимость оформленного" },
  { id: "month_amount", label: "Бюджет на месяц" },
  { id: "date_start", label: "Начало периода" },
  { id: "date_end", label: "Конец периода" },
  {
    id: "avito_week_contacts_requests",
    label: "Авито-запросы контактов в неделю",
  },
  { id: "avito_week_callbacks", label: "Авито-вызовы в неделю" },
  { id: "promotion_type", label: "Тип продвижения" },
];
export const PLAN_UPLOAD_COLUMNS: readonly PlanUploadColumn[] = [
  { id: "hire_count", label: "К подбору" },
  { id: "real_profile", label: "Профиль" },
  { id: "real_location", label: "Населенный пункт" },
  { id: "real_region", label: "Регион" },
  { id: "real_macroregion", label: "Макрорегион" },
];
export const HV_AV_DATA_COLUMNS: readonly HvAvDataColumn[] = [
  { id: "id", label: "№" },
  { id: "uploaded_at", label: "Дата загрузки" },
  { id: "profile", label: "Профиль" },
  { id: "region", label: "Регион" },
  { id: "days", label: "Дни" },
  { id: "manage", label: "Управление" },
];
export const CANDIDATES_DATA_COLUMNS: readonly CandidatesDataColumn[] = [
  { id: "id", label: "№" },
  { id: "type", label: "Тип" },
  { id: "source", label: "Источник" },
  { id: "shop", label: "Регион" },
  { id: "position", label: "Вакансия" },
  { id: "status", label: "Статус" },
  { id: "date", label: "Дата" },
  { id: "real_profile", label: "Профиль" },
  { id: "day_of_week", label: "Дни недели"},
  { id: "working", label: "Рабочий / Выходной"},
  { id: "time", label: "Время"}
];
export const MEDIA_PLAN_MULTI_COLUMNS: readonly MediaPlanMultiColumn[] = [
  { id: "region", label: "Регион" },
  { id: "city", label: "Город" },
  { id: "profile", label: "Профиль" },
  { id: "month_plan", label: "Потребность (план)" },

  { id: "leads_for_week", label: "Лидов в месяц" },
  { id: "price_for_lead", label: "Стоимость лида, руб." },
  { id: "amount_on_start", label: "Бюджет в месяц" },
  { id: "future_registered", label: "Прогноз оформленных в месяц" },

  { id: "leads_for_week", label: "Лидов в месяц" },
  { id: "price_for_lead", label: "Стоимость лида, руб." },
  { id: "amount_on_start", label: "Бюджет в месяц" },
  { id: "future_registered", label: "Прогноз оформленных в месяц" },

  { id: "leads_for_week", label: "Лидов в месяц" },
  { id: "price_for_lead", label: "Стоимость лида, руб." },
  { id: "amount_on_start", label: "Бюджет в месяц" },
  { id: "future_registered", label: "Прогноз оформленных в месяц" },

  { id: "leads_for_week", label: "Лидов в месяц" },
  { id: "price_for_lead", label: "Стоимость лида, руб." },
  { id: "amount_on_start", label: "Бюджет в месяц" },
  { id: "future_registered", label: "Прогноз оформленных в месяц" },
];

export const MEDIA_PLAN_MULTI_PLANS: Plans = {
  custom: {
    label: "Утвержденный",
    description: "С возможностью ручной корректировки планов",
    bgColor: "#4D6788",
    bgDataColor: "#FFF9F2",
    textColor: "#FFFFFF",
  },
  opt: {
    label: "Оптимальный",
    description: "Баланс между стоимостью и количеством откликов",
    bgColor: "#314660",
    bgDataColor: "#EEFFFB",
    textColor: "#FFFFFF",
  },
  optimal: {
    label: "Оптимальный",
    description: "Баланс между стоимостью и количеством откликов",
    bgColor: "#314660",
    bgDataColor: "#EEFFFB",
    textColor: "#FFFFFF",
  },
  max: {
    label: "Максимальный",
    description: "Максимальное количество откликов",
    bgColor: "#7998BD",
    bgDataColor: "#FEEFF9",
    textColor: "#FFFFFF",
  },
  min: {
    label: "Экономичный",
    description: "Наименьшая стоимость отклика",
    bgColor: "#C1D6EE",
    bgDataColor: "#E8F6FE",
    textColor: "#1E1F1F",
  },
};

export const MEDIA_PLAN_MULTI_SETTINGS_CAPTIONS = [
  { id: "week", label: "Неделя" },
  { id: "month", label: "Месяц" },
];
export const MEDIA_PLAN_MULTI_SETTINGS_COLUMNS = [
  { id: "placements", label: "Размещений" },
  { id: "leads", label: "Лидов" },
  { id: "contact_requests", label: "Запросов контактов" },
  { id: "feedbacks", label: "Откликов" },
  { id: "lead_price", label: "Стоимость лида" },
  { id: "budget", label: "Бюджет" },
  { id: "promotion_type", label: "Тип продвижения" },
];

export const STORE_INIT_FILE_NAME = "Файл не выбран";
export const EMPTY_DATA = "Нет данных";

export const API_MULTI_URL = "http://51.250.67.190:8000/api"; // TODO: Move hardcoded values to .env
export const TEMPLATE_CANDIDATES_URL =
  "https://api.hrrocket.ru/static/base-candidates.xlsx";
export const TEMPLATE_PLAN_UPLOAD_URL =
  "https://api.hrrocket.ru/static/base-plan.xlsx";

export const initState = {
  loading: false,
  error: null,
  data: null,
};

export const FILTERS_INFO = {
  delimiter: 'Используйте ";" в качестве разделителя',
};

type FilterType = "slider" | "select" | "date" | "input" | "inputNumber";

export interface FilterConfiguration {
  labels: { [key in keyof FilterValues]: string };
  types: { [key in keyof FilterValues]: FilterType };
  defaults: FilterValues;
  errorsPhrases: { [key in keyof FilterValues]: string };
  steps?: { [key in keyof FilterValues]: number };
  mins?: { [key in keyof FilterValues]: number };
  maxs?: { [key in keyof FilterValues]: number };
  selectOptions?: { [key in keyof FilterValues]: optionType[] };
  groups?: { [key: number]: string[] };
}

export const MEDIAPLAN_FILTERS: FilterConfiguration = {
  labels: {
    hire_chance: "Вероятность найма",
    price_for_lead: "Стоимость лида",
    amount_on_start: "Бюджет",
    registered_price: "Стоимость оформленного",
  },
  types: {
    hire_chance: "slider",
    price_for_lead: "slider",
    amount_on_start: "slider",
    registered_price: "slider",
  },
  defaults: {
    hire_chance: 0.01,
    price_for_lead: 10_000,
    amount_on_start: 100_000_000,
    registered_price: 1_000_000,
  },
  steps: {
    hire_chance: 0.01,
    price_for_lead: 1,
    amount_on_start: 1_000,
    registered_price: 1,
  },
  mins: {
    hire_chance: 0.001,
    price_for_lead: 1,
    amount_on_start: 1_000,
    registered_price: 1,
  },
  maxs: {
    hire_chance: 1,
    price_for_lead: 10_000,
    amount_on_start: 100_000_000,
    registered_price: 1_000_000,
  },
  errorsPhrases: {
    hire_chance: "вероятности найма",
    price_for_lead: "стоимости лида",
    amount_on_start: "бюджета",
    registered_price: "стоимости оформленного",
  },
};

export const HV_AV_FILTERS: FilterConfiguration = {
  labels: {
    source_type: "Источник",
    date_from: "Дата отчета от",
    date_to: "Дата отчета до",
    uploaded_from: "Дата загрузки от",
    uploaded_to: "Дата загрузки до",
    profile: "Профиль",
    region: "Населенный пункт",
  },
  types: {
    source_type: "select",
    date_from: "date",
    date_to: "date",
    uploaded_from: "date",
    uploaded_to: "date",
    profile: "input",
    region: "input",
  },
  defaults: {
    source_type: "hv",
  },
  errorsPhrases: {
    source_type: "Выбран несуществующий источник",
  },
  selectOptions: { source_type: SOURCE_OPTIONS },
  groups: {
    1: ["source_type"],
    2: ["date_from", "date_to"],
    3: ["uploaded_from", "uploaded_to"],
    4: ["profile", "region"],
  },
};

export const CANDIDATES_FILTERS: FilterConfiguration = {
  labels: {
    source_type: "Источник",
    profile: "Профиль",
    region: "Населённый пункт",
    uploaded_from: "Дата загрузки от",
    uploaded_to: "Дата загрузки до",
  },
  types: {
    source_type: "select",
    profile: "input",
    region: "input",
    uploaded_from: "date",
    uploaded_to: "date",
  },
  defaults: {
    source_type: "",
    uploaded_from: '',
    uploaded_to: '',
  },
  errorsPhrases: {},
  selectOptions: { source_type: SOURCE_OPTIONS },
  groups: {
    1: ["source_type"],
    2: ["profile", "region"],
    3: ["uploaded_from", "uploaded_to"],
  },
};

export const MEDIAPLANS_FILTERS: FilterConfiguration = {
  labels: {
    id: "Номер плана подбора",
    uploaded_from: "Дата загрузки от",
    uploaded_to: "Дата загрузки до",
  },
  types: {
    id: "inputNumber",
    uploaded_from: "date",
    uploaded_to: "date",
  },
  defaults: {},
  errorsPhrases: {
    id: "Значение должно быть целым положительным числом",
  },
  groups: {
    1: ["id"],
    2: ["uploaded_from", "uploaded_to"],
  },
};

export const RECRUITMENT_FILTERS: FilterConfiguration = {
  labels: {
    profile: "Профиль",
    region: "Населённый пункт",
  },
  types: {
    profile: "input",
    region: "input",
  },
  defaults: {},
  errorsPhrases: {},
  groups: {
    1: ["profile", "region"],
  },
};

export const RECRUITMENTS_FILTERS: FilterConfiguration = {
  labels: {
    uploaded_from: "Дата загрузки от",
    uploaded_to: "Дата загрузки до",
  },
  types: {
    uploaded_from: "date",
    uploaded_to: "date",
  },
  defaults: {},
  errorsPhrases: {},
  groups: {
    1: ["uploaded_from", "uploaded_to"],
  },
};
