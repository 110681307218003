export default function ShevronRight({
  stroke = "#E0E0E0",
}: {
  stroke?: string;
}) {
  return (
    <svg
      width="17"
      height="17"
      viewBox="0 0 17 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6.375 3.54183L11.3333 8.50016L6.375 13.4585"
        stroke={stroke}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
