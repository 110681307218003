import { useState } from "react";
import { useNavigate } from "react-router";

import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";

import { Pagination } from "components/UI/Pagination";
import { Project } from "services/store/reducers/projects/types";

import commonStyles from "../common-styles.module.sass";
import { columns } from "./constants";
import styles from "./styles.module.sass";

interface IPublishReestrTable {
  data: Project[];
}

const PublishReestrTable: React.FC<IPublishReestrTable> = ({
  data,
}): JSX.Element => {
  const [page, setPage] = useState(0);
  const [rowsPerPage] = useState(4);
  const navigate = useNavigate();
  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  return (
    <>
      <Paper className={styles.paper}>
        <TableContainer sx={{ maxHeight: "calc(100vh - 350px)" }}>
          <Table stickyHeader aria-label="sticky table">
            <TableHead>
              <TableRow>
                {columns.map((column) => (
                  <TableCell
                    padding="none"
                    className={styles.tableHeadCell}
                    key={column.id}
                  >
                    {column.label}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {data
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row) => (
                  <TableRow
                    key={row.id}
                    className={styles.tableRow}
                    onClick={() => navigate("/publish-plan")}
                  >
                    {columns.map((column) => {
                      const value = row[column.id];
                      return (
                        <TableCell
                          padding="none"
                          key={column.id}
                          className={styles.tableBodyCell}
                        >
                          {value}
                        </TableCell>
                      );
                    })}
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
      <Pagination
        total={data.length}
        limit={rowsPerPage}
        currentPage={page}
        onPageChange={handleChangePage}
        className={commonStyles.pagination}
      />
    </>
  );
};

export default PublishReestrTable;
