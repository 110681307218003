// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.styles_paper__95UfB {
  box-shadow: 0px 2px 12px rgba(26, 61, 150, 0.0784313725), inset 2px 1px 12px rgba(26, 61, 150, 0.0784313725) !important;
  border-radius: 8px !important;
  max-width: fit-content !important;
}

.styles_header__cell__IIT81 {
  font-size: 11px !important;
  line-height: 14px !important;
  color: #54687D !important;
  padding: 6px 16px !important;
  border-bottom: 1px solid #E0E0E0 !important;
  height: 60px;
  white-space: normal !important;
}

.styles_body__cell__PScqW {
  height: 50px;
  padding-block: 0;
  font-size: 13px !important;
  line-height: 14px !important;
  color: #0C3953 !important;
  border-bottom: 1px solid #f2f2f2 !important;
}
.styles_body__cell__PScqW:nth-child(1) {
  width: 50px;
  padding: 0 16px !important;
}
.styles_body__cell__PScqW:nth-child(1) img {
  margin-top: 5px;
  transition: scale 0.2s ease-in-out;
}
.styles_body__cell__PScqW:nth-child(1) img:hover {
  scale: 1.1;
}

.styles_custom__LjH7k {
  background-color: #FFF9F2;
}

.styles_body__cell_active__uI4-A {
  background-color: #EDF2FC;
}

.styles_control__LAOGm {
  display: flex;
  align-items: center;
  gap: 8px;
}`, "",{"version":3,"sources":["webpack://./src/components/tables/PublishPlanTable/styles.module.sass"],"names":[],"mappings":"AAAA;EACE,uHAAA;EACA,6BAAA;EACA,iCAAA;AACF;;AACA;EACE,0BAAA;EACA,4BAAA;EACA,yBAAA;EACA,4BAAA;EACA,2CAAA;EACA,YAAA;EACA,8BAAA;AAEF;;AAAA;EACE,YAAA;EACA,gBAAA;EACA,0BAAA;EACA,4BAAA;EACA,yBAAA;EACA,2CAAA;AAGF;AAFE;EACE,WAAA;EACA,0BAAA;AAIJ;AAHI;EACE,eAAA;EACA,kCAAA;AAKN;AAJM;EACE,UAAA;AAMR;;AAJA;EACE,yBAAA;AAOF;;AALA;EACE,yBAAA;AAQF;;AANA;EACE,aAAA;EACA,mBAAA;EACA,QAAA;AASF","sourcesContent":[".paper\n  box-shadow: 0px 2px 12px #1A3D9614, inset 2px 1px 12px #1A3D9614 !important\n  border-radius: 8px !important\n  max-width: fit-content !important\n\n.header__cell\n  font-size: 11px !important\n  line-height: 14px !important\n  color: #54687D !important\n  padding: 6px 16px !important\n  border-bottom: 1px solid #E0E0E0 !important\n  height: 60px\n  white-space: normal !important\n\n.body__cell\n  height: 50px\n  padding-block: 0\n  font-size: 13px !important\n  line-height: 14px !important\n  color: #0C3953 !important\n  border-bottom: 1px solid #f2f2f2 !important\n  &:nth-child(1)\n    width: 50px\n    padding: 0 16px !important\n    img\n      margin-top: 5px\n      transition: scale 0.2s ease-in-out\n      &:hover\n        scale: 1.1\n\n.custom\n  background-color: #FFF9F2\n\n.body__cell_active\n  background-color: #EDF2FC\n\n.control\n  display: flex\n  align-items: center\n  gap: 8px\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"paper": `styles_paper__95UfB`,
	"header__cell": `styles_header__cell__IIT81`,
	"body__cell": `styles_body__cell__PScqW`,
	"custom": `styles_custom__LjH7k`,
	"body__cell_active": `styles_body__cell_active__uI4-A`,
	"control": `styles_control__LAOGm`
};
export default ___CSS_LOADER_EXPORT___;
