import { useEffect, useState } from "react";

import { EMPTY_DATA } from "common/const";
import PublishReestrTable from "components/tables/PublishReestrTable";
import { H1 } from "components/typography";
import { Loader } from "components/UI/Loader";
import { getProjects } from "services/store/reducers/projects/actions";
import { Project } from "services/store/reducers/projects/types";
import { useAppDispatch, useAppSelector } from "services/store/store";

const PublishReestr: React.FC = (): JSX.Element => {
  const [data, setData] = useState<Project[]>([]);
  const dispatch = useAppDispatch();

  const { clientId } = useAppSelector((state) => state.globalParams);
  const { getProjectData, getProjectLoading } = useAppSelector(
    (state) => state.project,
  );

  useEffect(() => {
    dispatch(getProjects(clientId));
  }, [clientId, dispatch]);

  useEffect(() => {
    if (getProjectData && !getProjectLoading) {
      setData(getProjectData);
    }
  }, [getProjectData, getProjectLoading]);

  return (
    <>
      <H1>Реестр планов публикаций</H1>
      {getProjectLoading ? (
        <div
          className="glow w-full flex items-center justify-center"
          style={{ height: "calc(100vh - 150px)" }}
        >
          <Loader />
        </div>
      ) : data && data.length > 0 ? (
        <PublishReestrTable data={data} />
      ) : (
        <span>{EMPTY_DATA}</span>
      )}
    </>
  );
};

export default PublishReestr;
