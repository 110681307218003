import { useEffect } from "react";

import { EMPTY_DATA } from "common/const";
import PublishPlanTable from "components/tables/PublishPlanTable";
import { H1 } from "components/typography";
import { Button } from "components/UI/Button";
import { Loader } from "components/UI/Loader";
import { getPublicationPlan } from "services/store/reducers/media/actions";
import { useAppDispatch, useAppSelector } from "services/store/store";

import styles from "./styles.module.sass";

const PublishPlan: React.FC = (): JSX.Element => {
  const dispatch = useAppDispatch();
  const { publicationPlanData, publicationPlanLoading } = useAppSelector(
    (state) => state.mediaplan,
  );

  useEffect(() => {
    dispatch(
      getPublicationPlan({
        upload_id: 191,
        project_id: 4,
        city_independent: false,
        debug: false,
        mediaplan_type: "optimal",
        strategy: "strategy_v1",
      }),
    );
  }, [dispatch]);

  return (
    <section className={styles.container}>
      <H1>План публикаций</H1>
      {publicationPlanLoading ? (
        <div
          className="glow w-full flex items-center justify-center"
          style={{ height: "calc(100vh - 150px)" }}
        >
          <Loader />
        </div>
      ) : publicationPlanData && publicationPlanData.length > 0 ? (
        <PublishPlanTable data={publicationPlanData} />
      ) : (
        <span>{EMPTY_DATA}</span>
      )}
    </section>
  );
};

export default PublishPlan;
