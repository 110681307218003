// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.styles_wrapper__a-BTc {
  display: flex;
  justify-content: center;
}`, "",{"version":3,"sources":["webpack://./src/components/UI/Loader/styles.module.sass"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,uBAAA;AACF","sourcesContent":[".wrapper\n  display: flex\n  justify-content: center\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": `styles_wrapper__a-BTc`
};
export default ___CSS_LOADER_EXPORT___;
