import { useEffect, useRef, useState } from "react";
import { NavLink, useLocation, useNavigate } from "react-router-dom";

import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";

import classNames from "classnames";
import { Route } from "common/types";
import { getLocationIndex } from "common/utils/getLocationIndex";
import Logo from "components/UI/Logo";
import URLInputs from "components/URLInputs";
import { logout } from "services/store/reducers/authentication";
import { useAppDispatch } from "services/store/store";

import { SIDEBAR_ROUTES } from "../../common/const";
import SelectClient from "./selectClient";
import styles from "./styles.module.sass";
import { SideBarControl, TabRoute } from "./types";

const SideBar: React.FC = (): JSX.Element => {
  const { pathname } = useLocation();
  const sidebarLocation: string = `/${pathname.split("/")[1]}`;

  let locationIndex: number = getLocationIndex(sidebarLocation, SIDEBAR_ROUTES);
  const [selectedIndex, setSelectedIndex] = useState<number>(locationIndex);
  const [tempRoutes, setTempRoutes] = useState<
    (Route & { subroutes: Route[] })[]
  >([]);
  const [sideBarControl, setSideBarControl] = useState<SideBarControl>({
    isOpen: false,
    reason: null,
  });
  const [currentRote, setCurrentRote] = useState<TabRoute>();

  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [userPopup, setUserPopup] = useState(false);

  const locationIndexRef = useRef<number>(-1);

  useEffect(() => {
    locationIndexRef.current = getLocationIndex(
      sidebarLocation,
      SIDEBAR_ROUTES,
    );
    setCurrentRote((prev) => {
      if (prev) {
        return { ...prev, subrouteIndex: locationIndexRef.current };
      }
    });
  }, [sidebarLocation, selectedIndex]);

  useEffect(() => {
    setSelectedIndex(locationIndex);
  }, [locationIndex]);

  useEffect(() => {
    setTempRoutes(
      SIDEBAR_ROUTES.map((route) => ({
        ...route,
        value: `${route.value}`,
      })),
    );
  }, []);

  const togglePopup = () => {
    setUserPopup(!userPopup);
  };

  const handleLogout = () => {
    dispatch(logout());
    navigate("/login");
  };

  const handleListItemClick = (
    e: any,
    index: number,
    route: Route & { subroutes: Route[] },
  ) => {
    if (route.value === "blanc") {
      e.preventDefault();
    } else setSelectedIndex(index);

    if (!route.subroutes.length || currentRote?.name === route.name) {
      setCurrentRote(undefined);
    } else {
      setCurrentRote({ name: route.name, subrouteIndex: -1 });
    }
  };

  const toggleSideBar = () => {
    setSideBarControl((prevState) => {
      if (prevState.isOpen) {
        return {
          isOpen: false,
          reason: null,
        };
      }
      return {
        isOpen: true,
        reason: "click",
      };
    });
  };

  const changeSubroute = (index: number) => {
    setCurrentRote((previousState) => {
      if (previousState) {
        return { ...previousState, subrouteIndex: index };
      }
    });
  };

  const openSideBar = () => {
    if (!sideBarControl.isOpen) {
      setSideBarControl({
        isOpen: true,
        reason: "hover",
      });
    }
  };

  const closeSideBar = () => {
    if (sideBarControl.isOpen && sideBarControl.reason === "hover") {
      setSideBarControl({
        isOpen: false,
        reason: null,
      });
    }
  };

  return (
    <List
      component="nav"
      className={classNames("relative", styles.navigation, "!p-0", {
        "!w-[247px] !min-w-[247px]": sideBarControl.isOpen,
        "!w-[90px] !min-w-[90px]": !sideBarControl.isOpen,
      })}
      aria-label="secondary mailbox folders"
      onMouseLeave={closeSideBar}
    >
      <div
        className={classNames(
          "flex items-center",
          sideBarControl.isOpen ? "justify-between" : "justify-center",
        )}
      >
        <Logo isFullLogo={sideBarControl.isOpen} />
      </div>

      <URLInputs />
      {tempRoutes.map((route, index) => (
        <ListItem
          disablePadding
          key={index}
          className={classNames(
            "!p-0 !flex !flex-col !items-center whitespace-nowrap",
            {
              "!items-start mb-2": !sideBarControl.isOpen,
              "!items-center mb-3": sideBarControl.isOpen,
            },
          )}
          onMouseEnter={openSideBar}
        >
          <ListItemButton
            className={classNames(
              selectedIndex === index
                ? `${styles.selectedTab} ${styles.listItemTab}`
                : styles.listItemTab,
              sideBarControl.isOpen
                ? styles.openedListItem
                : styles.closedListItem,
            )}
            component={NavLink}
            to={route.value}
            selected={selectedIndex === index}
            onClick={(e) => handleListItemClick(e, index, route)}
          >
            <img
              src={route.icon ?? ""}
              alt="tab-icon"
              className="mb-2 w-[18px] h-[18px]"
            />
            <ListItemText
              disableTypography
              primary={route.name}
              className={classNames("text-sm", {
                "break-keep ml-3 text-white": sideBarControl.isOpen,
                hidden: !sideBarControl.isOpen,
              })}
            />

            {sideBarControl.isOpen && route.subroutes.length ? (
              <img
                src="/arrow-top.svg"
                alt="arrow"
                className={classNames(
                  "w-3 h-3 mr-2",
                  currentRote?.name === route.name
                    ? styles.arrow_top
                    : styles.arrow_bottom,
                )}
              />
            ) : null}
          </ListItemButton>
          {route.subroutes.length &&
          sideBarControl.isOpen &&
          currentRote?.name === route.name ? (
            <div
              className={classNames(
                styles.openedSubRoutes,
                "w-full flex flex-col pl-10 pr-5",
              )}
            >
              {route.subroutes.map((subroute, index) => (
                <NavLink
                  key={subroute.name}
                  to={subroute.value}
                  className="no-underline text-[#B0C4DE]"
                >
                  <div
                    key={subroute.name}
                    className={classNames(
                      currentRote?.subrouteIndex === index
                        ? `${styles.listItem} ${styles.selected}`
                        : styles.listItem,
                      "py-2",
                    )}
                    onClick={() => changeSubroute(index)}
                  >
                    <p>{subroute.name}</p>
                  </div>
                </NavLink>
              ))}
            </div>
          ) : null}
        </ListItem>
      ))}

      <div>
        <div className="w-full h-[1px] bg-[#B0C4DE] opacity-10" />
        <div
          className={classNames("flex relative", {
            "mx-3 flex-row justify-between items-center px-2":
              sideBarControl.isOpen,
            "flex-col": !sideBarControl.isOpen,
          })}
        >
          <div
            className={classNames({
              hidden: !sideBarControl.isOpen,
            })}
          >
            <p className="mt-4 text-white text-sm">Александр Власов</p>
            <p className="mt-1 text-sm text-[#B0C4DE]">Admin</p>
          </div>
          <img
            onClick={togglePopup}
            src="/tree-dots.svg"
            alt="tree dots"
            className={classNames("cursor-pointer", {
              "self-center mt-5": !sideBarControl.isOpen,
            })}
          />
          <div
            className={classNames(
              userPopup ? styles.popupActive : styles.popupClosed,
              styles.popup,
            )}
          >
            <ul className={styles.popup__list}>
              <li className={styles.popup__list_item} onClick={handleLogout}>
                Выйти
              </li>
            </ul>
          </div>
        </div>
        <div className={styles.selectClient}>
          {sideBarControl.isOpen && <SelectClient />}
        </div>
      </div>
      <img
        src="/arrow.svg"
        alt="arrow"
        className={classNames(
          sideBarControl.isOpen ? styles.arrow_expand : styles.arrow,
          "absolute bottom-4",
          sideBarControl.isOpen ? "right-0" : "left-1/2 -translate-x-1/2",
        )}
        onClick={toggleSideBar}
      />
    </List>
  );
};

export default SideBar;
