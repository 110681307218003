import { createSlice } from "@reduxjs/toolkit";
import { StateType } from "./types";

let localStorageClientId = localStorage.getItem('clientId');

let client_id = localStorageClientId !== null ? Number(localStorageClientId) : 103;

const initialState: StateType = {
  uploaderName: "test_55",
  clientId: client_id,
  clientName: "test_55",
  role: "admin",
}

export const globalParamsSlice = createSlice({
  name: "globalParams",
  initialState,
  reducers: {
    setGlobalParams: (state, action) => {
      state.uploaderName = action.payload.uploaderName;
      state.clientId = action.payload.clientId;
      state.role = action.payload.role;
      state.clientName = action.payload.clientName;
    },
    changeClientId: (state, action) => {
      state.clientId = action.payload;
      localStorage.setItem('clientId', state.clientId.toString());
    }
  },
});

export const { setGlobalParams, changeClientId } = globalParamsSlice.actions;
export const globalParamsReducer = globalParamsSlice.reducer;
