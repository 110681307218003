import { createSlice } from "@reduxjs/toolkit";
import { getClientsProfiles, uploadClientsProfiles } from "./actions";
import { MappingState } from "./types";

const initialState: MappingState = {
  getClientsProfilesData: null,
  getClientsProfilesLoading: false,
  getClientsProfilesError: null,
  uploadClientsProfilesData: null,
  uploadClientsProfilesLoading: false,
  uploadClientsProfilesError: null,
};

const mappingSlice = createSlice({
  name: 'mapping',
  initialState,
  reducers: {
    clearUploadClientsProfilesData: (state) => {
      state.uploadClientsProfilesData = null;
      state.uploadClientsProfilesError = null;
    }
  },
  extraReducers: (builder) => {
    builder.addCase(getClientsProfiles.pending, (state) => {
      state.getClientsProfilesLoading = true;
      state.getClientsProfilesError = null;
    })
    .addCase(getClientsProfiles.fulfilled, (state, action) => {
      state.getClientsProfilesLoading = false;
      state.getClientsProfilesData = action.payload;
    })
    .addCase(getClientsProfiles.rejected, (state, action) => {
      state.getClientsProfilesLoading = false;
      if (typeof action.payload === "string")
        state.getClientsProfilesError = action.payload;
    });
    builder.addCase(uploadClientsProfiles.pending, state => {
      state.uploadClientsProfilesLoading = true;
      state.uploadClientsProfilesError = null;
    })
    .addCase(uploadClientsProfiles.fulfilled, (state, action) => {
      state.uploadClientsProfilesLoading = false;
      state.uploadClientsProfilesData = action.payload;
    })
    .addCase(uploadClientsProfiles.rejected, (state, action) => {
      state.uploadClientsProfilesLoading = false;
      if (typeof action.payload === "string")
        state.uploadClientsProfilesError = action.payload;
    });
  }
})

export const { clearUploadClientsProfilesData } = mappingSlice.actions;
export const mappingReducer = mappingSlice.reducer;