// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.styles_header__bwQ6D {
  display: flex;
  gap: 20px;
  align-items: center;
  margin-bottom: 18px;
}

.styles_header__bwQ6D button {
  margin-top: 5px;
}`, "",{"version":3,"sources":["webpack://./src/pages/media-plan-choose-page/styles.module.sass"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,SAAA;EACA,mBAAA;EACA,mBAAA;AACF;;AACA;EACE,eAAA;AAEF","sourcesContent":[".header\n  display: flex\n  gap: 20px\n  align-items: center\n  margin-bottom: 18px\n\n.header button\n  margin-top: 5px\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"header": `styles_header__bwQ6D`
};
export default ___CSS_LOADER_EXPORT___;
