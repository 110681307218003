// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.styles_period__2XYua {
  font-size: 0.875rem;
  font-weight: 500;
  color: #54687D;
  line-height: 20px;
  margin-top: 4px;
}
.styles_period__2XYua span {
  color: #0C3953;
  font-weight: 400;
  margin-left: 8px;
}`, "",{"version":3,"sources":["webpack://./src/pages/publish-plan/styles.module.sass"],"names":[],"mappings":"AAAA;EACE,mBAAA;EACA,gBAAA;EACA,cAAA;EACA,iBAAA;EACA,eAAA;AACF;AACE;EACE,cAAA;EACA,gBAAA;EACA,gBAAA;AACJ","sourcesContent":[".period\n  font-size: 0.875rem\n  font-weight: 500\n  color: #54687D\n  line-height: 20px\n  margin-top: 4px\n\n  span\n    color: #0C3953\n    font-weight: 400\n    margin-left: 8px\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"period": `styles_period__2XYua`
};
export default ___CSS_LOADER_EXPORT___;
