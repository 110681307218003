import { initState } from "common/const";

import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import {
  MessageResponse,
  SourceData,
  StateType,
} from "./types";
import { fetchClientSources } from "./actions";



const initialState: StateType = {
  source: initState,
  sourceUpdate: initState,
};

const sourcesSlice = createSlice({
  name: "sources",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchClientSources.pending, (state) => {
        state.source.loading = true;
        state.source.error = null;
      })
      .addCase(
        fetchClientSources.fulfilled,
        (state, action: PayloadAction<SourceData | MessageResponse>) => {
          state.source.loading = false;
          state.source.data = action.payload;
        },
      )
      .addCase(fetchClientSources.rejected, (state, action) => {
        state.source.loading = false;
        if (typeof action.payload === "string")
          state.source.error = action.payload;
      });
  },
});

export const settingsReducer = sourcesSlice.reducer;
export default {
  settingsReducer,
};
