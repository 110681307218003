// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.styles_error__W7sZj {
  color: #c10000 !important;
  background-color: #fff4f4;
}`, "",{"version":3,"sources":["webpack://./src/components/Filters/Select/styles.module.sass"],"names":[],"mappings":"AAEA;EACE,yBAAA;EACA,yBAAA;AADF","sourcesContent":["@import \"../../../common/styles/variables/font-style\"\n\n.error\n  color: $error !important\n  background-color: lighten($error, 60%)\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"error": `styles_error__W7sZj`
};
export default ___CSS_LOADER_EXPORT___;
