import { createSlice } from "@reduxjs/toolkit";
import RecruitmentPlanData from "./types";
import { getRecruitmentPlan, getRecruitmentPlans, postRecruitmentPlan } from "./actions";


const initialState: RecruitmentPlanData = {
  uploadRecruitmentPlanData: null,
  uploadRecruitmentPlanError: null,
  uploadRecruitmentPlanLoading: false,
  getRecruitmentPlanData: null,
  getRecruitmentPlanError: null,
  getRecruitmentPlanLoading: false,
  getRecruitmentPlansData: null,
  getRecruitmentPlansError: null,
  getRecruitmentPlansLoading: false,
};

const recruitmentSlice = createSlice({
  name: 'recruitment',
  initialState,
  reducers: {
    clearRecruitmentData: (state) => {
      state.getRecruitmentPlanData = null;
    }
  },
  extraReducers: (builder) => {
    builder.addCase(postRecruitmentPlan.pending, state => {
      state.uploadRecruitmentPlanLoading = true;
      state.uploadRecruitmentPlanError = null;
    })
    .addCase(postRecruitmentPlan.fulfilled, (state, action) => {
      state.uploadRecruitmentPlanData = action.payload;
      state.uploadRecruitmentPlanLoading = false;
    })
    .addCase(postRecruitmentPlan.rejected, (state, action) => {
      state.uploadRecruitmentPlanLoading = false;
      if (typeof action.payload === "string")
        state.uploadRecruitmentPlanError = action.payload;
    })
    builder.addCase(getRecruitmentPlan.pending, state => {
      state.getRecruitmentPlanLoading = true;
      state.getRecruitmentPlanError = null;
    })
    .addCase(getRecruitmentPlan.fulfilled, (state, action) => {
      state.getRecruitmentPlanData = action.payload;
      state.getRecruitmentPlanLoading = false;
    })
    .addCase(getRecruitmentPlan.rejected, (state, action) => {
      state.getRecruitmentPlanLoading = false;
      if (typeof action.payload === "string")
        state.getRecruitmentPlanError = action.payload;
    })
    builder.addCase(getRecruitmentPlans.pending, state => {
      state.getRecruitmentPlansLoading = true;
      state.getRecruitmentPlansError = null;
    })
    .addCase(getRecruitmentPlans.fulfilled, (state, action) => {
      state.getRecruitmentPlansData = action.payload;
      state.getRecruitmentPlansLoading = false;
    })
    .addCase(getRecruitmentPlans.rejected, (state, action) => {
      state.getRecruitmentPlanLoading = false;
      if (typeof action.payload === "string")
        state.getRecruitmentPlanError = action.payload;
    })
  }
})

export const { clearRecruitmentData } = recruitmentSlice.actions;

export default recruitmentSlice.reducer

