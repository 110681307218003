import { PublicationPlan } from "services/store/reducers/media/types";

export function getFactorName(data: PublicationPlan) {
  if (data.source === 'hv') {
    if (data.factor_1 === 1) {
      return 'Стандарт';
    } else if (data.factor_2 === 1) {
      return 'Стандарт+';
    } else if (data.factor_3 === 1) {
      return 'Премиум';
    } else return '-';
  } else if (data.source === 'av') {
    if (data.factor_1 === 1) {
      return 'Публикация';
    } else if (data.factor_3 === 1) {
      return 'Продвижение'
    } else return '-'
  }

}