import { createAsyncThunk } from "@reduxjs/toolkit";
import axiosInstance from "services/store/axiosInstance";
import { getClientsProfilesMapping, uploadClientsProfilesParams, uploadClientsProfilesResponse } from "./types";
import { handleParams } from "common/utils/handleParams";
import { AxiosResponse } from "axios";

export const getClientsProfiles = createAsyncThunk(
  "mapping/getClientsProfiles",
  async (client_id: number, { rejectWithValue}) => {
    try {
      const response: AxiosResponse<getClientsProfilesMapping[]> = await axiosInstance.get(
        `mappings/get-clients-profiles/${client_id}`
      )
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response?.data || error.message);
    }
  }
)

export const uploadClientsProfiles = createAsyncThunk(
  "mapping/uploadClientsProfiles",
  async ({params, file}: {params: uploadClientsProfilesParams, file: File}, { rejectWithValue }) => {
    try {
      const formData = new FormData();
      formData.append('file', file);
      const urlParams = handleParams(params);
      const response: AxiosResponse<uploadClientsProfilesResponse> = await axiosInstance.post(
        `mappings/upload-clients-profiles${urlParams}`,
        formData,
        { headers: { 'Content-Type' : 'multipart/form-data'}}
      )
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response?.data || error.message);
    }
  }
)