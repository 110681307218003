// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.styles_confirmation__T1r5q {
  margin: 20px auto 0;
}

.styles_upload__PRe-r {
  display: flex;
  gap: 20px;
}`, "",{"version":3,"sources":["webpack://./src/pages/issued/styles.module.sass"],"names":[],"mappings":"AAAA;EACE,mBAAA;AACF;;AACA;EACE,aAAA;EACA,SAAA;AAEF","sourcesContent":[".confirmation\n  margin: 20px auto 0\n\n.upload\n  display: flex\n  gap: 20px\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"confirmation": `styles_confirmation__T1r5q`,
	"upload": `styles_upload__PRe-r`
};
export default ___CSS_LOADER_EXPORT___;
