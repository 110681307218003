// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.styles_fit__r06oN {
  display: block;
  max-width: fit-content;
}

.styles_info__Ys4M7 {
  display: flex;
  align-items: center;
  gap: 22px;
}

.styles_label__KIE2s {
  color: #0C3953;
  font-size: 0.9rem;
}`, "",{"version":3,"sources":["webpack://./src/components/UI/Upload/styles.module.sass"],"names":[],"mappings":"AAAA;EACE,cAAA;EACA,sBAAA;AACF;;AACA;EACE,aAAA;EACA,mBAAA;EACA,SAAA;AAEF;;AAAA;EACE,cAAA;EACA,iBAAA;AAGF","sourcesContent":[".fit\n  display: block\n  max-width: fit-content\n\n.info\n  display: flex\n  align-items: center\n  gap: 22px\n\n.label\n  color: #0C3953\n  font-size: 0.9rem\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"fit": `styles_fit__r06oN`,
	"info": `styles_info__Ys4M7`,
	"label": `styles_label__KIE2s`
};
export default ___CSS_LOADER_EXPORT___;
