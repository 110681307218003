// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.common-styles_download__PysZa {
  width: 30px !important;
  height: 30px !important;
}

.common-styles_details__cxDsv {
  display: flex;
  justify-content: space-between;
}

.common-styles_detail__5TSMR {
  display: inline-block;
}

.common-styles_heading__Rf9PX {
  display: flex;
  gap: 15px;
}`, "",{"version":3,"sources":["webpack://./src/pages/common-styles.module.sass"],"names":[],"mappings":"AAAA;EACE,sBAAA;EACA,uBAAA;AACF;;AACA;EACE,aAAA;EACA,8BAAA;AAEF;;AAAA;EACE,qBAAA;AAGF;;AADA;EACE,aAAA;EACA,SAAA;AAIF","sourcesContent":[".download\n  width: 30px !important\n  height: 30px !important\n\n.details\n  display: flex\n  justify-content: space-between\n\n.detail\n  display: inline-block\n\n.heading\n  display: flex\n  gap: 15px\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"download": `common-styles_download__PysZa`,
	"details": `common-styles_details__cxDsv`,
	"detail": `common-styles_detail__5TSMR`,
	"heading": `common-styles_heading__Rf9PX`
};
export default ___CSS_LOADER_EXPORT___;
