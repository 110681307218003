// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.styles_smallInput__yoiE8 > div {
  height: 30px;
}

.styles_box__bYzQK {
  width: 300px;
}

.styles_alignRight__9-0mc {
  display: flex !important;
  align-items: flex-end;
}

.styles_alignCenter__9oTOa {
  align-items: center;
}

.styles_dates__z2teW {
  display: flex;
  gap: 10px;
}`, "",{"version":3,"sources":["webpack://./src/components/ConfigMediaplan/styles.module.sass"],"names":[],"mappings":"AACE;EACE,YAAA;AAAJ;;AAEA;EACE,YAAA;AACF;;AACA;EACE,wBAAA;EACA,qBAAA;AAEF;;AAAA;EACE,mBAAA;AAGF;;AADA;EACE,aAAA;EACA,SAAA;AAIF","sourcesContent":[".smallInput\n  & > div\n    height: 30px\n\n.box\n  width: 300px\n\n.alignRight\n  display: flex !important\n  align-items: flex-end\n\n.alignCenter\n  align-items: center\n\n.dates\n  display: flex\n  gap: 10px\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"smallInput": `styles_smallInput__yoiE8`,
	"box": `styles_box__bYzQK`,
	"alignRight": `styles_alignRight__9-0mc`,
	"alignCenter": `styles_alignCenter__9oTOa`,
	"dates": `styles_dates__z2teW`
};
export default ___CSS_LOADER_EXPORT___;
