import { useLocation, useNavigate } from "react-router";

import { Button } from "@mui/material";

import { MEDIA_PLAN_MULTI_ROUTE, MEDIA_PLAN_ROUTE } from "common/const";

import styles from "./styles.module.scss";

interface IBackBtn {
  isTrue?: boolean;
}

export default function BackBtn({ isTrue }: IBackBtn) {
  const { pathname } = useLocation();
  const navigate = useNavigate();

  const isActive = (): boolean => {
    if (pathname === MEDIA_PLAN_MULTI_ROUTE || pathname === MEDIA_PLAN_ROUTE) {
      return true;
    } else return false;
  };

  return isActive() || isTrue ? (
    <div className={styles.backBtn}>
      <Button variant="text" onClick={() => navigate(-1)}>
        Назад
      </Button>
    </div>
  ) : null;
}
