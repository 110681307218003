import { useCallback, useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";

import classNames from "classnames";
import {
  CHOOSE_MEDIA_PLAN_ROUTE,
  MEDIA_PLAN_MULTI_ROUTE,
  MEDIA_PLAN_ROUTE,
  SIDEBAR_ROUTES,
} from "common/const";

import ShevronRight from "../ShevronRight";

interface BreadCrumb {
  text: string;
  link: string;
}

export interface BreadCrumbsProps extends React.HTMLAttributes<HTMLDivElement> {
  breadCrumbs: BreadCrumb[];
}

export const BreadCrumbMultiTable: React.FC<BreadCrumbsProps> = ({
  breadCrumbs,
}) => {
  const [pace, setPace] = useState<number>(0);
  const { pathname } = useLocation();

  const isMediaPlan = useCallback(() => {
    return SIDEBAR_ROUTES[0].subroutes.some(
      (route) => route.value === pathname,
    );
  }, [pathname]);

  const isPublishPlan = useCallback(() => {
    return SIDEBAR_ROUTES[1].subroutes.some(
      (route) => route.value === pathname,
    );
  }, [pathname]);

  useEffect(() => {
    if (isMediaPlan()) {
      setPace(0);
    } else if (
      isPublishPlan() ||
      `/${pathname.split("/")[1]}` === CHOOSE_MEDIA_PLAN_ROUTE
    ) {
      setPace(1);
    }
  }, [breadCrumbs, isMediaPlan, isPublishPlan, pathname]);

  return pathname === MEDIA_PLAN_MULTI_ROUTE ||
    pathname === MEDIA_PLAN_ROUTE ? null : (
    <div className="flex gap-[6px]">
      {breadCrumbs.map((crump, index, arr) => (
        <div key={crump.text} className="flex gap-[6px] items-center">
          {crump.link && index < pace ? (
            <Link
              to={crump.link}
              className={classNames(
                "no-underline text-[#217EC6] font-semibold text-xs",
              )}
            >
              {crump.text}
            </Link>
          ) : (
            <p
              className={classNames("text-xs", {
                "font-bold text-[#0C3953] cursor-default": pace === index,
                "text-[#C6CED8]": pace < index,
              })}
            >
              {crump.text}
            </p>
          )}

          {index !== arr.length - 1 && index < pace ? (
            <ShevronRight stroke="#217EC6" />
          ) : index !== arr.length - 1 ? (
            <ShevronRight />
          ) : null}
        </div>
      ))}
    </div>
  );
};
