import { Dispatch, SetStateAction, useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router";

import DownloadIcon from "@mui/icons-material/Download";
import Checkbox from "@mui/material/Checkbox";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";

import { useEffectOnce } from "common/hooks/useEffectOnce";
import { handleDate } from "common/utils/handleDate";
import FilterPanel from "components/UI/FilterPanel";
import { HiddenLink } from "components/UI/HiddenLink";
import { Loader } from "components/UI/icons/Loader";
import { MUIIconButton } from "components/UI/MUIIconButton";
import { Pagination } from "components/UI/Pagination";
import { getRecruitmentPlan } from "services/store/reducers/recruitment/actions";
import { RecruitmentPlanUpload } from "services/store/reducers/recruitment/types";
import { useAppDispatch, useAppSelector } from "services/store/store";

import commonStyles from "../common-styles.module.sass";
import { FILTERED_KEYS } from "../MediaPlanMultiTable/constants";
import { FILTERED_VALUES } from "./constants";
import { filterData } from "./helpers";
import styles from "./styles.module.sass";
import { columns, FilteredValue, Row } from "./types";

interface Props {
  data: RecruitmentPlanUpload[];
  choosePlan?: Dispatch<SetStateAction<number | null>>;
  navigable?: boolean;
}

export default function PlanUploadsTable({
  data,
  choosePlan,
  navigable = false,
}: Props) {
  const [page, setPage] = useState(0);
  const [rowsPerPage] = useState(10);
  const [selected, setSelected] = useState<number>(0);
  const [isFilterModalOpen, setIsFilterModalOpen] = useState(false);
  const [isNeedToFilter, setIsNeedToFilter] = useState(false);
  const [rows, setRows] = useState<(Row & { isSelected: boolean })[]>([]);
  const [selectedColumnId, setSelectedColumnId] = useState<"planId" | "date">();
  const navigate = useNavigate();
  const [loadingPlans, setLoadingPlans] = useState<Set<number>>();
  const { getRecruitmentPlanData, getRecruitmentPlanLoading } = useAppSelector(
    (state) => state.recr,
  );

  const downloadRef = useRef<HTMLAnchorElement>(null);

  useEffect(() => {
    if (data) {
      const sortedData = data.sort((a, b) => b.id - a.id);
      setRows(
        sortedData.map((row: RecruitmentPlanUpload) => {
          return {
            planId: row.id,
            date: handleDate(row.uploaded_at, true, true),
            author: row.uploader_name,
            isSelected: true,
          };
        }),
      );
    }
  }, [data]);

  const [filteredData, setFilteredData] = useState<FilteredValue[]>([]);
  const isSelected = (id: number): boolean => selected === id;
  const handleChangePage = (event: unknown, newPage: number): void => {
    setPage(newPage);
  };
  const dispatch = useAppDispatch();

  const toggleLoading = (id: number, isLoading: boolean): void => {
    setLoadingPlans((prev) => {
      const updated = new Set(prev);
      if (isLoading) {
        updated.add(id);
      } else {
        updated.delete(id);
      }
      return updated;
    });
  };

  const handleGetExcel = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    id: number,
  ): void => {
    event.preventDefault();
    event.stopPropagation();

    toggleLoading(id, true);
    dispatch(getRecruitmentPlan({ plan_id: id, excel: true }))
      .then(() => {
        toggleLoading(id, false);
      })
      .catch(() => {
        toggleLoading(id, false);
      });
  };

  const handleNavigate = (plan: number): void => {
    navigate(`/mediaplan?plan=selection&id=${plan}&history=selection`);
  };

  const handleChoose = (id: number): void => {
    if (selected === id) return;
    setSelected(id);
    if (choosePlan) choosePlan(data[id].id);
  };

  const openFilterModal = (id: string) => {
    setIsFilterModalOpen(true);

    if (id === "planId" || id === "date") {
      setSelectedColumnId(id);
    }
  };

  const closeFilterModal = () => {
    setIsFilterModalOpen(false);
    setSelectedColumnId(undefined);
  };

  const saveFilteredData = (values: Record<string, boolean>) => {
    const transFormValues =
      selectedColumnId === "date"
        ? Object.fromEntries(
            Object.entries(values).map(([field, value]) => [
              field.split("_").join("."),
              value,
            ]),
          )
        : values;
    setFilteredData((previousState) => [
      ...previousState.filter((category) => category.key !== selectedColumnId),
      ...(previousState.find((category) => category.key === selectedColumnId)
        ? [
            {
              key:
                previousState.find(
                  (category) => category.key === selectedColumnId,
                )?.key ?? FILTERED_KEYS[0],
              values: Object.keys(transFormValues).map((key) => ({
                value: key,
                isSelected: transFormValues[key],
              })),
            },
          ]
        : []),
    ]);
    setIsFilterModalOpen(false);
    setIsNeedToFilter(true);
  };

  useEffectOnce(() => {
    const filteredData = FILTERED_VALUES.map((field) => ({
      key: field,
      values: [...new Set(rows.map((row) => row[field]))].map((value) => ({
        value,
        isSelected: true,
      })),
    }));

    setFilteredData(filteredData);
  }, !!rows.length);

  useEffect(() => {
    if (filteredData && isNeedToFilter) {
      setRows(filterData(rows, filteredData));
      setIsNeedToFilter(false);
    }
  }, [filteredData, isNeedToFilter, rows]);

  useEffect(() => {
    if (choosePlan) choosePlan(data[selected].id);
  }, [data, choosePlan, selected]);

  useEffect(() => {
    if (
      getRecruitmentPlanData &&
      !getRecruitmentPlanLoading &&
      getRecruitmentPlanData.response
    ) {
      downloadRef.current?.setAttribute(
        "href",
        getRecruitmentPlanData.response.fileLink,
      );
      downloadRef.current?.setAttribute("download", "recruitment_plan.xlsx");
      downloadRef.current?.setAttribute("target", "_blanc");
      downloadRef.current?.click();
    }
  }, [getRecruitmentPlanLoading, getRecruitmentPlanData, dispatch]);

  return (
    <>
      <Paper className={commonStyles.table}>
        <TableContainer sx={{ maxHeight: "calc(100vh - 350px)" }}>
          <Table stickyHeader aria-label="sticky table">
            <TableHead>
              <TableRow>
                {!navigable && <TableCell padding="checkbox"></TableCell>}
                {columns.map((column, id) => (
                  <TableCell className={commonStyles.tableHead} key={id}>
                    <div
                      className="flex gap-2"
                      onClick={
                        column.id !== "author"
                          ? () => openFilterModal(column.id)
                          : undefined
                      }
                    >
                      {column.label}
                    </div>
                  </TableCell>
                ))}
                {navigable && <TableCell padding="checkbox"></TableCell>}
              </TableRow>
            </TableHead>
            <TableBody>
              {rows
                .filter((row) => row.isSelected)
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row, id): JSX.Element => {
                  const isItemSelected: boolean = isSelected(id);
                  return (
                    <TableRow
                      hover
                      onClick={(): void =>
                        navigable
                          ? handleNavigate(row.planId)
                          : handleChoose(id)
                      }
                      aria-checked={isItemSelected}
                      role="checkbox"
                      tabIndex={-1}
                      key={id}
                      className={commonStyles.hoverable}
                    >
                      {!navigable && (
                        <TableCell padding="checkbox">
                          <Checkbox checked={isItemSelected} color="primary" />
                        </TableCell>
                      )}
                      {columns.map((column) => {
                        const value = row[column.id];
                        return (
                          <TableCell
                            className={commonStyles.dataCell}
                            key={column.id}
                          >
                            {value}
                          </TableCell>
                        );
                      })}
                      {navigable && (
                        <TableCell padding="checkbox">
                          <MUIIconButton
                            className={styles.downloadButton}
                            icon={
                              loadingPlans?.has(row.planId) ? (
                                <Loader
                                  color="#217EC6"
                                  width={20}
                                  height={20}
                                />
                              ) : (
                                <DownloadIcon
                                  className={styles.download}
                                  color="success"
                                />
                              )
                            }
                            onClick={(
                              event: React.MouseEvent<
                                HTMLButtonElement,
                                MouseEvent
                              >,
                            ) => handleGetExcel(event, row.planId)}
                          />
                        </TableCell>
                      )}
                    </TableRow>
                  );
                })}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
      <Pagination
        total={rows.length}
        limit={rowsPerPage}
        currentPage={page}
        onPageChange={handleChangePage}
        className={styles.pagination}
      />

      <FilterPanel
        isOpen={isFilterModalOpen}
        columnName={
          columns.find(({ id }) => id === selectedColumnId)?.label ?? ""
        }
        data={
          selectedColumnId
            ? (filteredData
                .find((item) => item.key === selectedColumnId)
                ?.values.map((field) => ({
                  ...field,
                  value:
                    selectedColumnId === "date"
                      ? field.value.split(".").join("_")
                      : String(field.value),
                  isKeyShouldBeReplaced: !!(selectedColumnId === "date"),
                })) ?? [])
            : []
        }
        onClose={closeFilterModal}
        onSave={(values: Record<string, boolean>) => saveFilteredData(values)}
      />
      <HiddenLink ref={downloadRef} />
    </>
  );
}
