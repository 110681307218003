// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.common-styles_table__4Gdxj {
  overflow: hidden;
  box-shadow: none !important;
}

.common-styles_tableHead__-X59p {
  border-bottom: 1px solid #C6CED8 !important;
  color: #54687D !important;
  line-height: 16px !important;
  padding: 13px 16px !important;
}

.common-styles_dataCell__HRkBZ {
  padding: 8px 16px !important;
  border-bottom: 1px solid #f2f2f2 !important;
  color: #0C3953 !important;
}
.common-styles_dataCell__HRkBZ a {
  color: #217EC6 !important;
}

.common-styles_pagination__FJE7I {
  margin-top: 18px;
}

.common-styles_hoverable__EXn4N {
  cursor: pointer;
}

.common-styles_table_container__k9Ytj {
  overflow-y: auto;
  max-height: calc(100vh - 228px);
}

.common-styles_loader_container__kuKiO {
  height: calc(100vh - 150px);
  display: flex;
  align-items: center;
  justify-content: center;
}`, "",{"version":3,"sources":["webpack://./src/components/tables/common-styles.module.sass"],"names":[],"mappings":"AAGA;EACE,gBAAA;EACA,2BAAA;AAFF;;AAIA;EACE,2CAAA;EACA,yBAAA;EACA,4BAAA;EACA,6BAAA;AADF;;AAGA;EACE,4BAAA;EACA,2CAAA;EACA,yBAAA;AAAF;AAEE;EACE,yBAAA;AAAJ;;AAEA;EACE,gBAAA;AACF;;AACA;EACE,eAAA;AAEF;;AAAA;EACE,gBAAA;EACA,+BAAA;AAGF;;AADA;EACE,2BAAA;EACA,aAAA;EACA,mBAAA;EACA,uBAAA;AAIF","sourcesContent":["$padding-top: 13px\n$border-bottom-width: 1px\n\n.table\n  overflow: hidden\n  box-shadow: none !important\n\n.tableHead\n  border-bottom: 1px solid #C6CED8 !important\n  color: #54687D !important\n  line-height: 16px !important\n  padding: $padding-top 16px !important\n\n.dataCell\n  padding: 8px 16px !important\n  border-bottom: $border-bottom-width solid #f2f2f2 !important\n  color: #0C3953 !important\n\n  & a\n    color: #217EC6 !important\n\n.pagination\n  margin-top: 18px\n\n.hoverable\n  cursor: pointer\n\n.table_container\n  overflow-y: auto\n  max-height: calc(100vh - 228px)\n\n.loader_container\n  height: calc(100vh - 150px)\n  display: flex\n  align-items: center\n  justify-content: center\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"table": `common-styles_table__4Gdxj`,
	"tableHead": `common-styles_tableHead__-X59p`,
	"dataCell": `common-styles_dataCell__HRkBZ`,
	"pagination": `common-styles_pagination__FJE7I`,
	"hoverable": `common-styles_hoverable__EXn4N`,
	"table_container": `common-styles_table_container__k9Ytj`,
	"loader_container": `common-styles_loader_container__kuKiO`
};
export default ___CSS_LOADER_EXPORT___;
