import { useState } from "react";

import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";

import classNames from "classnames";
import { CandidatesDataColumn, EMPTY_DATA } from "common/const";
import { handleDate } from "common/utils/handleDate";
import { Loader } from "components/UI/Loader";
import { Pagination } from "components/UI/Pagination";
import { CandidatesData } from "services/store/reducers/issued/types";
import { useAppSelector } from "services/store/store";

import commonStyles from "../common-styles.module.sass";
import styles from "./styles.module.sass";

interface Props {
  columns: readonly CandidatesDataColumn[];
  perPage?: number;
}

export default function CandidatesDataTable({
  columns,
  perPage,
}: Props): JSX.Element {
  const { data, loading } = useAppSelector((state) => state.issued);
  const [page, setPage] = useState(0);
  const [rowsPerPage] = useState(perPage ?? 30);

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  return loading ? (
    <Loader />
  ) : (
    <>
      <Paper className={commonStyles.table} style={{ whiteSpace: "nowrap" }}>
        <TableContainer sx={{ maxHeight: "calc(100vh - 350px)" }}>
          {data ? (
            <Table stickyHeader aria-label="sticky table">
              <TableHead>
                <TableRow>
                  {columns.map((column, id) => (
                    <TableCell className={commonStyles.tableHead} key={id}>
                      {column.label}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {data
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row: CandidatesData, id: number) => {
                    return (
                      <TableRow role="checkbox" tabIndex={-1} key={id}>
                        {columns.map((column: CandidatesDataColumn) => {
                          const value =
                            column.id === "date"
                              ? handleDate(row[column.id], false, true)
                              : column.id === "day_of_week"
                                ? "Пятница"
                                : column.id === "working"
                                  ? "Рабочий"
                                  : column.id === "time"
                                    ? "22:52"
                                    : row[column.id];
                          return (
                            <TableCell
                              className={
                                column.id === "shop"
                                  ? classNames(
                                      styles.capitilizedCell,
                                      commonStyles.dataCell,
                                    )
                                  : commonStyles.dataCell
                              }
                              key={column.id}
                            >
                              {value}
                            </TableCell>
                          );
                        })}
                      </TableRow>
                    );
                  })}
              </TableBody>
            </Table>
          ) : (
            <p>{EMPTY_DATA}</p>
          )}
        </TableContainer>
      </Paper>
      {data && (
        <Pagination
          total={data.length}
          limit={rowsPerPage}
          currentPage={page}
          onPageChange={handleChangePage}
          className={commonStyles.pagination}
        />
      )}
    </>
  );
}
