import { useState } from "react";

import DeleteIcon from "@mui/icons-material/Delete";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";

import { EMPTY_DATA, HvAvDataColumn } from "common/const";
import { handleDate } from "common/utils/handleDate";
import { MUIIconButton } from "components/UI/MUIIconButton";
import { Pagination } from "components/UI/Pagination";
import { HvAvData } from "services/store/reducers/ws-data/types";

import commonStyles from "../common-styles.module.sass";
import { DataCell } from "./types";

interface Props {
  data: HvAvData[] | null;
  onDeletion: (id: number) => void;
  columns: readonly HvAvDataColumn[];
  perPage?: number;
}

export default function HvAvDataTable({
  data,
  perPage,
  onDeletion: handleDelete,
  columns,
}: Props) {
  const [page, setPage] = useState(0);
  const [rowsPerPage] = useState(perPage ?? 30);

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  return (
    <>
      <Paper className={commonStyles.table}>
        <TableContainer sx={{ maxHeight: "calc(100vh - 350px)" }}>
          {data ? (
            <Table stickyHeader aria-label="sticky table">
              <TableHead>
                <TableRow>
                  {columns.map((column, id) => (
                    <TableCell className={commonStyles.tableHead} key={id}>
                      <div className="flex flex-row gap-2">{column.label}</div>
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {data
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row: HvAvData, id: number) => {
                    return (
                      <TableRow hover tabIndex={-1} key={id}>
                        {columns.map((column: HvAvDataColumn) => {
                          let value: DataCell;
                          switch (column.id) {
                            case "uploaded_at":
                              value = handleDate(row[column.id], false, true);
                              break;
                            case "manage":
                              value = (
                                <MUIIconButton
                                  icon={<DeleteIcon color="error" />}
                                  onClick={() => handleDelete(row.id)}
                                />
                              );
                              break;
                            default:
                              value = row[column.id];
                          }

                          return (
                            <TableCell
                              className={`${commonStyles.dataCell} ${
                                column.id === "manage" && "!text-center"
                              } ${column.id === "region" && "capitalize"}`}
                              key={column.id}
                            >
                              {value}
                            </TableCell>
                          );
                        })}
                      </TableRow>
                    );
                  })}
              </TableBody>
            </Table>
          ) : (
            <p>{EMPTY_DATA}</p>
          )}
        </TableContainer>
      </Paper>
      {data && (
        <Pagination
          total={data.length}
          limit={rowsPerPage}
          currentPage={page}
          onPageChange={handleChangePage}
          className={commonStyles.pagination}
        />
      )}
    </>
  );
}
