import { ChangeEvent } from "react";

import styles from "./styles.module.sass";

interface RateProps {
  value?: number | string | undefined;
  onChange?: (event: ChangeEvent<HTMLInputElement>) => void;
  type?: string;
  style?: React.CSSProperties;
  error?: string;
  name?: string;
}

const RateInput: React.FC<RateProps> = ({
  value,
  onChange,
  type = "number",
  style,
  error,
  name,
}): JSX.Element => {
  return (
    <>
      <input
        name={name}
        type={type}
        value={value ?? ""}
        className={styles.rate}
        onChange={onChange}
        style={style}
      />
      {error && <div className={styles.error}>{error}</div>}
    </>
  );
};

export default RateInput;
