import { createSlice } from "@reduxjs/toolkit";
import { StateType } from "./types";
import { getObjects, startClusterasation } from "./actions";

const initialState: StateType = {
  getObjectsData: null,
  getObjectsLoading: false,
  getObjectsError: null,
  startClusterisationData: null,
  startClusterisationLoading: false,
  startClusterisationError: null,
}

const objectSlice = createSlice({
  name: 'object',
  initialState,
  reducers: {
    clearClusterisationData : (state) => {
      state.startClusterisationData = null;
      state.startClusterisationLoading = false;
      state.startClusterisationError = null;
    }
  },
  extraReducers: (builder) => {
    builder.addCase(getObjects.pending, state => {
      state.getObjectsLoading = true;
      state.getObjectsError = null;
    })
    .addCase(getObjects.fulfilled, (state, action) => {
      state.getObjectsLoading = false;
      state.getObjectsData = action.payload;
    })
    .addCase(getObjects.rejected, (state, action) => {
      state.getObjectsLoading = false;
      if (typeof action.payload === "string")
        state.getObjectsError = action.payload;
    })
    builder.addCase(startClusterasation.pending, state => {
      state.startClusterisationLoading = true;
      state.startClusterisationError = null;
    })
    .addCase(startClusterasation.fulfilled, (state, action) => {
      state.startClusterisationLoading = false;
      state.startClusterisationData = action.payload;
    })
    .addCase(startClusterasation.rejected, (state, action) => {
      state.startClusterisationLoading = false;
      if (typeof action.payload === "string")
        state.startClusterisationError = action.payload;
    })
  }
})

export const { clearClusterisationData } = objectSlice.actions;
export default objectSlice.reducer