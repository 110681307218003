import { initState } from "common/const";

import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import {
  DeleteHvAvDataResponse,
  HvAvData,
  StateType,
} from "./types";
import { deleteHvAvData, fetchHvAvData, postHvAvData } from "./actions";

const initialState: StateType = {
  hvAvData: initState,
  hvAvDelete: initState,
  hvAvPost: initState
};

const wsDataSlice = createSlice({
  name: "wsData",
  initialState,
  reducers: {
    clearData(state) {
      state.hvAvData.data = null;
      state.hvAvData.error = null;
      state.hvAvPost.data = null;
      state.hvAvPost.error = null;
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchHvAvData.pending, (state) => {
        state.hvAvData.loading = true;
        state.hvAvData.error = null;
      })
      .addCase(
        fetchHvAvData.fulfilled,
        (state, action: PayloadAction<HvAvData[]>) => {
          state.hvAvData.loading = false;
          state.hvAvData.data = action.payload;
        },
      )
      .addCase(fetchHvAvData.rejected, (state, action) => {
        state.hvAvData.loading = false;
        if (typeof action.payload === "string")
          state.hvAvData.error = action.payload;
      });

    builder
      .addCase(deleteHvAvData.pending, (state) => {
        state.hvAvDelete.loading = true;
        state.hvAvDelete.error = null;
      })
      .addCase(
        deleteHvAvData.fulfilled,
        (state, action: PayloadAction<DeleteHvAvDataResponse>) => {
          state.hvAvDelete.loading = false;
          state.hvAvDelete.data = action.payload;
          const message: string | undefined = action.payload.response.message;
          if (message && state.hvAvData.data) {
            state.hvAvData.data = state.hvAvData.data.filter(
              (entry: HvAvData) => entry.id !== Number(message.split("id")[1]),
            );
          }
        },
      )
      .addCase(deleteHvAvData.rejected, (state, action) => {
        state.hvAvDelete.loading = false;
        if (typeof action.payload === "string")
          state.hvAvDelete.error = action.payload;
      });
    
    builder.addCase(postHvAvData.pending, (state) => {
      state.hvAvPost.loading = true;
      state.hvAvPost.error = null;
    })
    .addCase(postHvAvData.fulfilled, (state, action) => {
      state.hvAvPost.loading = false;
      state.hvAvPost.data = action.payload;
    })
    .addCase(postHvAvData.rejected, (state, action) => {
      state.hvAvPost.loading = false;
      if (typeof action.payload === "string")
        state.hvAvPost.error = action.payload;
    })
  },
});

export const {clearData} = wsDataSlice.actions;
export const wsDataReducer = wsDataSlice.reducer;
