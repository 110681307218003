// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.styles_pagination__dUzew {
  margin: 25px 0 0 auto;
  align-self: start;
}

.styles_downloadButton__3edO1:hover {
  filter: brightness(1.2) contrast(1.5);
}`, "",{"version":3,"sources":["webpack://./src/components/tables/PlanUploadsTable/styles.module.sass"],"names":[],"mappings":"AAAA;EACE,qBAAA;EACA,iBAAA;AACF;;AAEE;EACE,qCAAA;AACJ","sourcesContent":[".pagination\n  margin: 25px 0 0 auto\n  align-self: start\n\n.downloadButton\n  &:hover\n    filter: brightness(1.2) contrast(1.5)\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"pagination": `styles_pagination__dUzew`,
	"downloadButton": `styles_downloadButton__3edO1`
};
export default ___CSS_LOADER_EXPORT___;
