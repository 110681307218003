import React, { useEffect, useState } from "react";

import useNotify from "common/hooks/useNotify";
import { useUploadHandler } from "common/hooks/useUploadHandler";
import ListObjectsTable from "components/tables/ListObjectsTable";
import { H1, H3 } from "components/typography";
import { Button } from "components/UI/Button";
import { Loader } from "components/UI/Loader";
import { Modal } from "components/UI/Modal";
import UploadFiles from "components/UploadFiles";
import { clearClusterisationData } from "services/store/reducers/objects";
import {
  getObjects,
  startClusterasation,
} from "services/store/reducers/objects/actions";
import { TObject } from "services/store/reducers/objects/types";
import { useAppDispatch, useAppSelector } from "services/store/store";

import styles from "./styles.module.sass";

const ListObjects: React.FC = (): JSX.Element => {
  const [data, setData] = useState<TObject[] | null>(null);
  const { clientId } = useAppSelector((state) => state.globalParams);
  const {
    getObjectsData,
    getObjectsLoading,
    startClusterisationLoading,
    startClusterisationData,
    startClusterisationError,
  } = useAppSelector((state) => state.objects);
  const dispatch = useAppDispatch();

  const [isOpenFilesModal, setIsOpenFilesModal] = useState(false);
  const [isExelModal, setIsExelModal] = useState(false);
  const { ToastContainer, notifyUploading, notifyClusterisationStart } =
    useNotify();
  const { fileName, handleUpload } = useUploadHandler();

  const handleCloseFilesModal = () => {
    setIsOpenFilesModal(false);
  };

  const handleCRMUpdate = () => {
    notifyUploading("Загрузка из CRM успешна", "success");
    setIsOpenFilesModal(false);
  };

  const handleOpenExelModal = () => {
    setIsOpenFilesModal(false);
    setIsExelModal(true);
  };

  const handleSaveFile = () => {
    notifyUploading(`${fileName}`, "success");
    setIsExelModal(false);
  };

  const handleClusterisation = () => {
    dispatch(
      startClusterasation({
        client_id: clientId,
        cluster_count: 3,
        status: "kmeans",
      }),
    );
  };

  useEffect(() => {
    if (startClusterisationData) {
      notifyClusterisationStart("success");
    } else if (startClusterisationError) {
      notifyClusterisationStart("error");
    }
  });

  useEffect(() => {
    dispatch(getObjects(clientId));

    return () => {
      dispatch(clearClusterisationData());
    };
  }, [dispatch, clientId]);

  useEffect(() => {
    if (getObjectsData && !getObjectsLoading) {
      setData(getObjectsData);
    }
  }, [getObjectsData, getObjectsLoading]);

  return (
    <main>
      <header className={styles.header}>
        <H1>Список объектов</H1>
      </header>
      <section className={styles.section}>
        <div className={styles.controls}>
          <Button
            variant="primary"
            onClick={handleClusterisation}
            disabled={startClusterisationLoading}
            loading={startClusterisationLoading}
          >
            Запуск кластеризации
          </Button>
          <Button variant="primary" onClick={() => setIsOpenFilesModal(true)}>
            Загрузить
          </Button>
        </div>
        {getObjectsLoading ? (
          <div
            className="glow w-full flex items-center justify-center"
            style={{ height: "calc(100vh - 150px)" }}
          >
            <Loader />
          </div>
        ) : data && data.length > 0 ? (
          <ListObjectsTable data={data} />
        ) : (
          <span>{`Нет объектов для клиента ${clientId}`}</span>
        )}
      </section>
      <Modal
        classNames={styles.roundedModal}
        open={isOpenFilesModal}
        onClose={handleCloseFilesModal}
        closeButtonPosition={{ right: 25, top: 20 }}
      >
        <section className={styles.uploadFiles}>
          <H3>Загрузка файлов</H3>
          <Button
            className="mt-4 mb-[10px]"
            block
            variant="primary"
            onClick={handleCRMUpdate}
          >
            Обновить из CRM
          </Button>
          <Button block variant="primary" onClick={handleOpenExelModal}>
            Загрузка эксель
          </Button>
        </section>
      </Modal>
      <Modal
        classNames={styles.exelModal}
        open={isExelModal}
        onClose={() => setIsExelModal(false)}
        closeButtonPosition={{ right: 25, top: 20 }}
      >
        <>
          <H3>Загрузить эксель</H3>
          <div className={styles.exel_control}>
            <UploadFiles fileName={fileName} handleUpload={handleUpload} />
          </div>
          <Button
            variant="primary"
            className={styles.saveBtn}
            onClick={handleSaveFile}
          >
            Сохранить
          </Button>
        </>
      </Modal>
      <ToastContainer />
    </main>
  );
};

export default ListObjects;
