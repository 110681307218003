import { Column } from "./types";

export const columns: Column[] = [
  {
    id: "error_profiles",
    label: 'Профиля с ошибкой',
  },
  {
    id: "select_profiles",
    label: 'Доступные профиля',
  },
]