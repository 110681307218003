import { useEffect, useState } from "react";
import { Controller, FormProvider, useForm } from "react-hook-form";
import { TailSpin } from "react-loader-spinner";
import { useNavigate } from "react-router";

import classNames from "classnames";
import { userLogin } from "services/store/reducers/authentication/authActions";
import { useAppDispatch, useAppSelector } from "services/store/store";

import { zodResolver } from "@hookform/resolvers/zod";

import { schema } from "./schema";
import styles from "./styles.module.sass";

const LoginPage: React.FC = (): JSX.Element => {
  const [isPassVisible, setIsPassVisible] = useState(false);
  const [isErrorBlockVisible, setIsErrorBlockVisible] = useState(false);
  const [focusedInputField, setFocusedInputField] = useState<
    "login" | "password"
  >();
  const { loading, data } = useAppSelector((state) => state.auth);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const form = useForm({
    mode: "onChange",
    resolver: zodResolver(schema),
    defaultValues: {
      login: "",
      password: "",
    },
  });

  const {
    control,
    handleSubmit,
    getValues,
    formState: { isValid, errors },
    watch,
  } = form;

  useEffect(() => {
    if (data && data.access_token) {
      navigate("/");
    }
  }, [data, navigate]);

  const submitForm = () => {
    if (isValid) {
      const data = getValues();
      dispatch(userLogin(`username=${data.login}&password=${data.password}`));
    }
  };

  const closeErrorBlock = () => setIsErrorBlockVisible(false);

  return (
    <div className="w-screen h-screen bg-black flex items-center">
      <div className="flex flex-col items-center mx-auto w-max">
        <img
          src="/HR-Rocket-logo.svg"
          alt="logo"
          width={310}
          height={115}
          className="mb-20"
        />
        <div
          className={classNames(
            "border-solid border border-red-500 w-full p-3 mb-6 rounded bg-[#1a0d14]",
            {
              hidden: !isErrorBlockVisible,
              "flex justify-between items-center": isErrorBlockVisible,
            },
          )}
        >
          <div className="flex gap-4 items-center">
            <img src="/warning.svg" alt="warning" width={20} height={20} />
            <p className="text-red-500 m-0 p-0 text-md">
              Неверный логин или пароль!
            </p>
          </div>
          <img
            src="/error-cross.svg"
            alt="cross"
            width={15}
            height={15}
            className="cursor-pointer"
            onClick={closeErrorBlock}
          />
        </div>
        <FormProvider {...form}>
          <form
            className="flex flex-col items-center"
            onSubmit={handleSubmit(submitForm)}
          >
            <div
              className={classNames(
                "relative px-5 py-2.5 rounded-xl flex flex-col",
                {
                  "bg-white": !errors.login,
                  "bg-[#ffebee] border-solid border border-red-500":
                    errors.login,
                },
              )}
              onFocus={() => setFocusedInputField("login")}
              onBlur={() => setFocusedInputField(undefined)}
            >
              <Controller
                control={control}
                name="login"
                rules={{
                  required: true,
                }}
                render={({
                  field: { onChange, value },
                  fieldState: { invalid },
                }) => (
                  <input
                    value={value}
                    type="text"
                    id="login"
                    size={45}
                    className={classNames(
                      "border-none focus:outline-none pt-3 pb-1.5 text-md",
                      {
                        "bg-[#ffebee]": invalid,
                      },
                    )}
                    onChange={onChange}
                  />
                )}
              />
              <label
                htmlFor="login"
                className={classNames(
                  styles.label,
                  errors.login ? "text-red-500" : "text-[#777B89]",
                  {
                    "!text-xs !top-[30%]":
                      focusedInputField === "login" || watch("login").length,
                  },
                )}
              >
                Логин
              </label>
            </div>
            <div className="h-6 w-full">
              {errors.login ? (
                <p className="text-red-700 m-0 p-0 pl-4 pt-[2px] text-[10px]">
                  Пожалуйста, введите логин!
                </p>
              ) : null}
            </div>

            <div
              className={classNames(
                "px-5 py-2.5 rounded-xl flex flex-col relative",
                {
                  "bg-white": !errors.password,
                  "bg-[#ffebee] border-solid border border-red-500":
                    errors.password,
                },
              )}
              onFocus={() => setFocusedInputField("password")}
              onBlur={() => setFocusedInputField(undefined)}
            >
              <Controller
                control={control}
                name="password"
                rules={{
                  required: true,
                }}
                render={({
                  field: { onChange, value },
                  fieldState: { invalid },
                }) => (
                  <input
                    value={value}
                    type={isPassVisible ? "text" : "password"}
                    id="pass"
                    size={45}
                    className={classNames(
                      "border-none focus:outline-none pt-3 pb-1.5 text-md",
                      {
                        "bg-[#ffebee]": invalid,
                      },
                    )}
                    onChange={onChange}
                  />
                )}
              />
              <img
                src={errors.password ? "/pass-eye-red.svg" : "/pass-eye.svg"}
                alt="Изменить видимость пароля"
                width={24}
                height={24}
                className="absolute top-1/2 right-4 -translate-y-1/2 cursor-pointer"
                onClick={() => setIsPassVisible((prevState) => !prevState)}
              />
              <label
                htmlFor="pass"
                className={classNames(
                  styles.label,
                  "text-xs",
                  errors.password ? "text-red-500" : "text-[#777B89]",
                  {
                    "!text-xs !top-[30%]":
                      focusedInputField === "password" ||
                      watch("password").length,
                  },
                )}
              >
                Пароль
              </label>
            </div>
            <div className="h-3 w-full">
              {errors.password ? (
                <p className="text-red-700 m-0 p-0 pl-4 pt-[2px] text-[10px]">
                  Пожалуйста, введите пароль!
                </p>
              ) : null}
            </div>
            {/* <p className="text-md text-[#777B89] underline decoration-solid decoration-[#777B89] self-end cursor-pointer">
              Сбросить пароль
            </p> */}

            <button
              type="submit"
              className="mt-6 px-6 py-2.5 bg-[#217ec6] flex align-middle justify-center border-none text-white text-lg rounded-xl w-full cursor-pointer"
              disabled={loading}
            >
              {loading ? <TailSpin width={25} height={25} /> : "Войти"}
            </button>
          </form>
        </FormProvider>
      </div>
    </div>
  );
};

export default LoginPage;
