import { Fragment, useEffect, useRef, useState } from "react";
import { CSSTransition } from "react-transition-group";

import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";

import classNames from "classnames";
import RowCheckbox from "components/UI/RowCheckbox";
import { Record } from "services/store/reducers/media/types";
import { useAppSelector } from "services/store/store";

import commonStyles from "../common-styles.module.sass";
import { INITIAL_EMPTY_ROW } from "../MediaPlanMultiTable/constants";
import {
  getLeadBudgetRange,
  getLeadCountRange,
} from "../MediaPlanMultiTable/helpers";
import SettingsRow from "../SettingsRow";
import { columns } from "./conts";
import styles from "./styles.module.sass";

interface IMediaPlanPublishTable {
  data: Record[];
  uploadId: number;
}

const MediaPlanPublishTable: React.FC<IMediaPlanPublishTable> = ({
  data,
  uploadId,
}): JSX.Element => {
  const [rows, setRows] = useState<(Record & { isSelected: boolean })[]>([]);
  const [activeRow, setActiveRow] = useState<number | null>(null);
  const shouldRefreshData = useRef(false);
  const { mediaPlanSources } = useAppSelector((state) => state.mediaplan);

  useEffect(() => {
    if (data && data.length > 0) {
      setRows(
        data.map((row) => {
          const transformedRow: Record = INITIAL_EMPTY_ROW;
          Object.keys(row).forEach((key) => {
            transformedRow[key] = row[key];
          });
          return { ...transformedRow, isSelected: true };
        }),
      );
    }
  }, [data]);

  return (
    <Paper className={styles.paper}>
      <TableContainer className={commonStyles.table_container}>
        <Table stickyHeader className="whitespace-nowrap">
          <TableHead className="sticky top-0">
            <TableRow>
              {columns.map((column) => (
                <TableCell
                  colSpan={column.id === "region" ? 2 : 1}
                  key={column.id}
                  className={styles.header__cell}
                >
                  {column.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {rows
              .filter((row) => row.isSelected)
              .map((row, id) => (
                <Fragment key={id}>
                  <TableRow key={id} tabIndex={-1} role="checkbox">
                    <TableCell
                      className={classNames(
                        styles.body__cell,
                        activeRow === id ? styles.body__cell_active : null,
                      )}
                    >
                      <RowCheckbox
                        activeSettings={activeRow}
                        rowIndex={id}
                        setActiveSettings={setActiveRow}
                      />
                    </TableCell>
                    {columns.map((column) => {
                      let value: any;
                      switch (column.id) {
                        case "region":
                          value = row.data[0].region;
                          break;
                        case "need":
                          value =
                            Math.round(Math.random() * (40 - 20 + 1)) + 20;
                          break;
                        case "callbacks_count":
                          value = `${getLeadCountRange(row.callbacks_count, 0.8, 1.2)}`;
                          break;
                        case "lead_cost":
                          value = `${getLeadBudgetRange(row.budgets / row.callbacks_count, 0.8, 1.2)}`;
                          break;
                        case "predict":
                          value = Math.round(row.callbacks_count * 0.15);
                          break;
                        default:
                          value = row[column.id];
                      }
                      return (
                        <TableCell
                          key={column.id}
                          className={classNames(
                            styles.body__cell,
                            activeRow === id ? styles.body__cell_active : null,
                          )}
                        >
                          {value}
                        </TableCell>
                      );
                    })}
                  </TableRow>
                  <CSSTransition
                    in={activeRow === id}
                    timeout={300}
                    unmountOnExit
                    classNames={{
                      enter: styles.rowExpandEnter,
                      enterActive: styles.rowExpandEnterActive,
                      exit: styles.rowExpandExit,
                      exitActive: styles.rowExpandExitActive,
                    }}
                  >
                    <SettingsRow
                      shouldRefresh={shouldRefreshData}
                      data={row}
                      setActiveSettings={setActiveRow}
                      columnsNumber={12}
                      uploadId={uploadId}
                      editable={false}
                      sources={mediaPlanSources!}
                    />
                  </CSSTransition>
                </Fragment>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Paper>
  );
};

export default MediaPlanPublishTable;
