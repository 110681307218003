// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.styles_clickable__cc3MG {
  cursor: pointer;
}
.styles_clickable__cc3MG:hover {
  background: rgba(0, 0, 0, 0.04);
}

.styles_centered__-wzEd {
  text-align: center !important;
}`, "",{"version":3,"sources":["webpack://./src/components/tables/MediaPlanTable/styles.module.sass"],"names":[],"mappings":"AAAA;EACE,eAAA;AACF;AAAE;EACE,+BAAA;AAEJ;;AAAA;EACE,6BAAA;AAGF","sourcesContent":[".clickable\n  cursor: pointer\n  &:hover\n    background: rgba(0, 0, 0, 0.04)\n\n.centered\n  text-align: center !important\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"clickable": `styles_clickable__cc3MG`,
	"centered": `styles_centered__-wzEd`
};
export default ___CSS_LOADER_EXPORT___;
