// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.styles_logo__qLGBV {
  margin: 35px 0 37px 9px;
  cursor: pointer;
}

.styles_closed_logo_sm__dllKJ {
  margin-left: 0;
}

.styles_full_logo__rTbfS {
  margin-top: 10px;
}

.styles_logo_sm__q87xx {
  margin-top: 6px;
}`, "",{"version":3,"sources":["webpack://./src/components/UI/Logo/styles.module.sass"],"names":[],"mappings":"AAAA;EACE,uBAAA;EACA,eAAA;AACF;;AACA;EACE,cAAA;AAEF;;AAAA;EACE,gBAAA;AAGF;;AADA;EACE,eAAA;AAIF","sourcesContent":[".logo\n  margin: 35px 0 37px 9px\n  cursor: pointer\n\n.closed_logo_sm\n  margin-left: 0\n\n.full_logo\n  margin-top: 10px\n\n.logo_sm\n  margin-top: 6px\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"logo": `styles_logo__qLGBV`,
	"closed_logo_sm": `styles_closed_logo_sm__dllKJ`,
	"full_logo": `styles_full_logo__rTbfS`,
	"logo_sm": `styles_logo_sm__q87xx`
};
export default ___CSS_LOADER_EXPORT___;
