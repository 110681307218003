// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.styles_label__4cmuq {
  position: absolute;
  pointer-events: none;
  top: 50%;
  transform: translateY(-50%);
  transition: 0.2s ease;
  font-size: 16px;
}`, "",{"version":3,"sources":["webpack://./src/pages/login/styles.module.sass"],"names":[],"mappings":"AAAA;EACE,kBAAA;EACA,oBAAA;EACA,QAAA;EACA,2BAAA;EACA,qBAAA;EACA,eAAA;AACF","sourcesContent":[".label\n  position: absolute\n  pointer-events: none\n  top: 50%\n  transform: translateY(-50%)\n  transition: 0.2s ease\n  font-size: 16px"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"label": `styles_label__4cmuq`
};
export default ___CSS_LOADER_EXPORT___;
