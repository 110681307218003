// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.styles_tableData__G4Iy5 {
  margin: 15px;
  display: flex;
  align-items: flex-end;
  flex-direction: column;
  gap: 11px;
  overflow: auto;
}

.styles_note__xkpzr {
  color: #0C3953;
  font-size: 0.875rem;
  font-weight: 500;
  line-height: 20px;
  padding-right: 25px;
}

.styles_error__tjLH\\+ {
  color: #c10000;
  font-weight: 700;
}

.styles_centered__MyrAC {
  align-self: center;
}

.styles_hoverable__lWSU- {
  cursor: pointer;
}`, "",{"version":3,"sources":["webpack://./src/pages/settings/styles.module.sass","webpack://./src/common/styles/variables/font-style.sass"],"names":[],"mappings":"AAEA;EACE,YAAA;EACA,aAAA;EACA,qBAAA;EACA,sBAAA;EACA,SAAA;EACA,cAAA;AADF;;AAGA;EACE,cAAA;EACA,mBAAA;EACA,gBAAA;EACA,iBAAA;EACA,mBAAA;AAAF;;AAEA;EACE,cClBM;EDmBN,gBAAA;AACF;;AACA;EACE,kBAAA;AAEF;;AAAA;EACE,eAAA;AAGF","sourcesContent":["@import \"../../common/styles/variables/font-style\"\n\n.tableData\n  margin: 15px\n  display: flex\n  align-items: flex-end\n  flex-direction: column\n  gap: 11px\n  overflow: auto\n\n.note\n  color: #0C3953\n  font-size: 0.875rem\n  font-weight: 500\n  line-height: 20px\n  padding-right: 25px\n\n.error\n  color: $error\n  font-weight: 700\n\n.centered\n  align-self: center\n\n.hoverable\n  cursor: pointer\n","$error: #c10000\n$font-collection: Montserrat, Arial, \"Helvetica Neue\", Helvetica, sans-serif\n$placeholder: rgba(0, 0, 0, 0.6)\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"tableData": `styles_tableData__G4Iy5`,
	"note": `styles_note__xkpzr`,
	"error": `styles_error__tjLH+`,
	"centered": `styles_centered__MyrAC`,
	"hoverable": `styles_hoverable__lWSU-`
};
export default ___CSS_LOADER_EXPORT___;
