import { createAsyncThunk } from "@reduxjs/toolkit";
import { MessageResponse, SendSettingsParams, SettingSource } from "./types";
import axios, { AxiosResponse } from "axios";
import { groupByListId } from "common/utils/groupByListId";
import { handleParams } from "common/utils/handleParams";
import axiosInstance from "services/store/axiosInstance";

export const postSettingsData = createAsyncThunk(
  "settings/sendSettings",
  async ({ settings }: SendSettingsParams, { rejectWithValue }) => {
    const urlParams = handleParams(settings);
    try {
      const response = await axiosInstance.post(
        `/sources/upload-source${urlParams}`,
    );
      return response.data;
    } catch (error) {
      if (axios.isAxiosError(error) && error.response) {
        return rejectWithValue(error.response.data);
      } else {
        return rejectWithValue(error.message);
      }
    }
  },
);

export const updateSettingsData = createAsyncThunk(
  "settings/sendUpdatedSettings",
  async ({ settings }: SendSettingsParams, { rejectWithValue }) => {
    const urlParams = handleParams(settings);
    try {
      const response: AxiosResponse<MessageResponse> = await axiosInstance.patch(
        `/sources/update-source${urlParams}`,
      );
      return response.data;
    } catch (error) {
      if (axios.isAxiosError(error) && error.response) {
        return rejectWithValue(error.response.data);
      } else {
        return rejectWithValue(error.message);
      }
    }
  },
);

export const fetchClientSources = createAsyncThunk(
  "sources/fetchClientSources",
   async (client_id: number, { rejectWithValue }) => {
  try {
    const response: AxiosResponse<SettingSource[] | MessageResponse> =
      await axiosInstance.get(
        `/sources/get-sources/${client_id}`
      );
    if (Array.isArray(response.data)) {
      const grouped = groupByListId<SettingSource>(response.data, "id");
      return grouped;
    }
    return response.data;
  } catch (error) {
    if (axios.isAxiosError(error) && error.response) {
      return rejectWithValue(error.response.data);
    } else {
      return rejectWithValue(error.message);
    }
  }
});