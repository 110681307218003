// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.styles_select__HB\\+5d {
  min-width: 200px !important;
  max-width: fit-content !important;
}`, "",{"version":3,"sources":["webpack://./src/components/UI/Select/styles.module.sass"],"names":[],"mappings":"AAAA;EACE,2BAAA;EACA,iCAAA;AACF","sourcesContent":[".select\n  min-width: 200px !important\n  max-width: fit-content !important\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"select": `styles_select__HB+5d`
};
export default ___CSS_LOADER_EXPORT___;
