// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.styles_errors__QJ6K3 {
  font-family: Montserrat, Arial, "Helvetica Neue", Helvetica, sans-serif;
  color: #c10000;
  list-style-type: none;
  font-size: 14px;
}`, "",{"version":3,"sources":["webpack://./src/components/Filters/ErrorMessages/styles.module.sass","webpack://./src/common/styles/variables/font-style.sass"],"names":[],"mappings":"AAEA;EACE,uECFgB;EDGhB,cCJM;EDKN,qBAAA;EACA,eAAA;AADF","sourcesContent":["@import \"../../../common/styles/variables/font-style\"\n\n.errors\n  font-family: $font-collection\n  color: $error\n  list-style-type: none\n  font-size: 14px\n","$error: #c10000\n$font-collection: Montserrat, Arial, \"Helvetica Neue\", Helvetica, sans-serif\n$placeholder: rgba(0, 0, 0, 0.6)\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"errors": `styles_errors__QJ6K3`
};
export default ___CSS_LOADER_EXPORT___;
