// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.styles_select__E44G1 {
  appearance: none;
  -webkit-appearance: none;
  border: none;
  scrollbar-width: thin;
  scrollbar-color: #ddd transparent;
}
.styles_select__E44G1::-webkit-scrollbar-thumb {
  border-radius: 3px;
}
.styles_select__E44G1:focus {
  outline: none;
}

.styles_option__qSo2x {
  font-family: Montserrat, Arial, "Helvetica Neue", Helvetica, sans-serif;
  font-size: 0.875rem;
  color: #0C3953;
  line-height: 20px;
  font-weight: 500;
  padding: 8px 0;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
  padding-right: 92px;
}
.styles_option__qSo2x:hover {
  background-color: #F4F8FF;
}
.styles_option__qSo2x:focus {
  background-color: #F4F8FF;
}`, "",{"version":3,"sources":["webpack://./src/components/UI/SizebleSelect/styles.module.sass","webpack://./src/common/styles/variables/font-style.sass"],"names":[],"mappings":"AAEA;EACE,gBAAA;EACA,wBAAA;EACA,YAAA;EACA,qBAAA;EACA,iCAAA;AADF;AAEE;EACE,kBAAA;AAAJ;AAEE;EACE,aAAA;AAAJ;;AAEA;EACE,uECdgB;EDehB,mBAAA;EACA,cAAA;EACA,iBAAA;EACA,gBAAA;EACA,cAAA;EACA,eAAA;EACA,gCAAA;EACA,mBAAA;AACF;AAAE;EACE,yBAAA;AAEJ;AADE;EACE,yBAAA;AAGJ","sourcesContent":["@import \"common/styles/variables/font-style\"\n\n.select\n  appearance: none\n  -webkit-appearance: none\n  border: none\n  scrollbar-width: thin\n  scrollbar-color: #ddd transparent\n  &::-webkit-scrollbar-thumb\n    border-radius: 3px\n  &::-webkit-scrollbar-button\n  &:focus\n    outline: none\n\n.option\n  font-family: $font-collection\n  font-size: 0.875rem\n  color: #0C3953\n  line-height: 20px\n  font-weight: 500\n  padding: 8px 0\n  cursor: pointer\n  transition: all 0.3s ease-in-out\n  padding-right: 92px\n  &:hover\n    background-color: #F4F8FF\n  &:focus\n    background-color: #F4F8FF\n","$error: #c10000\n$font-collection: Montserrat, Arial, \"Helvetica Neue\", Helvetica, sans-serif\n$placeholder: rgba(0, 0, 0, 0.6)\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"select": `styles_select__E44G1`,
	"option": `styles_option__qSo2x`
};
export default ___CSS_LOADER_EXPORT___;
