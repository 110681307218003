import axios from "axios";

import { API_URL } from "./endpoint";

// const TOKEN = process.env.REACT_APP_TOKEN || "Token";
//TODO добавить наш url

const API_URLS: Record<API, string> = {
  API: process.env.REACT_APP_API_URL || API_URL,
};

type Method = "get" | "put" | "post" | "delete";
type API = "API";

export const apiRequest = <Response, Params = {}>(
  type: API,
  method: Method,
  endpoint: string,
  params?: Params,
) => {
  return axios[method]<Response>(`${API_URLS[type]}${endpoint}`, params, {});
};
