import { createAsyncThunk } from "@reduxjs/toolkit";
import { DeleteHvAvDataParams, DeleteHvAvDataResponse, FetchHvAvDataParams, HvAvData, PostHvAvDataParams, PostHvAvDataResponse } from "./types";
import axios, { AxiosResponse } from "axios";
import { handleParams } from "common/utils/handleParams";
import axiosInstance from "services/store/axiosInstance";

export const fetchHvAvData = createAsyncThunk(
  "wsData/fetchHvAvData",
  async (params: FetchHvAvDataParams, { rejectWithValue }) => {
    try {
      const URLparams = handleParams(params);
      const response: AxiosResponse<HvAvData[]> = await axiosInstance.get(
        `/hr-data/get-hr${URLparams}&limit=500`
      );
      const data: HvAvData[] = response.data;
      return data;
    } catch (error) {
      return axios.isAxiosError(error) && error.response
        ? rejectWithValue(error.response.data)
        : rejectWithValue(error.message);
    }
  },
);

export const deleteHvAvData = createAsyncThunk<any, DeleteHvAvDataParams>(
  "wsData/deleteHvAvData",
  async ({ source_type, source_id }, { rejectWithValue }) => {
    try {
      const response: AxiosResponse<DeleteHvAvDataResponse> = await axiosInstance.delete(
        `/hr-data/delete-hr/${source_type}/${source_id}`,
      );
      return response.data;
    } catch (error) {
      return axios.isAxiosError(error) && error.response
        ? rejectWithValue(error.response.data)
        : rejectWithValue(error.message);
    }
  },
);

export const postHvAvData = createAsyncThunk(
  "wsData/postHvAvData",
  async ({params, file, source_code}: {params: PostHvAvDataParams, file: File, source_code: string}, {rejectWithValue}) => {
    try {
      const formData: FormData = new FormData();
      formData.append("file", file);
      const urlParams = handleParams(params);
      const response: AxiosResponse<PostHvAvDataResponse> = await axiosInstance.post(
        `/hr-data/upload-${source_code}${urlParams}`,
        formData,
      );
      return response.data;
    } catch (error) {
      return axios.isAxiosError(error) && error.response
       ? rejectWithValue(error.response.data)
        : rejectWithValue(error.message);
    }
  }
)