import { Route } from "../../common/types";

export const getLocationIndex = (location: string, routes: (Route & { subroutes: Route[] })[]): number => {
  if (routes.length === 0) return -1;
  let index: number = - 1;
  for (let i = 0; i < routes.length; i++) {
    if (routes[i].subroutes.length > 0) {
      index = routes[i].subroutes.findIndex((route) => route.value === location);
      if (index !== -1) break
    }
  }
  return index;
};
