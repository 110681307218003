// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.styles_button__cPvBh {
  width: 48px;
  height: 48px;
  margin-top: 5px;
}
.styles_button__cPvBh.styles_activated__qLd\\+g {
  filter: brightness(1) hue-rotate(25deg) contrast(2);
}
.styles_button__cPvBh:hover {
  filter: brightness(1.25) hue-rotate(15deg);
}

.styles_filterPanel__tyuJ5 {
  width: 650px;
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.styles_header__F0nTR {
  display: flex;
  gap: 16px;
}

.styles_heading__jDCHk {
  font-family: Montserrat, Arial, "Helvetica Neue", Helvetica, sans-serif;
}`, "",{"version":3,"sources":["webpack://./src/components/Filters/styles.module.sass","webpack://./src/common/styles/variables/font-style.sass"],"names":[],"mappings":"AAEA;EACE,WAAA;EACA,YAAA;EACA,eAAA;AADF;AAGE;EACE,mDAAA;AADJ;AAGE;EACE,0CAAA;AADJ;;AAGA;EACE,YAAA;EACA,aAAA;EACA,sBAAA;EACA,SAAA;AAAF;;AAEA;EACE,aAAA;EACA,SAAA;AACF;;AACA;EACE,uECvBgB;ADyBlB","sourcesContent":["@import \"../../common/styles/variables/font-style\"\n\n.button\n  width: 48px\n  height: 48px\n  margin-top: 5px\n\n  &.activated\n    filter: brightness(1) hue-rotate(25deg) contrast(2)\n\n  &:hover\n    filter: brightness(1.25) hue-rotate(15deg)\n\n.filterPanel\n  width: 650px\n  display: flex\n  flex-direction: column\n  gap: 20px\n\n.header\n  display: flex\n  gap: 16px\n\n.heading\n  font-family: $font-collection\n","$error: #c10000\n$font-collection: Montserrat, Arial, \"Helvetica Neue\", Helvetica, sans-serif\n$placeholder: rgba(0, 0, 0, 0.6)\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"button": `styles_button__cPvBh`,
	"activated": `styles_activated__qLd+g`,
	"filterPanel": `styles_filterPanel__tyuJ5`,
	"header": `styles_header__F0nTR`,
	"heading": `styles_heading__jDCHk`
};
export default ___CSS_LOADER_EXPORT___;
