import { Route, Routes } from "react-router";

import {
  Analytics,
  Dixi,
  IssuedPage,
  ListObjects,
  LoginPage,
  MainPage,
  MediaPage,
  MediaPlanChoose,
  MediaPlanChoosePage,
  MediaPlanCluster,
  MediaPlanMultiPage,
  MediaPlanPage,
  NotFoundPage,
  PublishPlan,
  PublishReestr,
  PublishSelection,
  SelectionPage,
  SettingsPage,
  WSDataPage,
} from "pages";

import {
  ANALYTICS_ROUTE,
  CHOOSE_MEDIA_PLAN_ROUTE,
  CLUSTER_MEDIA_PLAN_ROUTE,
  ISSUED_ROUTE,
  MEDIA_PLAN_MULTI_ROUTE,
  MEDIA_PLAN_ROUTE,
  MEDIA_PLANS_ROUTE,
  OBJECT_LIST_ROUTE,
  PUPLISH_PLAN_ROUTE,
  REESTR_PLAN_PUBLISH_ROUTE,
  SELECTION_PLAN_ROUTE,
  SETTINGS_ROUTE,
  UPLOAD_PLAN_ROUTE,
  WORK_SITE_DATA_ROUTE,
} from "../common/const";
import MainLayout from "./main-layout";
import TabsLayout from "./tabs-layout";

const AppRouter: React.FC = (): JSX.Element => {
  return (
    <Routes>
      <Route path="/login" element={<LoginPage />} />
      <Route path="/dixi" element={<Dixi />} />
      <Route path="/" element={<MainLayout />}>
        <Route index element={<MainPage />} />
        <Route path={MEDIA_PLAN_ROUTE} element={<MediaPlanPage />} />
        <Route path={ANALYTICS_ROUTE} element={<Analytics />} />
        <Route element={<TabsLayout flow="mediaplans" />}>
          <Route path={MEDIA_PLANS_ROUTE} element={<MediaPage />} />
          <Route path={SETTINGS_ROUTE} element={<SettingsPage />} />
          <Route path={ISSUED_ROUTE} element={<IssuedPage />} />
          <Route path={WORK_SITE_DATA_ROUTE} element={<WSDataPage />} />
          <Route path={SELECTION_PLAN_ROUTE} element={<SelectionPage />} />
          <Route
            path={MEDIA_PLAN_MULTI_ROUTE}
            element={<MediaPlanMultiPage />}
          />
        </Route>
        <Route element={<TabsLayout flow="publish-plan" />}>
          <Route path={OBJECT_LIST_ROUTE} element={<ListObjects />} />
          <Route path={UPLOAD_PLAN_ROUTE} element={<PublishSelection />} />
          <Route path={CHOOSE_MEDIA_PLAN_ROUTE} element={<MediaPlanChoose />} />
          <Route
            path={`${CHOOSE_MEDIA_PLAN_ROUTE}/:mpId`}
            element={<MediaPlanChoosePage />}
          />
          <Route path={REESTR_PLAN_PUBLISH_ROUTE} element={<PublishReestr />} />
          <Route
            path={CLUSTER_MEDIA_PLAN_ROUTE}
            element={<MediaPlanCluster />}
          />
          <Route path={PUPLISH_PLAN_ROUTE} element={<PublishPlan />} />
        </Route>
        <Route path="*" element={<NotFoundPage />} />
      </Route>
    </Routes>
  );
};

export default AppRouter;
