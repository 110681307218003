import { useEffect, useState } from "react";

import { EMPTY_DATA } from "common/const";
import MediaPlanClusterTable from "components/tables/mediaPlanClusterTable";
import { H1 } from "components/typography";
import { Loader } from "components/UI/Loader";
import { getMediaPlansByClusters } from "services/store/reducers/media/actions";
import { GetMPByClusters } from "services/store/reducers/media/types";
import { useAppDispatch, useAppSelector } from "services/store/store";

const MediaPlanCluster: React.FC = (): JSX.Element => {
  const [data, setData] = useState<GetMPByClusters[] | []>([]);
  const dispatch = useAppDispatch();
  const { mediaPlanByClustersData, mediaPlanByClusterLoading } = useAppSelector(
    (state) => state.mediaplan,
  );
  useEffect(() => {
    dispatch(
      getMediaPlansByClusters({
        upload_id: 146,
        hire_plan_id: 4,
        city_independent: false,
        debug: false,
      }),
    );
  }, [dispatch]);
  useEffect(() => {
    if (!mediaPlanByClusterLoading && mediaPlanByClustersData) {
      setData(mediaPlanByClustersData);
    }
  }, [mediaPlanByClusterLoading, mediaPlanByClustersData]);

  return (
    <>
      <header className="mb-4">
        <H1>Медиаплан по кластерам</H1>
      </header>
      <section>
        {mediaPlanByClusterLoading ? (
          <Loader />
        ) : data && data.length > 0 ? (
          <MediaPlanClusterTable data={data} />
        ) : (
          <span>{EMPTY_DATA}</span>
        )}
      </section>
    </>
  );
};

export default MediaPlanCluster;
