import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router";

import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import NavigationTabs from "@mui/material/Tabs";

import { SIDEBAR_ROUTES } from "common/const";
import { Route } from "common/types";
import a11yProps from "common/utils/a11yProps";
import { getLocationIndex } from "common/utils/getLocationIndex";

interface ITabs {
  tabRoutes: Route[];
}

const Tabs: React.FC<ITabs> = (props): JSX.Element => {
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const [selectedIndex, setSelectedIndex] = useState<number>(
    getLocationIndex(pathname, SIDEBAR_ROUTES),
  );

  const handleChange = (_: React.SyntheticEvent, newValue: number) => {
    setSelectedIndex(newValue);
    navigate(props.tabRoutes[newValue].value);
  };

  useEffect(() => {
    let routeIndex = props.tabRoutes.findIndex(
      (route) => route.value === pathname,
    );

    if (pathname === "/mediaplan-multi") {
      routeIndex = 4;
    }

    if (routeIndex !== -1) {
      setSelectedIndex(routeIndex);
    }
  }, [pathname, props.tabRoutes]);

  return (
    <Box
      sx={{
        borderBottom: 1,
        borderColor: "divider",
      }}
    >
      <NavigationTabs
        value={selectedIndex}
        onChange={handleChange}
        variant="scrollable"
        scrollButtons={false}
        aria-label="scrollable auto tabs"
        className="!min-h-[34px] !max-h-[34px]"
      >
        {props.tabRoutes.map((value, index) => (
          <Tab
            label={value.name}
            key={index}
            {...a11yProps(index)}
            style={{
              textTransform: "none",
              padding: 0,
              fontSize: 10,
              marginLeft: index ? 10 : 0,
              marginRight: 10,
              color: selectedIndex !== index ? "#C6CED8" : "",
            }}
          />
        ))}
      </NavigationTabs>
    </Box>
  );
};

export default Tabs;
