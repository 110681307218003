import {
  ChangeEvent,
  memo,
  MutableRefObject,
  SetStateAction,
  useCallback,
  useEffect,
  useState,
} from "react";
import { Fragment } from "react/jsx-runtime";

import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";

import RateInput from "components/SettingsTable/components/RateInput";
import { Button } from "components/UI/Button";
import { Loader } from "components/UI/Loader";
import { clearCustomMediaPlanData } from "services/store/reducers/media";
import { postCustomMediaPlan } from "services/store/reducers/media/actions";
import { Record, Region } from "services/store/reducers/media/types";
import { useAppDispatch, useAppSelector } from "services/store/store";

import commonStyles from "../common-styles.module.sass";
import {
  commafy,
  getLeadBudgetRange,
  getLeadCountRange,
} from "../MediaPlanMultiTable/helpers";
import {
  ACTIVE_SETTINGS_TABLE_DATA,
  ACTIVE_SETTINGS_TABLE_HEADERS,
  CHANNELS,
} from "./constants";
import { getFactors, getMonthlyColumn, getWeeklyColumn } from "./helpers";
import { ChannelsFactors } from "./types";

interface ISettingsRow {
  data: Record;
  columnsNumber: number;
  setActiveSettings: (value: SetStateAction<number | null>) => void;
  uploadId: number;
  shouldRefresh: MutableRefObject<boolean>;
  editable: boolean;
  sources: string[];
}

const SettingsRow: React.FC<ISettingsRow> = ({
  data,
  setActiveSettings,
  uploadId,
  columnsNumber,
  shouldRefresh,
  editable,
  sources,
}): JSX.Element => {
  const { customMediaPlanData, customMediaPlanLoading, customMediaPlanError } =
    useAppSelector((state) => state.mediaplan);
  const [loading, setLoading] = useState(false);
  const [settingsData, setSettingsData] = useState<Record>();
  const [dirty, setDirty] = useState<string>("");
  const dispatch = useAppDispatch();
  const [currentFactors, setCurrentFactors] = useState<ChannelsFactors>({});

  const handleEditPlan = async () => {
    const newPostData = (() => {
      const currentEditedSource = data.data.find(
        (item) => item.source_code === dirty,
      );
      if (currentEditedSource) {
        return {
          upload_id: uploadId,
          ml_output_id: currentEditedSource.id,
          options: Number(currentFactors[dirty].monthly[0]),
          options_2:
            dirty === "av" ? 0 : Number(currentFactors[dirty].monthly[1]),
          options_3: Number(currentFactors[dirty].monthly[2]),
        };
      } else return null;
    })();

    if (newPostData) {
      const response = await dispatch(
        postCustomMediaPlan({
          ...newPostData,
        }),
      );

      const payload = response.payload as Region;
      if (response?.payload && !customMediaPlanLoading) {
        setSettingsData((prev) => {
          if (prev && prev.data) {
            const updatedData = prev.data.map((item) =>
              item.source_code === payload.source_code
                ? { ...item, ...payload }
                : item,
            );

            if (JSON.stringify(prev.data) === JSON.stringify(updatedData)) {
              return prev;
            }

            return {
              ...prev,
              data: updatedData,
            };
          }
          return prev;
        });
      }
      shouldRefresh.current = true;
    }
  };

  const handleChangeFactors = useCallback(
    (
      e: ChangeEvent<HTMLInputElement>,
      channel: string,
      inputNumber: number,
    ) => {
      setCurrentFactors((prev) => {
        const currentRow = prev[channel];
        setDirty(channel);
        currentRow.monthly[inputNumber] = e.target.value;
        return {
          ...prev,
          [channel]: currentRow,
        };
      });
    },
    [],
  );

  useEffect(() => {
    if (!customMediaPlanData && !customMediaPlanLoading) {
      setLoading(true);
      setSettingsData({ ...data });
      setCurrentFactors(getFactors(data, sources));
      setLoading(false);
    }
  }, [customMediaPlanData, data, customMediaPlanLoading, sources]);

  useEffect(() => {
    if (customMediaPlanData && settingsData) {
      setCurrentFactors(getFactors(settingsData, sources));
    } else if (customMediaPlanError) {
      shouldRefresh.current = false;
    }
  }, [
    customMediaPlanData,
    settingsData,
    customMediaPlanError,
    shouldRefresh,
    sources,
  ]);

  useEffect(() => {
    return () => dispatch(clearCustomMediaPlanData() as any);
  }, [dispatch]);

  return (
    <TableRow>
      <TableCell colSpan={columnsNumber} className="!pt-2 !pb-3 !px-0">
        <Paper className="!bg-[#314660] !rounded-lg !p-[20px] !flex flex-col !items-start gap-6">
          <div className="flex flex-col gap-3 w-full">
            <div className="flex justify-between">
              <div className="flex items-center gap-5">
                <p className="text-xs text-white">Редактирование плана</p>
                <Button
                  variant="upload"
                  onClick={handleEditPlan}
                  size="small"
                  loading={customMediaPlanLoading}
                  disabled={customMediaPlanLoading || loading || !editable}
                >
                  Пересчёт {dirty}
                </Button>
              </div>
              <img
                className="cursor-pointer size-4"
                src="/close.svg"
                alt="close icon"
                onClick={() => setActiveSettings(null)}
              />
            </div>
            {loading ? (
              <Loader />
            ) : (
              <Paper className={commonStyles.table}>
                <TableContainer sx={{ maxHeight: "calc(100vh - 300px)" }}>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell
                          padding="none"
                          className="!border !border-grey-6"
                        ></TableCell>
                        <TableCell
                          colSpan={6}
                          className="!text-xxs !bg-[#EAF1F9] !py-2 !px-4 !border !border-grey-6 leading-5"
                        >
                          Месяц
                        </TableCell>
                        <TableCell
                          colSpan={6}
                          className="!text-xxs !bg-[#D7E1ED] !py-2 !px-4 !border !border-grey-6"
                        >
                          Неделя
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {settingsData &&
                        sources.map((channel) => (
                          <Fragment key={channel}>
                            <TableRow>
                              <TableCell className="!py-[10px] !pl-4 !text-xxs leading-[20px] !font-medium !text-blue-500 !border !border-grey-6">
                                {CHANNELS[channel]}
                              </TableCell>
                              {["month", "week"].map(
                                (period) =>
                                  ACTIVE_SETTINGS_TABLE_HEADERS[channel] &&
                                  ACTIVE_SETTINGS_TABLE_HEADERS[channel].map(
                                    (header: string, index: number) => (
                                      <TableCell
                                        key={`${header}-${index}-${period}`}
                                        className="!text-xxs !font-medium !text-[#0C3953] !py-[5px] !border !border-grey-6"
                                      >
                                        {header}
                                      </TableCell>
                                    ),
                                  ),
                              )}
                            </TableRow>
                            {ACTIVE_SETTINGS_TABLE_DATA[channel] && (
                              <TableRow className="!bg-[#FBFBFB]">
                                {["month", "week"].map((period) => (
                                  <Fragment key={period}>
                                    {period === "month" && (
                                      <TableCell className="!py-[6px] !border !border-grey-6"></TableCell>
                                    )}
                                    {ACTIVE_SETTINGS_TABLE_DATA[channel].map(
                                      (item: string, inputNumber: number) =>
                                        item === "input" &&
                                        period === "month" ? (
                                          <TableCell
                                            key={inputNumber}
                                            className="!py-[5px] !text-xxs !border !border-grey-6"
                                          >
                                            {editable ? (
                                              <RateInput
                                                name={channel}
                                                value={
                                                  currentFactors[channel]
                                                    .monthly[inputNumber]
                                                }
                                                onChange={(e) =>
                                                  handleChangeFactors(
                                                    e,
                                                    channel,
                                                    inputNumber,
                                                  )
                                                }
                                              />
                                            ) : (
                                              currentFactors[channel].monthly[
                                                inputNumber
                                              ]
                                            )}
                                          </TableCell>
                                        ) : item === "input" &&
                                          period === "week" ? (
                                          <TableCell
                                            key={inputNumber}
                                            className="!py-[5px] !border !border-grey-6 !text-xxs"
                                          >
                                            {
                                              currentFactors[channel].weekly[
                                                inputNumber
                                              ]
                                            }
                                          </TableCell>
                                        ) : (
                                          <TableCell
                                            key={inputNumber}
                                            className="!py-[5px] !border !border-grey-6 !text-xxs"
                                          >
                                            {item}
                                          </TableCell>
                                        ),
                                    )}
                                    {period === "week" ? (
                                      <>
                                        <TableCell className="!py-[5px] !text-xxs !text-[#0C3953] !border !border-grey-6">
                                          {getLeadCountRange(
                                            Number(
                                              getWeeklyColumn(
                                                "callbacks_count",
                                                settingsData,
                                                channel,
                                              ).toFixed(0),
                                            ),
                                            0.8,
                                            1.2,
                                          )}
                                        </TableCell>
                                        <TableCell className="!py-[5px] !text-xxs !text-[#0C3953] !border !border-grey-6">
                                          {getLeadBudgetRange(
                                            getWeeklyColumn(
                                              "avg_leed_price",
                                              settingsData,
                                              channel,
                                            ),
                                            0.8,
                                            1.2,
                                          )}
                                        </TableCell>
                                        <TableCell className="!py-[5px] !text-xxs !text-[#0C3953] !border !border-grey-6">
                                          {commafy(
                                            getWeeklyColumn(
                                              "budget",
                                              settingsData,
                                              channel,
                                            ),
                                          )}
                                          ₽
                                        </TableCell>
                                      </>
                                    ) : period === "month" ? (
                                      <>
                                        <TableCell className="!py-[5px] !text-xxs !text-[#0C3953] !border !border-grey-6">
                                          {getLeadCountRange(
                                            getMonthlyColumn(
                                              "callbacks_count",
                                              settingsData,
                                              channel,
                                            ),
                                            0.8,
                                            1.2,
                                          )}
                                        </TableCell>
                                        <TableCell className="!py-[5px] !text-xxs !text-[#0C3953] !border !border-grey-6">
                                          {getLeadBudgetRange(
                                            getMonthlyColumn(
                                              "lead_cost",
                                              settingsData,
                                              channel,
                                            ),
                                            0.8,
                                            1.2,
                                          )}
                                        </TableCell>
                                        <TableCell className="!py-[5px] !text-xxs !text-[#0C3953] !border !border-grey-6">
                                          {commafy(
                                            getMonthlyColumn(
                                              "budgets",
                                              settingsData,
                                              channel,
                                            ),
                                          )}
                                          ₽
                                        </TableCell>
                                      </>
                                    ) : null}
                                  </Fragment>
                                ))}
                              </TableRow>
                            )}
                          </Fragment>
                        ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Paper>
            )}
          </div>
        </Paper>
      </TableCell>
    </TableRow>
  );
};

export default memo(SettingsRow);
