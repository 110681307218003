// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.styles_selectionPlan__n4FQA {
  margin-bottom: 5px;
}

.styles_confirmation__zUeQV {
  margin: 15px 0 6px;
  display: flex;
  justify-content: space-between;
}

.styles_wrapper__UWkhi {
  margin-top: 5px;
}

.styles_content__v8scd {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.styles_newPlan__\\+MkqR {
  display: flex;
  justify-content: space-between;
}

.styles_optionsBox__S0RBj {
  display: flex;
  align-items: flex-end;
  gap: 25px;
}

.styles_learnBox__XCZM9 {
  display: flex;
  align-items: center;
  justify-content: space-around;
}`, "",{"version":3,"sources":["webpack://./src/pages/media/styles.module.sass"],"names":[],"mappings":"AAAA;EACE,kBAAA;AACF;;AACA;EACE,kBAAA;EACA,aAAA;EACA,8BAAA;AAEF;;AAAA;EACE,eAAA;AAGF;;AADA;EACE,aAAA;EACA,sBAAA;EACA,SAAA;AAIF;;AAFA;EACE,aAAA;EACA,8BAAA;AAKF;;AAHA;EACE,aAAA;EACA,qBAAA;EACA,SAAA;AAMF;;AAJA;EACE,aAAA;EACA,mBAAA;EACA,6BAAA;AAOF","sourcesContent":[".selectionPlan\n  margin-bottom: 5px\n\n.confirmation\n  margin: 15px 0 6px\n  display: flex\n  justify-content: space-between\n\n.wrapper\n  margin-top: 5px\n\n.content\n  display: flex\n  flex-direction: column\n  gap: 10px\n\n.newPlan\n  display: flex\n  justify-content: space-between\n\n.optionsBox\n  display: flex\n  align-items: flex-end\n  gap: 25px\n\n.learnBox\n  display: flex\n  align-items: center\n  justify-content: space-around\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"selectionPlan": `styles_selectionPlan__n4FQA`,
	"confirmation": `styles_confirmation__zUeQV`,
	"wrapper": `styles_wrapper__UWkhi`,
	"content": `styles_content__v8scd`,
	"newPlan": `styles_newPlan__+MkqR`,
	"optionsBox": `styles_optionsBox__S0RBj`,
	"learnBox": `styles_learnBox__XCZM9`
};
export default ___CSS_LOADER_EXPORT___;
