import { createSlice } from "@reduxjs/toolkit"
import { userLogin } from "./authActions";
import { AuthState } from "./types";

const initialState : AuthState = {
  loading: false,
  data: null,
  error: null,
}

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    logout: (state) => {
      state.data = null;
      document.cookie = 'access_token=; path=/; max-age=0; samesite=strict';
      document.cookie = 'refresh_token=; path=/; max-age=0; samesite=strict';
      localStorage.clear();
    },
    
  },
  extraReducers: (builder) => {
    builder.addCase(userLogin.pending, (state) => {
      state.loading = true
      state.error = null
    })
    builder.addCase(userLogin.fulfilled, (state, action) => {
      state.loading = false;
      state.data = action.payload;
    })
    builder.addCase(userLogin.rejected, (state) => {
      state.loading = false;
    })
  }
})

export const { logout } = authSlice.actions;
export default authSlice.reducer