import { useEffect, useState } from "react";
import { useNavigate } from "react-router";

import { REESTR_PLAN_PUBLISH_ROUTE } from "common/const";
import useNotify from "common/hooks/useNotify";
import { optionType } from "common/types";
import MediaPlanTable from "components/tables/MediaPlanTable";
import { H1, H3 } from "components/typography";
import { Button } from "components/UI/Button";
import { Loader } from "components/UI/Loader";
import { Modal } from "components/UI/Modal";
import SizebleSelect from "components/UI/SizebleSelect";
import { fetchMediaPlans } from "services/store/reducers/media/actions";
import { clearSetProjectData } from "services/store/reducers/projects";
import { setProject } from "services/store/reducers/projects/actions";
import { selectSortedMediaPlans } from "services/store/selectors";
import { useAppDispatch, useAppSelector } from "services/store/store";

import commonStyles from "../common-styles.module.sass";
import styles from "./styles.module.sass";

const MediaPlanChoose: React.FC = (): JSX.Element => {
  const dispatch = useAppDispatch();
  const { mediaUploadsLoading } = useAppSelector((state) => state.mediaplan);
  const { clientId: client_id } = useAppSelector((state) => state.globalParams);
  const sortedMediaUploadsData = useAppSelector(selectSortedMediaPlans);
  const { setProjectData, setProjectLoading, setProjectError } = useAppSelector(
    (state) => state.project,
  );
  const { ToastContainer, notifyProjectSet } = useNotify();

  const [selectOptions, setSelectOptions] = useState<optionType[] | []>([]);
  const [selectRecrOptions, setSelectRecrOptions] = useState<optionType[] | []>(
    [],
  );
  const [selectMP, setSelectMP] = useState("");
  const [selectRecr, setSelectRecr] = useState("");
  const [step, setStep] = useState(1);
  const navigate = useNavigate();

  useEffect(() => {
    if (sortedMediaUploadsData && sortedMediaUploadsData.length > 0) {
      const selectOptions = sortedMediaUploadsData?.map((item) => {
        return { name: `Медиаплан №${item.id}`, value: item.id.toString() };
      });
      const recrOptions = [
        ...new Set(sortedMediaUploadsData?.map((item) => item.recr_plan_id)),
      ]
        .filter((item) => item !== null)
        .map((item) => {
          return {
            name: `План подбора №${item}`,
            value: item.toString(),
          };
        });
      setSelectOptions(selectOptions);
      setSelectRecrOptions(recrOptions);
    }
    return () => setSelectOptions([]);
  }, [sortedMediaUploadsData]);

  const [isCreateModalOpen, setIsCreateModalOpen] = useState(false);

  useEffect(() => {
    dispatch(fetchMediaPlans({ client_id }));

    return () => {
      dispatch(clearSetProjectData());
    };
  }, [dispatch, client_id]);

  const handleCreatePublishPlan = () => {
    dispatch(
      setProject({
        client_id: client_id,
        mediaplan_id: Number(selectMP),
        hire_plan_id: Number(selectRecr),
        name: `План публикации для медиаплана №${selectMP} и плана подбора №${selectRecr}`,
      }),
    );
  };

  const handleOpenModal = () => {
    setSelectMP(selectOptions[0].value);
    setIsCreateModalOpen(true);
  };

  const handleCloseModal = () => {
    setStep(1);
    setIsCreateModalOpen(false);
  };

  const handleContinueModal = () => {
    setSelectRecr(selectRecrOptions[0].value);
    setStep(2);
  };

  useEffect(() => {
    if (setProjectData) {
      navigate(REESTR_PLAN_PUBLISH_ROUTE);
    } else if (setProjectError) {
      notifyProjectSet("error");
      handleCloseModal();
    }
  }, [navigate, setProjectData, notifyProjectSet, setProjectError]);

  return (
    <>
      <section className={commonStyles.heading}>
        <H1 color="#0C3953">Выбор Медиаплана</H1>
      </section>
      <section className={styles.optionsBox}>
        <Button variant="primary" onClick={handleOpenModal}>
          Создать ПП
        </Button>
        <Button variant="primary">Создать медиаплан по кластерам</Button>
      </section>
      <section className={styles.wrapper}>
        {mediaUploadsLoading ? (
          <Loader />
        ) : (
          <MediaPlanTable data={sortedMediaUploadsData} />
        )}
      </section>
      <Modal
        classNames={styles.roundedModal}
        closeButtonPosition={{ top: 22, right: 24 }}
        open={isCreateModalOpen}
        onClose={handleCloseModal}
      >
        {step === 1 ? (
          <>
            <H3>Выберите медиаплан</H3>
            <SizebleSelect
              options={selectOptions}
              select={selectMP}
              setSelect={setSelectMP}
              size={7}
            />
            <Button
              variant="primary"
              className={styles.openBtn}
              onClick={handleContinueModal}
            >
              Продолжить
            </Button>
          </>
        ) : (
          <>
            <H3>Выберите план подбора</H3>
            <SizebleSelect
              options={selectRecrOptions}
              select={selectRecr}
              setSelect={setSelectRecr}
              size={7}
            />
            <Button
              variant="primary"
              className={styles.openBtn}
              onClick={handleCreatePublishPlan}
              disabled={setProjectLoading}
              loading={setProjectLoading}
            >
              Создать
            </Button>
          </>
        )}
      </Modal>
      <ToastContainer />
    </>
  );
};

export default MediaPlanChoose;
