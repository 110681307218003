import { isNull } from "lodash";

interface IRowCheckbox {
  activeSettings?: number | null;
  rowIndex: number;
  setActiveSettings?: (rowIndex: number | null) => void;
  oneOpener?: boolean;
  isSelected?: boolean;
  toggleSelection?: (id: number) => void;
}

export default function RowCheckbox({
  activeSettings,
  rowIndex,
  setActiveSettings,
  oneOpener = false,
  isSelected,
  toggleSelection,
}: IRowCheckbox) {
  return oneOpener && toggleSelection ? (
    <img
      className="cursor-pointer"
      src={`/mini-settings-${isSelected ? "minus" : "plus"}.svg`}
      alt="plus icon"
      onClick={() => toggleSelection(rowIndex)}
    />
  ) : (
    setActiveSettings && (
      <img
        className="cursor-pointer"
        src={`/mini-settings-${activeSettings === rowIndex ? "minus" : "plus"}.svg`}
        alt="plus icon"
        onClick={() =>
          setActiveSettings(
            isNull(activeSettings)
              ? rowIndex
              : activeSettings === rowIndex
                ? null
                : rowIndex,
          )
        }
      />
    )
  );
}
