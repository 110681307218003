import { FILTERED_VALUES } from "./constants";
import { FilteredValue, Row } from "./types";

export function filterData(
  data: (Row & { isSelected: boolean })[],
  filters: FilteredValue[],
): (Row & { isSelected: boolean })[] {
  return data.map((item) => {
    const isValid = FILTERED_VALUES.every(
      (filterField) =>
        filters
          .find((filter) => filter.key === filterField)
          ?.values.find(
            (value) => String(value.value) === String(item[filterField])
            
          )?.isSelected,
    );

    return { ...item, isSelected: isValid };
  });
}


export function isObjectChanged(
  initial?: FilteredValue,
  current?: FilteredValue,
) {
  if (initial && current) {
    for (let i = 0; i < initial.values.length; i++) {
      const initialValue = initial.values[i];
      const currentValue = current.values[i];
      if (
        String(initialValue.value) !== String(currentValue.value) ||
        initialValue.isSelected !== currentValue.isSelected
      ) {
        return true;
      }
    }
  }

  return false;
}