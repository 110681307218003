import { Outlet } from "react-router";

import { SIDEBAR_ROUTES, tabsBreadCrumps } from "common/const";
import Tabs from "components/Tabs";
import BackBtn from "components/UI/BackBtn";
import { BreadCrumbMultiTable } from "components/UI/BreadCrumpsMultiTable";

interface ITabsLayout {
  flow: "mediaplans" | "publish-plan";
}

const TabsLayout: React.FC<ITabsLayout> = ({ flow }): JSX.Element => {
  return (
    <div className="w-full grow flex flex-col">
      <BreadCrumbMultiTable breadCrumbs={tabsBreadCrumps} />
      <BackBtn />
      <Tabs
        tabRoutes={
          flow === "mediaplans"
            ? SIDEBAR_ROUTES[0].subroutes
            : SIDEBAR_ROUTES[1].subroutes
        }
      />
      <div role="tabpanel" className="flex flex-col grow items-stretch py-2">
        <Outlet />
      </div>
    </div>
  );
};

export default TabsLayout;
