// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.styles_toDoItem__Hsz78 {
  font-weight: 700;
  margin: 32px 0;
  color: #0C3953;
}`, "",{"version":3,"sources":["webpack://./src/components/ToDo/styles.module.sass"],"names":[],"mappings":"AAAA;EACE,gBAAA;EACA,cAAA;EACA,cAAA;AACF","sourcesContent":[".toDoItem\n  font-weight: 700\n  margin: 32px 0\n  color: #0C3953\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"toDoItem": `styles_toDoItem__Hsz78`
};
export default ___CSS_LOADER_EXPORT___;
