import {
  ChangeEvent,
  forwardRef,
  HTMLInputTypeAttribute,
  useState,
} from "react";

import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";

import classNames from "classnames";

import commonStyles from "../../../common/styles/common-classes/no-arrows.module.sass";
import styles from "./styles.module.sass";

interface Props extends React.HTMLAttributes<HTMLDivElement> {
  label: string;
  value?: string;
  min?: number;
  max?: number;
  onInputChange?: (
    event: Event | ChangeEvent,
    newValue: number | number[] | string,
  ) => void;
  type?: HTMLInputTypeAttribute;
  inputClassName?: string;
  readonly?: boolean;
  isError?: boolean;
  tooltip?: string;
  required?: boolean;
  theme?: "white" | "black";
}

export const FloatingLabelInput = forwardRef<HTMLDivElement, Props>(
  (
    {
      label,
      value = "",
      min,
      max,
      onInputChange,
      type = "text",
      readonly = false,
      isError = false,
      required = false,
      tooltip,
      inputClassName,
      theme = "black",
      className,
      ...props
    },
    ref,
  ) => {
    const [showTooltip, setShowTooltip] = useState(false);
    const [focused, setFocused] = useState(false);

    const updateValue = (event: ChangeEvent<HTMLInputElement>) => {
      const newValue: string = event.target.value;
      if (onInputChange)
        if (newValue !== "")
          onInputChange(event, type === "number" ? Number(newValue) : newValue);
        else if (!required) onInputChange(event, newValue);
    };

    const combinedClassName = classNames(
      styles.wrapper,
      focused && styles.focused,
      className,
      isError && styles.error,
      theme === "white" && "!border-white",
    );
    const combinedInputClassName = classNames(
      styles.input,
      type === "number" ? commonStyles.noArrows : undefined,
      inputClassName,
      tooltip && styles.withTooltip,
      theme === "white" && "!text-white",
    );

    return (
      <div
        onFocus={() => setFocused(true)}
        onBlur={() => setFocused(false)}
        ref={ref}
        className={combinedClassName}
        {...props}
      >
        <input
          type={type}
          className={combinedInputClassName}
          value={value}
          placeholder=""
          onChange={updateValue}
          readOnly={readonly}
        />
        <span
          className={classNames(
            styles.label,
            theme === "white" && "!text-white !opacity-70",
          )}
        >
          {label}
        </span>
        {tooltip && (
          <span
            onMouseEnter={() => setShowTooltip(true)}
            onMouseLeave={() => setShowTooltip(false)}
          >
            <InfoOutlinedIcon color="primary" className={styles.info} />
          </span>
        )}
        {showTooltip && <div className={styles.tooltip}>{tooltip}</div>}
      </div>
    );
  },
);
