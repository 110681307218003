// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.styles_header__dMeUR {
  padding: 20px 16px !important;
  line-height: 20px !important;
  color: #54687D !important;
}

.styles_headerBorder__O1LHG {
  border-bottom: solid 1px #C6CED8 !important;
}

.styles_accommodation__2cu4m {
  border-radius: 8px !important;
  box-shadow: 0 0 15px -5px lightgray !important;
}

.styles_cell__rTLSK {
  border-bottom: none !important;
  border-top: 1px solid #F2F2F2 !important;
  color: #0C3953 !important;
}

.styles_error__d82wW {
  animation: styles_error-glow__PzINc 4s ease-out forwards;
}

@keyframes styles_error-glow__PzINc {
  0% {
    box-shadow: inset 0px 0px 5px 4px rgb(255, 0, 0);
  }
  25% {
    box-shadow: inset 0px 0px 5px 4px rgb(255, 0, 0);
  }
  100% {
    box-shadow: inset 0px 0px 0 0 rgb(255, 0, 0);
  }
}`, "",{"version":3,"sources":["webpack://./src/components/SettingsTable/styles.module.sass"],"names":[],"mappings":"AAAA;EACE,6BAAA;EACA,4BAAA;EACA,yBAAA;AACF;;AACA;EACE,2CAAA;AAEF;;AAAA;EACE,6BAAA;EACA,8CAAA;AAGF;;AADA;EACE,8BAAA;EACA,wCAAA;EACA,yBAAA;AAIF;;AAFA;EACE,wDAAA;AAKF;;AAHA;EACE;IACE,gDAAA;EAMF;EALA;IACE,gDAAA;EAOF;EANA;IACE,4CAAA;EAQF;AACF","sourcesContent":[".header\n  padding: 20px 16px !important\n  line-height: 20px !important\n  color: #54687D !important\n\n.headerBorder\n  border-bottom: solid 1px #C6CED8 !important\n\n.accommodation\n  border-radius: 8px !important\n  box-shadow: 0 0 15px -5px lightgray !important\n\n.cell\n  border-bottom: none !important\n  border-top: 1px solid #F2F2F2 !important\n  color: #0C3953 !important\n\n.error\n  animation: error-glow 4s ease-out forwards\n\n@keyframes error-glow\n  0%\n    box-shadow: inset 0px 0px 5px 4px rgba(255,0,0,1)\n  25%\n    box-shadow: inset 0px 0px 5px 4px rgba(255,0,0,1)\n  100%\n    box-shadow: inset 0px 0px 0 0 rgba(255,0,0,1)\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"header": `styles_header__dMeUR`,
	"headerBorder": `styles_headerBorder__O1LHG`,
	"accommodation": `styles_accommodation__2cu4m`,
	"cell": `styles_cell__rTLSK`,
	"error": `styles_error__d82wW`,
	"error-glow": `styles_error-glow__PzINc`
};
export default ___CSS_LOADER_EXPORT___;
