import { useEffect, useState } from "react";

import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";

import { optionType } from "common/types";
import BasicSelect from "components/UI/Select";
import { getClientsProfilesMapping } from "services/store/reducers/mapping/types";

import commonStyles from "../common-styles.module.sass";
import { columns } from "./const";
import styles from "./styles.module.sass";

export default function ErrorTable({
  data,
  profiles,
}: {
  data: string[];
  profiles: getClientsProfilesMapping[];
}) {
  const [options, setOptions] = useState<optionType[] | []>([
    { name: "work in progress", value: "wip" },
  ]);
  const [select, setSelect] = useState("");

  return (
    <TableContainer>
      <Table stickyHeader aria-label="sticky-header">
        <TableHead>
          <TableRow>
            {columns.map((column, index) => (
              <TableCell
                className={commonStyles.tableHead}
                key={`header-cell-${index}`}
              >
                {column.label}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {data.map((item, index) => (
            <TableRow key={`body-row-${index}`}>
              <TableCell className={styles.tableBodyCell}>{item}</TableCell>
              <TableCell>
                <BasicSelect
                  options={options}
                  select={select}
                  setSelect={setSelect}
                />
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
