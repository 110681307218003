// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.styles_line__CASgO {
  border-bottom: solid 1px #EFF1F4;
  display: block;
}`, "",{"version":3,"sources":["webpack://./src/components/UI/HorizontalLine/styles.module.sass"],"names":[],"mappings":"AAAA;EACE,gCAAA;EACA,cAAA;AACF","sourcesContent":[".line\n  border-bottom: solid 1px #EFF1F4\n  display: block\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"line": `styles_line__CASgO`
};
export default ___CSS_LOADER_EXPORT___;
