import { useState } from "react";
import { Controller, useForm } from "react-hook-form";

import { Box, FormControl, FormGroup, FormLabel } from "@mui/material";
import { createTheme, ThemeProvider } from "@mui/material/styles";

import DatePicker from "components/UI/DatePicker";
import { adaptDate } from "components/UI/DatePicker/helpers";
import { Input } from "components/UI/Input";
import { FormDataConfig } from "services/store/reducers/config/types";

import { Button } from "../UI/Button";
import styles from "./styles.module.sass";

const theme = createTheme({
  components: {
    MuiFormLabel: {
      styleOverrides: {
        asterisk: {
          color: "#0057ac",
        },
      },
    },
  },
});

export type ConfigFormProps = {
  onSubmit: (formData: FormDataConfig) => void;
};

export const ConfigForm: React.FunctionComponent<ConfigFormProps> = ({
  onSubmit,
}) => {
  const { control, handleSubmit } = useForm<FormDataConfig>();
  const [activityDateFrom, setActivityDateFrom] = useState<
    Date | null | undefined
  >(null);
  const [prevMpDateFrom, setPrevMpDateFrom] = useState<Date | null | undefined>(
    null,
  );

  return (
    <ThemeProvider theme={theme}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <FormGroup
          sx={{
            overflow: "auto",
          }}
        >
          <Box display="flex" justifyContent="center" flexWrap="wrap" gap={2}>
            <FormControl
              margin="normal"
              className={`${styles.alignRight} ${styles.box}`}
            >
              <FormLabel>Ограничение численности населения</FormLabel>
              <Controller
                name="population"
                control={control}
                rules={{ required: "Население обязательно" }}
                render={({ field }) => (
                  <Input
                    {...field}
                    value={field.value || ""}
                    placeholder="Введите население"
                    type="number"
                    style={{ marginTop: 0 }}
                    sx={{
                      width: "200px",
                      "& input[type=number]": {
                        textAlign: "right",
                        MozAppearance: "textfield",
                        "&::-webkit-outer-spin-button": {
                          WebkitAppearance: "none",
                          margin: 0,
                        },
                        "&::-webkit-inner-spin-button": {
                          WebkitAppearance: "none",
                          margin: 0,
                        },
                      },
                    }}
                  />
                )}
              />
            </FormControl>
            <FormControl margin="normal" className={styles.box}>
              <FormLabel>Ограничение активного поиска</FormLabel>
              <Controller
                name="views"
                control={control}
                rules={{ required: "Просмотры обязательно" }}
                render={({ field }) => (
                  <Input
                    {...field}
                    value={field.value || ""}
                    placeholder="Введите просмотры"
                    type="number"
                    style={{ marginTop: 0 }}
                    sx={{
                      width: "200px",
                      "& input[type=number]": {
                        MozAppearance: "textfield",
                        "&::-webkit-outer-spin-button": {
                          WebkitAppearance: "none",
                          margin: 0,
                        },
                        "&::-webkit-inner-spin-button": {
                          WebkitAppearance: "none",
                          margin: 0,
                        },
                      },
                    }}
                  />
                )}
              />
            </FormControl>
          </Box>
          <Box
            display="flex"
            justifyContent="space-evenly"
            flexWrap="wrap"
            gap={2}
            sx={{
              borderBottom: "1px solid grey",
              pb: 1,
            }}
          >
            <FormControl
              required
              margin="normal"
              className={styles.alignCenter}
            >
              <FormLabel>Учётный период активности от и до</FormLabel>
              <div className={styles.dates}>
                <Controller
                  name="date_from"
                  control={control}
                  rules={{ required: "Дата обязательна" }}
                  render={({ field }) => (
                    <DatePicker
                      value={field.value || null}
                      onChange={(date) => {
                        setActivityDateFrom(date as Date | undefined);
                        field.onChange(adaptDate(date));
                      }}
                      sx={{ width: "200px" }}
                      className={styles.smallInput}
                    />
                  )}
                />
                <Controller
                  name="date_to"
                  control={control}
                  rules={{ required: "Дата обязательна" }}
                  render={({ field }) => (
                    <DatePicker
                      value={field.value || null}
                      onChange={(date) => {
                        field.onChange(adaptDate(date));
                      }}
                      minDate={activityDateFrom || undefined}
                      sx={{ width: "200px" }}
                      className={styles.smallInput}
                    />
                  )}
                />
              </div>
            </FormControl>
          </Box>
          <Box
            display="flex"
            justifyContent="space-evenly"
            flexWrap="wrap"
            gap={2}
            sx={{
              borderBottom: "1px solid grey",
              pb: 1,
            }}
          >
            <FormControl
              required
              className={styles.alignCenter}
              margin="normal"
            >
              <FormLabel>На какой период считаем медиаплан</FormLabel>
              <div className={styles.dates}>
                <Controller
                  name="mp_start"
                  control={control}
                  rules={{ required: "Дата обязательна" }}
                  render={({ field }) => (
                    <DatePicker
                      value={field.value || null}
                      onChange={(date) => {
                        field.onChange(adaptDate(date));
                      }}
                      sx={{ width: "200px" }}
                      className={styles.smallInput}
                    />
                  )}
                />
                <Controller
                  name="mp_finish"
                  control={control}
                  rules={{ required: "Дата обязательна" }}
                  render={({ field }) => (
                    <DatePicker
                      value={field.value || null}
                      onChange={(date) => {
                        field.onChange(adaptDate(date));
                      }}
                      sx={{ width: "200px" }}
                      className={styles.smallInput}
                    />
                  )}
                />
              </div>
            </FormControl>
          </Box>
          <Box
            display="flex"
            justifyContent="space-evenly"
            flexWrap="wrap"
            gap={2}
            sx={{
              borderBottom: "1px solid grey",
              pb: 1,
            }}
          >
            <FormControl
              required
              className={styles.alignCenter}
              margin="normal"
            >
              <FormLabel>Дата действия предыдущего медиаплана</FormLabel>
              <div className={styles.dates}>
                <Controller
                  name="prev_mp_start"
                  control={control}
                  rules={{ required: "Дата обязательна" }}
                  render={({ field }) => (
                    <DatePicker
                      value={field.value || null}
                      onChange={(date) => {
                        setPrevMpDateFrom(date as Date | undefined);
                        field.onChange(adaptDate(date));
                      }}
                      sx={{ width: "200px" }}
                      className={styles.smallInput}
                    />
                  )}
                />
                <Controller
                  name="prev_mp_finish"
                  control={control}
                  rules={{ required: "Дата обязательна" }}
                  render={({ field }) => (
                    <DatePicker
                      value={field.value || null}
                      onChange={(date) => {
                        field.onChange(adaptDate(date));
                      }}
                      sx={{ width: "200px" }}
                      className={styles.smallInput}
                      minDate={prevMpDateFrom || undefined}
                    />
                  )}
                />
              </div>
            </FormControl>
          </Box>
          <Box
            display="flex"
            justifyContent="space-evenly"
            flexWrap="wrap"
            gap={2}
            sx={{
              borderBottom: "1px solid grey",
              pb: 1,
            }}
          >
            <FormControl
              required
              margin="normal"
              className={styles.alignCenter}
            >
              <FormLabel>Дата загрузки отчёта от и до</FormLabel>
              <div className={styles.dates}>
                <Controller
                  name="sr_start"
                  control={control}
                  rules={{ required: "Дата обязательна" }}
                  render={({ field }) => (
                    <DatePicker
                      value={field.value || null}
                      onChange={(date) => {
                        setActivityDateFrom(date as Date | undefined);
                        field.onChange(adaptDate(date));
                      }}
                      sx={{ width: "200px" }}
                      className={styles.smallInput}
                    />
                  )}
                />
                <Controller
                  name="sr_finish"
                  control={control}
                  rules={{ required: "Дата обязательна" }}
                  render={({ field }) => (
                    <DatePicker
                      value={field.value || null}
                      onChange={(date) => {
                        field.onChange(adaptDate(date));
                      }}
                      minDate={activityDateFrom || undefined}
                      sx={{ width: "200px" }}
                      className={styles.smallInput}
                    />
                  )}
                />
              </div>
            </FormControl>
          </Box>
          <Box
            display="flex"
            justifyContent="center"
            flexWrap="wrap"
            gap={2}
            sx={{
              pb: 2,
            }}
          >
            <FormControl margin="normal" className={styles.box}>
              <FormLabel>Количество периодов</FormLabel>
              <Controller
                name="finish_date"
                control={control}
                render={({ field }) => (
                  <Input
                    {...field}
                    value={field.value ?? 4}
                    placeholder="По умолчанию 4"
                    type="number"
                    style={{ marginTop: 0 }}
                    sx={{
                      "& input[type=number]": {
                        MozAppearance: "textfield",
                        "&::-webkit-outer-spin-button": {
                          WebkitAppearance: "none",
                          margin: 0,
                        },
                        "&::-webkit-inner-spin-button": {
                          WebkitAppearance: "none",
                          margin: 0,
                        },
                      },
                    }}
                  />
                )}
              />
            </FormControl>
          </Box>
          <Button type="submit" variant="primary">
            Отправить
          </Button>
        </FormGroup>
      </form>
    </ThemeProvider>
  );
};
