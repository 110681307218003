// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.styles_message__xnsEZ {
  padding: 13.5px 39px;
  border: 1px solid #000;
  border-radius: 10px;
  font-weight: 500;
  font-size: 11px;
  line-height: 20px;
  position: relative;
}

.styles_error__j9UGo {
  border-color: #EF493E;
  color: #EF493E;
  background: #FDEDEC;
}

.styles_success__0E\\+PW {
  border-color: #2baa32;
  color: #2baa32;
  background: #ecfdec;
}

.styles_link__5b0R1 {
  color: inherit;
}

.styles_close__EZ3vH {
  position: absolute;
  top: 5px;
  right: 10px;
  cursor: pointer;
  font-size: 13px;
  font-weight: 500;
  line-height: 1.2;
  color: #000;
}
.styles_close__EZ3vH:before {
  content: "x";
}`, "",{"version":3,"sources":["webpack://./src/components/UI/Message/styles.module.sass"],"names":[],"mappings":"AAAA;EACE,oBAAA;EACA,sBAAA;EACA,mBAAA;EACA,gBAAA;EACA,eAAA;EACA,iBAAA;EACA,kBAAA;AACF;;AACA;EACE,qBAAA;EACA,cAAA;EACA,mBAAA;AAEF;;AAAA;EACE,qBAAA;EACA,cAAA;EACA,mBAAA;AAGF;;AADA;EACE,cAAA;AAIF;;AAFA;EACE,kBAAA;EACA,QAAA;EACA,WAAA;EACA,eAAA;EACA,eAAA;EACA,gBAAA;EACA,gBAAA;EACA,WAAA;AAKF;AAJE;EACE,YAAA;AAMJ","sourcesContent":[".message\n  padding: 13.5px 39px\n  border: 1px solid #000\n  border-radius: 10px\n  font-weight: 500\n  font-size: 11px\n  line-height: 20px\n  position: relative\n\n.error\n  border-color: #EF493E\n  color: #EF493E\n  background: #FDEDEC\n\n.success\n  border-color: #2baa32\n  color: #2baa32\n  background: #ecfdec\n\n.link\n  color: inherit\n\n.close\n  position: absolute\n  top: 5px\n  right: 10px\n  cursor: pointer\n  font-size: 13px\n  font-weight: 500\n  line-height: 1.2\n  color: #000\n  &:before\n    content: 'x'\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"message": `styles_message__xnsEZ`,
	"error": `styles_error__j9UGo`,
	"success": `styles_success__0E+PW`,
	"link": `styles_link__5b0R1`,
	"close": `styles_close__EZ3vH`
};
export default ___CSS_LOADER_EXPORT___;
