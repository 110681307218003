import {
  Caption,
  MediaPlanMultiColumn,
  MediaPlanMultiColumnId,
} from "common/const";
import { capitalizeFirstLetter, formatCityName } from "common/utils/formatText";
import { handleDate } from "common/utils/handleDate";
import { roundToTwoDecimalPlaces } from "common/utils/roundToTwoDecimalPlaces";
import { FilteredValue } from "components/UI/FilterPanel/types";
import { Option } from "components/UI/MultipleSelect";
import { isNull } from "lodash";

import { SplittedMediaPlanColumns } from "./types";

export function handleCell(
  value: string | number | null,
  column: string,
): string {
  let result = value;

  if (typeof value === "number" && !Number.isInteger(value))
    result = roundToTwoDecimalPlaces(value).toString();

  if (
    (column === "date_start" || column === "date_end") &&
    typeof value === "string"
  )
    result = handleDate(value, false, true);

  if (column === "region" && typeof result === "string")
    result = capitalizeFirstLetter(result);

  if (column === "city" && typeof result === "string")
    result = formatCityName(result);

  return result?.toString() ?? "";
}

const getLimitNumber = (number: number, limits: Caption[]): number =>
  limits.slice(0, number + 1).reduce((sum, cur) => sum + cur.unions, 0);

function isInArea(
  columnIndex: number,
  startLimitIndex: number,
  endLimitIndex: number,
  limits?: Caption[],
): boolean {
  if (!limits) return false;
  const columnNumber = columnIndex + 1;
  const startLimit = getLimitNumber(startLimitIndex, limits);
  const endLimit = getLimitNumber(endLimitIndex, limits);
  return columnNumber > startLimit && columnNumber <= endLimit;
}

export function isGrayCell(columnIndex: number, limits?: Caption[]): boolean {
  return isInArea(columnIndex, 0, 1, limits);
}

export function isGrayerCell(columnIndex: number, limits?: Caption[]): boolean {
  return isInArea(columnIndex, 1, 2, limits);
}

interface MiniSettings {
  rowIndex: number;
  activeSettings: number | null;
  setActiveSettings: (rowIndex: number | null) => void;
}

const isSameRow = (selectedRow: number | null, currentRow: number): boolean =>
  selectedRow === currentRow;

export function commafy(num: string | number | undefined) {
  if (num === undefined) return 0;
  const str = num.toString().split(".");
  if (str[0].length >= 3) {
    str[0] = str[0].replace(/(\d)(?=(\d{3})+$)/g, "$1 ");
  }
  if (str[1] && str[1].length >= 3) {
    str[1] = str[1].slice(0, 2).replace(/(\d{3})/g, "$1 ");
  }
  return str.join(",");
}

export function handleValue(
  value: string,
  columnId: MediaPlanMultiColumnId,
  miniSettings?: MiniSettings,
): string | JSX.Element {
  if (columnId === "amount_on_start" && value !== "")
    return +value % 1 === 0 ? `${commafy(value)},0 ₽` : `${commafy(value)} ₽`;
  else if (columnId === "region" && value !== "" && miniSettings) {
    const { rowIndex, activeSettings, setActiveSettings } = miniSettings;
    return (
      <span className="flex items-center gap-[34px]">
        <img
          className="cursor-pointer hover:scale-110 transition-all"
          src={`/mini-settings-${isSameRow(activeSettings, rowIndex) ? "minus" : "plus"}.svg`}
          alt="plus icon"
          onClick={() =>
            setActiveSettings(
              isNull(activeSettings)
                ? rowIndex
                : isSameRow(activeSettings, rowIndex)
                  ? null
                  : rowIndex,
            )
          }
        />
        {value}
      </span>
    );
  } else return value;
}

export function splitArrayById(
  inputArray: readonly MediaPlanMultiColumn[],
  filteredOptions: Option[],
): SplittedMediaPlanColumns {
  const result = { common: [] };
  let groupIndex = 1;
  let currentGroup = "common";

  for (let i = 0; i < inputArray.length; i++) {
    const item = inputArray[i];

    if (item.id === "leads_for_week") {
      if (currentGroup === "common") {
        currentGroup = groupIndex.toString();
        result[currentGroup] = [item];
      } else {
        groupIndex += 1;
        currentGroup = groupIndex.toString();
        result[currentGroup] = [item];
      }
    } else {
      if (!result[currentGroup]) {
        result[currentGroup] = [];
      }
      result[currentGroup].push(item);
    }
  }

  const filteredResult = (() => {
    const updatedResult = {};
    const maxKey = filteredOptions.filter(
      ({ isSelected }) => isSelected,
    ).length;

    Object.keys(result).forEach((key) => {
      if (key === "common" || (Number(key) <= maxKey && !isNaN(Number(key)))) {
        updatedResult[key] = result[key];
      }
    });

    return updatedResult;
  })();

  return filteredResult;
}

export function isObjectChanged(
  initial?: FilteredValue,
  current?: FilteredValue,
) {
  if (initial && current) {
    for (let i = 0; i < initial.values.length; i++) {
      const initialValue = initial.values[i];
      const currentValue = current.values[i];
      if (
        initialValue.value !== currentValue.value ||
        initialValue.isSelected !== currentValue.isSelected
      ) {
        return true;
      }
    }
  }

  return false;
}

export function getLeadCountRange(
  leadcount: number,
  lower: number,
  upper: number,
) {
  if (leadcount === 0 || leadcount === undefined) return "-";
  return `${Math.round(leadcount * lower)} - ${Math.ceil(leadcount * upper)}`;
}

export function getLeadBudgetRange(
  budget: number,
  lower: number,
  upper: number,
) {
  if (budget === 0 || budget === undefined) return "-";
  return `${(budget * lower).toFixed(2)} ₽ - ${(budget * upper).toFixed(2)} ₽`;
}

export function getRandomNeed() {
  return Math.round(Math.random() * (40 - 20 + 1)) + 20;
}

// true если выбран не только custom, false если выбран только custom
export function isChosenMoreThanCustom(options: Option[]) {
  const selectedPlans = options.filter((option) => option.isSelected);
  if (selectedPlans.length === 1 && selectedPlans[0].value === "custom") {
    return false;
  }
  return true;
}
