import { Column } from "./types";

export const columns: readonly Column[] = [
  { id: 'cluster', label: 'Номер кластера' },
  { id: 'profile', label: 'Профиль'},
  { id: 'demand', label: 'Потребность'},
  { id: 'objects', label: 'Количество объектов'},
  { id: 'factors_1', label: 'Фактор №1'},
  { id: 'factors_2', label: 'Фактор №2'},
  { id: 'factors_3', label: 'Фактор #3'}
]