// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.styles_header__56JST {
  margin-bottom: 1rem;
}

.styles_controls__EtPqg {
  display: flex;
  gap: 15px;
  margin-bottom: 20px;
}

.styles_uploadFilesModal__X\\+iTq {
  max-width: 230px;
}

.styles_exel_control__8VqgJ {
  margin: 20px 0;
}

.styles_saveBtn__3sTFo {
  margin-left: auto;
}

.styles_roundedModal__OIGsp, .styles_exelModal__EbIe8 {
  padding: 20px 24px !important;
  border-radius: 16px;
}

.styles_roundedModal__OIGsp {
  max-width: 230px;
}

.styles_exelModal__EbIe8 {
  max-width: 360px;
}`, "",{"version":3,"sources":["webpack://./src/pages/list-objects/styles.module.sass"],"names":[],"mappings":"AAAA;EACE,mBAAA;AACF;;AACA;EACE,aAAA;EACA,SAAA;EACA,mBAAA;AAEF;;AAAA;EACE,gBAAA;AAGF;;AADA;EACE,cAAA;AAIF;;AAFA;EACE,iBAAA;AAKF;;AAHA;EACE,6BAAA;EACA,mBAAA;AAMF;;AAJA;EACE,gBAAA;AAOF;;AALA;EACE,gBAAA;AAQF","sourcesContent":[".header\n  margin-bottom: 1rem\n\n.controls\n  display: flex\n  gap: 15px\n  margin-bottom: 20px\n\n.uploadFilesModal\n  max-width: 230px\n\n.exel_control\n  margin: 20px 0\n\n.saveBtn\n  margin-left: auto\n\n.roundedModal, .exelModal\n  padding: 20px 24px !important\n  border-radius: 16px\n\n.roundedModal\n  max-width: 230px\n\n.exelModal\n  max-width: 360px\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"header": `styles_header__56JST`,
	"controls": `styles_controls__EtPqg`,
	"uploadFilesModal": `styles_uploadFilesModal__X+iTq`,
	"exel_control": `styles_exel_control__8VqgJ`,
	"saveBtn": `styles_saveBtn__3sTFo`,
	"roundedModal": `styles_roundedModal__OIGsp`,
	"exelModal": `styles_exelModal__EbIe8`
};
export default ___CSS_LOADER_EXPORT___;
