import { useEffect, useMemo, useRef, useState } from "react";
import { useLocation } from "react-router";

import {
  EMPTY_DATA,
  MEDIA_PLAN_MULTI_COLUMNS,
  MEDIA_PLAN_MULTI_PLANS,
} from "common/const";
import MediaPlanMultiTable from "components/tables/MediaPlanMultiTable";
import { Loader } from "components/UI/Loader";
import { Option } from "components/UI/MultipleSelect";
import { fetchMediaPlanMulti } from "services/store/reducers/media/actions";
import { SpecificPlan } from "services/store/reducers/media/types";
import { useAppDispatch, useAppSelector } from "services/store/store";

import { fetchAndDownloadFile } from "../media-plan/helpers";
import { transformPlansObjectToArray } from "./helpers";

const MediaPlanMultiPage: React.FC = (): JSX.Element => {
  const [data, setData] = useState<SpecificPlan[]>([]);
  const dispatch = useAppDispatch();
  const { search } = useLocation();
  const downloadRef = useRef<HTMLAnchorElement>(null);
  const [selectedPlan, setSelectedPlan] = useState<string>();
  const [filteredOptions, setFilteredOptions] = useState<Option[]>([]);
  const { clientId } = useAppSelector((state) => state.globalParams);

  const { id, uploaderName } = useMemo(() => {
    const urlParams = new URLSearchParams(search);
    return {
      id: Number(urlParams.get("id")),
      uploaderName: urlParams.get("uploader_name") ?? "",
    };
  }, [search]);

  const {
    mediaPlanMultiData,
    mediaPlanMultiLoading,
    shouldMediaPlanMultiRefresh,
  } = useAppSelector((state) => state.mediaplan);

  const getRef = () => downloadRef;

  const handleGetExcel = (): void => {
    fetchAndDownloadFile(
      `/media-plan/get-clients-media-plan/${id}?filter_uploader=${uploaderName}`,
      downloadRef,
    );
  };

  const handleSelectPlan = (planKey: string) => {
    setSelectedPlan(planKey);
    setFilteredOptions((previousState) =>
      previousState.map((option) => ({
        ...option,
        isSelected: !!(option.name === "Утвержденный"),
      })),
    );
  };

  useEffect(() => {
    if (selectedPlan) {
      const filteredPlan = filteredOptions.find(
        ({ name }) => name === selectedPlan,
      );
      if (!filteredPlan?.isSelected) {
        setSelectedPlan(undefined);
      }
    }
  }, [filteredOptions, selectedPlan]);

  useEffect(() => {
    dispatch(fetchMediaPlanMulti(id));
  }, [dispatch, id]);

  useEffect(() => {
    if (mediaPlanMultiData && !mediaPlanMultiLoading) {
      const planTypeOrder = ["custom", "optimal" || "opt", "max", "min"];
      const sortedData = [...mediaPlanMultiData].sort((a, b) => {
        return planTypeOrder.indexOf(a.plan) - planTypeOrder.indexOf(b.plan);
      });
      setFilteredOptions(
        transformPlansObjectToArray(MEDIA_PLAN_MULTI_PLANS, sortedData),
      );
      setData(sortedData);
    }

    return () => setData([]);
  }, [mediaPlanMultiData, mediaPlanMultiLoading]);

  return (
    <section className="h-screen flex grow">
      {mediaPlanMultiLoading && shouldMediaPlanMultiRefresh ? (
        <div
          className="glow w-full flex items-center justify-center"
          style={{ height: "calc(100vh - 150px)" }}
        >
          <Loader />
        </div>
      ) : data && data.length ? (
        <MediaPlanMultiTable
          uploadId={id}
          clientId={clientId}
          data={data}
          columns={MEDIA_PLAN_MULTI_COLUMNS}
          plans={MEDIA_PLAN_MULTI_PLANS}
          filteredOptions={filteredOptions}
          selectedPlan={selectedPlan}
          onSelectPlan={handleSelectPlan}
          getRef={getRef}
          onDownload={handleGetExcel}
          setFilteredOptions={setFilteredOptions}
        />
      ) : (
        <span className="ml-[50%] -translate-x-1/2">{EMPTY_DATA}</span>
      )}
    </section>
  );
};

export default MediaPlanMultiPage;
