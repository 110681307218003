import { useEffect, useState } from "react";
import { useParams } from "react-router";

import MediaPlanPublishTable from "components/tables/MediaPlanPublishTable";
import { H1 } from "components/typography";
import BackBtn from "components/UI/BackBtn";
import { Loader } from "components/UI/Loader";
import { fetchMediaPlanMulti } from "services/store/reducers/media/actions";
import { Record } from "services/store/reducers/media/types";
import { useAppDispatch, useAppSelector } from "services/store/store";

import styles from "./styles.module.sass";

const MediaPlanChoosePage: React.FC = (): JSX.Element => {
  const { mpId } = useParams();
  const { mediaPlanMultiData, mediaPlanMultiLoading } = useAppSelector(
    (state) => state.mediaplan,
  );
  const [data, setData] = useState<Record[]>([]);
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(fetchMediaPlanMulti(Number(mpId)));
  }, [mpId, dispatch]);

  useEffect(() => {
    if (mediaPlanMultiData && !mediaPlanMultiLoading) {
      const customPlan = mediaPlanMultiData.find(
        (data) => data.plan === "custom",
      );
      const optimalPlan = mediaPlanMultiData.find(
        (data) => data.plan === "optimal",
      );
      setData(
        customPlan ? customPlan.data : optimalPlan ? optimalPlan.data : [],
      );
    }
  }, [mediaPlanMultiData, mediaPlanMultiLoading]);

  return (
    <>
      <header className={styles.header}>
        <BackBtn isTrue />
        <H1>Медиаплан № {mpId}</H1>
      </header>
      {mediaPlanMultiLoading ? (
        <div
          className="glow w-full flex items-center justify-center"
          style={{ height: "calc(100vh - 150px)" }}
        >
          <Loader />
        </div>
      ) : data && data.length > 0 ? (
        <MediaPlanPublishTable data={data} uploadId={Number(mpId)} />
      ) : (
        <span>Нет утверждённого медиаплана</span>
      )}
    </>
  );
};

export default MediaPlanChoosePage;
