import classNames from "classnames";

interface FilterButtonProps {
  isActive?: boolean;
}

export default function FilterButton({ isActive = false }: FilterButtonProps) {
  return (
    <button className="cursor-pointer outline-none border-none bg-transparent ml-1">
      <img
        className={classNames(isActive && "align-bottom")}
        src={`/media-filter${isActive ? "-active" : ""}.svg`}
        alt="filter icon"
      />
    </button>
  );
}
