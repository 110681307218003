import { useRef } from "react";

import { EXCEL_ACCEPT_FORMAT } from "common/const";
import { Button } from "components/UI/Button";
import Upload from "components/UI/Upload";

interface IUploadFiles {
  fileName: string;
  handleUpload: (files: FileList | null) => void;
}

// TODO: Как будет ручка сделать отправку файла через редакс

export default function UploadFiles(props: IUploadFiles) {
  const uploadRef = useRef<HTMLInputElement>(null);

  const handleUploadClick = (): void => {
    if (uploadRef.current) uploadRef.current.click();
  };

  return (
    <Upload
      id="upload"
      forwardRef={uploadRef}
      onUpload={props.handleUpload}
      accept={EXCEL_ACCEPT_FORMAT}
      label={props.fileName}
    >
      <Button variant="upload" onClick={handleUploadClick}>
        Выберите файл
      </Button>
    </Upload>
  );
}
