import { useState } from "react";

import { STORE_INIT_FILE_NAME } from "common/const";

interface CustomStates {
  fileName: string;
  file: File | null;
  handleUpload: (files: FileList | null) => void;
  handleClearUpload: () => void;
}

export const useUploadHandler = (): CustomStates => {
  const [fileName, setFileName] = useState(STORE_INIT_FILE_NAME);
  const [file, setFile] = useState<File | null>(null);

  const handleUpload = (files: FileList | null): void => {
    if (files && "0" in files) {
      const uploadedFile: File = files[0];
      setFileName(uploadedFile.name);
      setFile(uploadedFile);
    }
  };

  const handleClearUpload = () => {
    setFileName(STORE_INIT_FILE_NAME);
    setFile(null);
  };

  return { fileName, file, handleUpload, handleClearUpload };
};
