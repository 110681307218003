import { RefObject } from "react";

import { downloadFile } from "api/Rocket";
import { AxiosResponse } from "axios";

export function fetchAndDownloadFile(
  endpointCore: string,
  downloadRef: RefObject<HTMLAnchorElement>,
) {
  downloadFile(endpointCore, {
    excel: true.toString(),
  })
    .then((response: AxiosResponse) => {
      handleDownloadExcel(downloadRef.current, response.data.response.fileLink);
      if ("error" in response.data.response)
        console.error(response.data.response.error);
    })
    .catch((error: Error) => {
      console.error("Произошла ошибка при обращении к серверу", error);
      console.error(error.message);
    });
}

function handleDownloadExcel(
  hiddenLink: HTMLAnchorElement | null,
  url: string,
): void {
  hiddenLink?.setAttribute("href", url);
  hiddenLink?.click();
}
