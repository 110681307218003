import { useEffect, useRef, useState } from "react";

import { Box, Modal as MUIModal, Typography } from "@mui/material";

import classNames from "classnames";
import {
  EMPTY_DATA,
  EXCEL_ACCEPT_FORMAT,
  RECRUITMENTS_FILTERS,
} from "common/const";
import useNotify from "common/hooks/useNotify";
import useSetMessages from "common/hooks/useSetMessages";
import { useUploadHandler } from "common/hooks/useUploadHandler";
import { UploadMessage } from "common/interfaces";
import { addOptionalParams } from "common/utils/addOptionalParams";
import { checkOnPositiveInteger } from "common/utils/zodValidators";
import { Filters, FilterValues } from "components/Filters";
import PlanUploadsTable from "components/tables/PlanUploadsTable";
import { H1 } from "components/typography";
import { Button } from "components/UI/Button";
import { Loader } from "components/UI/Loader";
import { Message } from "components/UI/Message";
import Upload from "components/UI/Upload";
import { clearRecruitmentData } from "services/store/reducers/recruitment";
import {
  getRecruitmentPlans,
  postRecruitmentPlan,
} from "services/store/reducers/recruitment/actions";
import { GetRecruitmentPlansParams } from "services/store/reducers/recruitment/types";
import { selectSortedRequirementPlans } from "services/store/selectors";
import { useAppDispatch, useAppSelector } from "services/store/store";
import useSessionStorageState from "use-session-storage-state";
import { SafeParseReturnType } from "zod";

import commonStyles from "../common-styles.module.sass";
import styles from "./styles.module.sass";

const SelectionPage: React.FC = (): JSX.Element => {
  const { clientId, uploaderName } = useAppSelector(
    (state) => state.globalParams,
  );
  const { getRecruitmentPlansLoading } = useAppSelector((state) => state.recr);

  const SortedRequirementPlans = useAppSelector(selectSortedRequirementPlans);

  const {
    uploadRecruitmentPlanData,
    uploadRecruitmentPlanError,
    uploadRecruitmentPlanLoading,
  } = useAppSelector((state) => state.recr);

  const dispatch = useAppDispatch();

  const [uploadMessage, setUploadMessage] =
    useSessionStorageState<UploadMessage | null>("upload-message-selection");

  const [errors, setErrors] = useState<string[]>();
  const [loadingModalOpen, setLoadingModalOpen] = useState(false);

  const uploadRef = useRef<HTMLInputElement>(null);
  const { fileName, file, handleUpload } = useUploadHandler();
  const { ToastContainer, notifyUploading } = useNotify();

  const handleUpdateFilters = (filterValues: FilterValues): void => {
    const sendParams: GetRecruitmentPlansParams = {
      client_id: clientId,
    };
    addOptionalParams(sendParams, filterValues);

    let result: SafeParseReturnType<number, number> | undefined;
    result = checkOnPositiveInteger(
      filterValues.id,
      RECRUITMENTS_FILTERS.errorsPhrases.id,
    );
    const errorMessage = result?.error?.issues[0].message;

    if (errorMessage) {
      setErrors([errorMessage]);
      return;
    }

    setErrors(undefined);
    dispatch(getRecruitmentPlans(sendParams));
  };

  const handleUploadClick = (): void => {
    if (uploadRef.current) uploadRef.current.click();
  };

  const handleClearMessage = (): void => {
    setUploadMessage(null);
  };

  const handleSaveClick = (): void => {
    if (file && !uploadRecruitmentPlanLoading) {
      setUploadMessage(null);
      const params = {
        client_id: clientId.toString(),
        uploader_name: uploaderName,
      };
      dispatch(postRecruitmentPlan({ params, file }));
    }
  };

  useSetMessages({
    data: uploadRecruitmentPlanData,
    fileName,
    notifyUploading,
    setUploadMessage,
    error: uploadRecruitmentPlanError,
  });

  useEffect(() => {
    if (clientId) {
      dispatch(
        getRecruitmentPlans({
          client_id: clientId,
        }),
      );
    }

    return () => {
      dispatch(clearRecruitmentData());
    };
  }, [dispatch, clientId]);

  return (
    <>
      <section className={classNames(commonStyles.heading)}>
        <H1 color="#0C3953">Планы подбора</H1>
        <Filters
          onUpdateFilters={handleUpdateFilters}
          configuration={RECRUITMENTS_FILTERS}
          errors={errors}
        />
      </section>
      <section className={classNames(styles.upload)}>
        <Button
          style={{ margin: "20px 0 16px" }}
          variant="download"
          onClick={() => setLoadingModalOpen(true)}
        >
          Загрузка
        </Button>
        <ToastContainer />
        {uploadMessage && (
          <Message
            text={uploadMessage.message}
            success={uploadMessage.success}
            close={handleClearMessage}
          />
        )}
      </section>
      {getRecruitmentPlansLoading ? (
        <Loader />
      ) : SortedRequirementPlans && SortedRequirementPlans.length > 0 ? (
        <PlanUploadsTable data={SortedRequirementPlans} navigable />
      ) : (
        EMPTY_DATA
      )}
      <MUIModal
        open={loadingModalOpen}
        onClose={() => setLoadingModalOpen(false)}
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            transform: "translate(-50%, -50%)",
            width: 400,
            bgcolor: "background.paper",
            border: "2px solid #000",
            boxShadow: 24,
            p: 4,
            borderRadius: 5,
          }}
        >
          <Typography variant="h6" component="h2" sx={{ marginBottom: 2 }}>
            Загрузка
          </Typography>
          <div>
            <div className={styles.confirmation}>
              <Upload
                forwardRef={uploadRef}
                id="upload"
                onUpload={handleUpload}
                accept={EXCEL_ACCEPT_FORMAT}
                label={fileName}
              >
                <Button
                  variant="upload"
                  loading={uploadRecruitmentPlanLoading}
                  disabled={uploadRecruitmentPlanLoading}
                  onClick={handleUploadClick}
                >
                  Выберите файл
                </Button>
              </Upload>
            </div>
          </div>
          <div className="flex flex-row w-full mt-10 justify-between px-6">
            <Button
              variant="primary"
              onClick={handleSaveClick}
              loading={uploadRecruitmentPlanLoading}
              disabled={uploadRecruitmentPlanLoading}
            >
              Сохранить
            </Button>
            <Button
              variant="download"
              onClick={() => setLoadingModalOpen(false)}
            >
              Закрыть
            </Button>
          </div>
        </Box>
      </MUIModal>
    </>
  );
};

export default SelectionPage;
