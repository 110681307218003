import styled from "@emotion/styled";

import { TTextProps } from "./types";

export default styled.h1<TTextProps>`
  font-size: 2.25rem;
  white-space: nowrap;
  text-align: ${({ align }) => align ?? "left"};
  color: ${({ color, theme }) => color ?? theme.colors.text.superDark};
  font-weight: 600;
  font-family: ${({ fontFamily }) =>
    fontFamily ?? "Montserrat, Arial, 'Helvetica Neue', Helvetica, sans-serif"};
`;
