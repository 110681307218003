import { createAsyncThunk } from "@reduxjs/toolkit";
import axios, { AxiosResponse } from "axios";
import { ClusterasationParams, TObject } from "./types";
import { PUBLISH_API_URL } from "api/endpoint";
import axiosInstance from "services/store/axiosInstance";
import { handleParams } from "common/utils/handleParams";

export const getObjects = createAsyncThunk(
  "objects/getObjects",
  async (client_id: number, { rejectWithValue}) => {
    try {
      const response: AxiosResponse<TObject[]> = await axiosInstance.get(
        `${PUBLISH_API_URL}/objects?client_id=${client_id}`
      );
      return response.data;
    } catch (error) {
      return axios.isAxiosError(error) && error.response
       ? rejectWithValue(error.response.data)
        : rejectWithValue(error.message);
    }
  }
)

export const startClusterasation = createAsyncThunk(
  "objects/startClusterasation",
  async (params: ClusterasationParams, { rejectWithValue }) => {
    try {
      const urlParams = handleParams(params)
      const response: string = await axiosInstance.post(
        `${PUBLISH_API_URL}/objects/clusterisation${urlParams}`
      )
      return response;
    } catch (error) {
      return axios.isAxiosError(error) && error.response
       ? rejectWithValue(error.response.data)
        : rejectWithValue(error.message);
    }
  }
)