import { Column } from "./types";

export const columns: Column[] = [
  { id: "file", label: "План подбора" },
  {
    id: "date",
    label: "Дата загрузки плана подбора",
  },
  {
    id: "status",
    label: "Имя загрузившего", // TODO: add header-switcher depending on data - Статус медиаплана or Имя загрузившего
  },
  {
    id: "media",
    label: "Медиаплан",
  },
];
