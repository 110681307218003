import { useState } from "react";

import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";

import classNames from "classnames";
import { Pagination } from "components/UI/Pagination";
import { TObject } from "services/store/reducers/objects/types";
import { useAppSelector } from "services/store/store";

import commonStyles from "../common-styles.module.sass";
import { columns } from "./constants";
import styles from "./styles.module.sass";

export default function ListObjectsTable({ data }: { data: TObject[] }) {
  // Для пагинации
  const [page, setPage] = useState(0);
  const [rowsPerPage] = useState(20);
  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const { role } = useAppSelector((state) => state.globalParams);
  const filteredColumns = columns.filter((column) =>
    column.allow.includes(role),
  );
  return (
    <>
      <Paper className={styles.paper}>
        <TableContainer sx={{ maxHeight: "calc(100vh - 350px)" }}>
          <Table stickyHeader aria-label="sticky table">
            <TableHead>
              <TableRow>
                {filteredColumns.map((column) => (
                  <TableCell
                    padding="none"
                    className={styles.tableHeadCell}
                    key={column.id}
                  >
                    {column.label}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {data
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row) => (
                  <TableRow key={row.id}>
                    {filteredColumns.map((column) => {
                      let value = row[column.id];
                      if (typeof value === "boolean" && value === true) {
                        value = "+";
                      } else if (typeof value === "boolean" && value === false)
                        value = "-";
                      return (
                        <TableCell
                          padding="none"
                          key={column.id}
                          className={classNames(
                            styles.tableBodyCell,
                            column.id === "city" ? "capitalize" : "",
                          )}
                        >
                          {value || "-"}
                        </TableCell>
                      );
                    })}
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
      <Pagination
        total={data.length}
        limit={rowsPerPage}
        currentPage={page}
        onPageChange={handleChangePage}
        className={commonStyles.pagination}
      />
    </>
  );
}
