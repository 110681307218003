// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.styles_button__SUZH5 {
  padding: 0;
  border: none;
  font: inherit;
  color: inherit;
  background-color: transparent;
  cursor: pointer;
}`, "",{"version":3,"sources":["webpack://./src/components/UI/MUIIconButton/styles.module.sass","webpack://./src/common/styles/mixins/button-reset.sass"],"names":[],"mappings":"AAEA;ECDE,UAAA;EACA,YAAA;EACA,aAAA;EACA,cAAA;EACA,6BAAA;EACA,eAAA;ADCF","sourcesContent":["@import '../../../common/styles/mixins/button-reset'\n\n.button\n  @include button-reset\n","@mixin button-reset\n  padding: 0\n  border: none\n  font: inherit\n  color: inherit\n  background-color: transparent\n  cursor: pointer\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"button": `styles_button__SUZH5`
};
export default ___CSS_LOADER_EXPORT___;
