import { handleParams } from "common/utils/handleParams";
import axios, {AxiosResponse} from "axios";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { DetailedPlanUpload, GetRecruitmentPlanParams, GetRecruitmentPlansParams, RecruitmentPlanParams, RecruitmentPlanUpload, Response, getRecruitmentPlanExelResponse } from "./types";
import axiosInstance from "services/store/axiosInstance";

export const postRecruitmentPlan = createAsyncThunk(
  "recruitmentPlan/postRecruitmentPlan",
  async ({params, file}: {params: RecruitmentPlanParams, file: File}, { rejectWithValue }) => {
    try {
      const formData: FormData = new FormData();
      const urlParams = handleParams(params);
      formData.append("file", file);
      const response: AxiosResponse<Response> = await axiosInstance.post(`/recruitment-plan/upload-recruitment-plan${urlParams}`, formData);     
      return response.data;
    } catch (error) {
      return axios.isAxiosError(error) && error.response
       ? rejectWithValue(error.response.data)
        : rejectWithValue(error.message);
    }
  }
)

export const getRecruitmentPlan = createAsyncThunk(
  "recruitmentPlan/getRecruitmentPlan",
  async (params: GetRecruitmentPlanParams, { rejectWithValue }) => {
    try {
      const urlParams = handleParams(params, ["plan_id"]);
      const response: AxiosResponse<DetailedPlanUpload | getRecruitmentPlanExelResponse> = await axiosInstance.get(
        `/recruitment-plan/get-recruitment-plan/${params.plan_id}${urlParams}`
      );
      return response.data;
    } catch (error) {
      return axios.isAxiosError(error) && error.response
        ? rejectWithValue(error.response.data)
        : rejectWithValue(error.message);
    }
  },
);


export const getRecruitmentPlans = createAsyncThunk(
  "recruitmentPlan/getRecruitmentPlans",
  async (params: GetRecruitmentPlansParams, { rejectWithValue }) => {
    try {
      const urlParams = handleParams(params, ["client_id"]);
      const response: AxiosResponse<RecruitmentPlanUpload[]> = await axiosInstance.get(
        `/recruitment-plan/get-all-recruitment-plans/${params.client_id}${urlParams}`
      );
      return response.data;
    } catch (error) {
      return axios.isAxiosError(error) && error.response
        ? rejectWithValue(error.response.data)
        : rejectWithValue(error.message);
    }
  },
);