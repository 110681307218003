import React from "react";

import { Checkbox, ListItemText, MenuItem, Select } from "@mui/material";

export interface Option {
  name: string;
  value: string;
  isSelected: boolean;
}

interface Props {
  options: Option[];
  setSelect: (selectedValue: string, isSelected: boolean) => void;
}

const MultipleSelect: React.FC<Props> = ({ options, setSelect }) => {
  return (
    <Select
      multiple
      value={options.map(({ name }) => name)}
      className="h-10 w-[180px] rounded-md border-[#314660]"
      renderValue={() => (
        <p className="text-xs text-wrap mt-1">
          {options
            .filter(({ isSelected }) => isSelected)
            .map(({ name }) => `${name.slice(0, 3)}.`)
            .join(", ")}
        </p>
      )}
    >
      {options.map((option) => (
        <MenuItem
          key={option.value}
          value={option.value}
          className="w-[180px] !p-2 !pl-0"
        >
          <Checkbox
            checked={option.isSelected}
            onChange={(e) => setSelect(option.value, e.target.checked)}
            className="!my-1"
          />
          <ListItemText secondary={option.name} />
        </MenuItem>
      ))}
    </Select>
  );
};

export default MultipleSelect;
