import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import {
  CustomMediaPlanResponse,
  MediaPlanResponse,
  MediaPlansResponse,
  MediaUpload,
  StateType,
  Region,
  StartMLResponse,
  PublicationPlan
} from "./types";
import { fetchMediaPlans, fetchMediaPlan, fetchMediaPlanMulti, postDuplicateMediaPlanToCustom, postCustomMediaPlan, startLearnMl, startML, getPublicationPlan, getMediaPlansByClusters } from "./actions";

const initialState: StateType = {
  mediaUploadsLoading: false,
  mediaUploadsData: null,
  mediaUploadsError: null,
  mediaPlanUploadLoading: false,
  mediaPlanUploadData: null,
  mediaPlanFilters: null,
  mediaPlanSum: null,
  mediaPlanUploadError: null,
  mediaPlanMultiData: null,
  mediaPlanMultiLoading: false,
  mediaPlanSources: null,
  shouldMediaPlanMultiRefresh: true,
  mediaPlanMultiError: null,
  customMediaPlanData: null,
  customMediaPlanLoading: false,
  customMediaPlanError: null,
  duplicateMediaPlanToCustomLoading: false,
  duplicateMediaPlanToCustomData: null,
  duplicateMediaPlanToCustomError: null,
  startMlLearningLoading: false,
  startMlLearningError: null,
  startMLData: null,
  startMLError: null,
  startMLLoading: false,
  ultimateUploadLoading: false,
  ultimateUploadData: null,
  ultimateUploadError: null,
  publicationPlanData: null,
  publicationPlanError: null,
  publicationPlanLoading: false,
  mediaPlanByClustersData: null,
  mediaPlanByClusterError: null,
  mediaPlanByClusterLoading: false,
};

const mediaPlanSlice = createSlice({
  name: "mediaPlan",
  initialState,
  reducers: {
    setShouldMediaPlanMultiRefresh(state, action: PayloadAction<boolean>) {
      state.shouldMediaPlanMultiRefresh = action.payload;
    },
    clearCustomMediaPlanData(state) {
      state.customMediaPlanData = null;
    },
    clearMediaPlansData(state) {
      state.mediaUploadsData = null;
    }
  },
  extraReducers: (builder) => {
    builder.addCase(getMediaPlansByClusters.pending, state => {
      state.mediaPlanByClusterLoading = true;
      state.mediaPlanByClusterError = null;
    })
    .addCase(getMediaPlansByClusters.fulfilled, (state, action) => {
      state.mediaPlanByClusterLoading = false;
      state.mediaPlanByClustersData = action.payload;
    })
    .addCase(getMediaPlansByClusters.rejected, (state, action) => {
      state.mediaPlanByClusterLoading = false;
      if (typeof action.payload === "string")
        state.mediaPlanByClusterError = action.payload;
    });
    builder
      .addCase(fetchMediaPlans.pending, (state) => {
        state.mediaUploadsLoading = true;
        state.mediaUploadsError = null;
      })
      .addCase(
        fetchMediaPlans.fulfilled,
        (state, action: PayloadAction<MediaUpload[]>) => {
          state.mediaUploadsLoading = false;
          state.mediaUploadsData = action?.payload;
        },
      )
      .addCase(fetchMediaPlans.rejected, (state, action) => {
        state.mediaUploadsLoading = false;
        if (typeof action.payload === "string")
          state.mediaUploadsError = action.payload;
      });

    builder
      .addCase(fetchMediaPlan.pending, (state) => {
        state.mediaPlanUploadLoading = true;
        state.mediaPlanUploadError = null;
      })
      .addCase(
        fetchMediaPlan.fulfilled,
        (state, action: PayloadAction<MediaPlanResponse>) => {
          state.mediaPlanUploadLoading = false;
          state.mediaPlanUploadData = action.payload.media_plan;
          state.mediaPlanFilters = action.payload.filters;
          state.mediaPlanSum = action.payload.sum;
        },
      )
      .addCase(fetchMediaPlan.rejected, (state, action) => {
        state.mediaPlanUploadLoading = false;
        if (typeof action.payload === "string")
          state.mediaPlanUploadError = action.payload;
      });
    builder
      .addCase(postDuplicateMediaPlanToCustom.pending, (state) => {
        state.duplicateMediaPlanToCustomLoading = true;
        state.duplicateMediaPlanToCustomError = null;
      })
      .addCase(
        postDuplicateMediaPlanToCustom.fulfilled,
        (state, action: PayloadAction<CustomMediaPlanResponse>) => {
          state.duplicateMediaPlanToCustomLoading = false;
          state.duplicateMediaPlanToCustomData = action.payload.results;
        },
      )
      .addCase(postDuplicateMediaPlanToCustom.rejected, (state, action) => {
        state.duplicateMediaPlanToCustomLoading = false;
        if (typeof action.payload === "string")
          state.duplicateMediaPlanToCustomError = action.payload;
      });
    builder
      .addCase(fetchMediaPlanMulti.pending, (state) => {
        state.mediaPlanMultiLoading = true;
        state.mediaPlanMultiError = null;
      })
      .addCase(
        fetchMediaPlanMulti.fulfilled,
        (state, action: PayloadAction<MediaPlansResponse>) => {
          state.mediaPlanMultiLoading = false;
          state.mediaPlanMultiData = action.payload.data;
          state.mediaPlanSources = action.payload.sources;
        },
      )
      .addCase(fetchMediaPlanMulti.rejected, (state, action) => {
        state.mediaPlanMultiLoading = false;
        if (typeof action.payload === "string")
          state.mediaPlanMultiError = action.payload;
      });

    builder
      .addCase(postCustomMediaPlan.pending, (state) => {
        state.customMediaPlanLoading = true;
        state.customMediaPlanError = null;
      })
      .addCase(postCustomMediaPlan.fulfilled, (state, action: PayloadAction<Region>) => {
        state.customMediaPlanData = action.payload;
        state.customMediaPlanLoading = false;
      })
      .addCase(postCustomMediaPlan.rejected, (state, action) => {
        state.customMediaPlanLoading = false;
        if (typeof action.payload === "string")
          state.customMediaPlanError = action.payload;
      });

      builder
        .addCase(startLearnMl.pending, (state) => {
          state.startMlLearningLoading = true;
          state.startMlLearningError = null;
        })
        .addCase(startLearnMl.fulfilled, (state) => {
          state.startMlLearningLoading = false;
        })
        .addCase(startLearnMl.rejected, (state, action) => {
          state.startMlLearningLoading = false;
          if (typeof action.payload === "string")
            state.startMlLearningError = action.payload;
        });
      
      builder
        .addCase(startML.pending, state => {
          state.startMLLoading = true;
          state.startMLError = null;
        })
        .addCase(startML.fulfilled, (state, action: PayloadAction<StartMLResponse>) => {
          state.startMLData = action.payload;
          state.startMLLoading = false;
        })
        .addCase(startML.rejected, (state, action) => {
          state.startMLLoading = false;
          if (typeof action.payload === "string")
            state.startMLError = action.payload;
        });

      builder.addCase(getPublicationPlan.pending, state => {
        state.publicationPlanLoading = true;
        state.publicationPlanError = null;
      })
      .addCase(getPublicationPlan.fulfilled, (state, action: PayloadAction<PublicationPlan[]>) => {
        state.publicationPlanLoading = false;
        state.publicationPlanData = action.payload;
      })
      .addCase(getPublicationPlan.rejected, (state, action) => {
        state.publicationPlanLoading = false;
        if (typeof action.payload === "string")
          state.publicationPlanError = action.payload;
      });
  },
});

export const mediaPlanReducer = mediaPlanSlice.reducer;
export const { setShouldMediaPlanMultiRefresh, clearCustomMediaPlanData, clearMediaPlansData } = mediaPlanSlice.actions;
