import { useState } from "react";

import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";

import { Pagination } from "components/UI/Pagination";
import { GetMPByClusters } from "services/store/reducers/media/types";

import commonStyles from "../common-styles.module.sass";
import { columns } from "./const";
import styles from "./styles.module.sass";

export default function MediaPlanClusterTable({
  data,
}: {
  data: GetMPByClusters[];
}) {
  const [page, setPage] = useState(0);
  const [rowsPerPage] = useState(20);
  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  return (
    <>
      <Paper className={styles.paper}>
        <TableContainer sx={{ maxHeight: "calc(100vh - 350px)" }}>
          <Table stickyHeader aria-label="sticky table">
            <TableHead>
              <TableRow>
                {columns.map((column) => (
                  <TableCell
                    padding="none"
                    className={styles.tableHeadCell}
                    key={column.id}
                  >
                    {column.label}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {data
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row, id) => (
                  <TableRow key={id}>
                    {columns.map((column) => (
                      <TableCell
                        padding="none"
                        key={column.id}
                        className={styles.tableBodyCell}
                      >
                        {row[column.id] || "-"}
                      </TableCell>
                    ))}
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
      <Pagination
        total={data.length}
        limit={rowsPerPage}
        currentPage={page}
        onPageChange={handleChangePage}
        className={commonStyles.pagination}
      />
    </>
  );
}
