// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.styles_datePickers__tBdmd {
  display: flex;
  align-items: center;
}

.styles_divider__-IvRc {
  font-size: 1.8rem;
  margin: 0 10px;
}

.styles_confirmation__6Nll0 {
  display: flex;
  justify-content: space-between;
}

.styles_affirmation__1Nq3X {
  display: flex;
  justify-content: center;
  gap: 30px;
  margin-top: 25px;
}`, "",{"version":3,"sources":["webpack://./src/pages/selection/styles.module.sass"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,mBAAA;AACF;;AACA;EACE,iBAAA;EACA,cAAA;AAEF;;AAAA;EACE,aAAA;EACA,8BAAA;AAGF;;AADA;EACE,aAAA;EACA,uBAAA;EACA,SAAA;EACA,gBAAA;AAIF","sourcesContent":[".datePickers\n  display: flex\n  align-items: center\n\n.divider\n  font-size: 1.8rem\n  margin: 0 10px\n\n.confirmation\n  display: flex\n  justify-content: space-between\n\n.affirmation\n  display: flex\n  justify-content: center\n  gap: 30px\n  margin-top: 25px\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"datePickers": `styles_datePickers__tBdmd`,
	"divider": `styles_divider__-IvRc`,
	"confirmation": `styles_confirmation__6Nll0`,
	"affirmation": `styles_affirmation__1Nq3X`
};
export default ___CSS_LOADER_EXPORT___;
