import { useEffect, useRef, EffectCallback } from 'react';

export const useEffectOnce = (
  effect: EffectCallback,
  condition: boolean = true,
) => {
  const hasRun = useRef(false);

  useEffect(() => {
    if (!hasRun.current && condition) {
      hasRun.current = true;
      effect();
    }
  }, [condition, effect]);
};