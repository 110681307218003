import { optionType } from "common/types";

import styles from "./styles.module.sass";

interface AdditionSelectOptions {
  options: optionType[];
  label?: string;
  select: string;
  setSelect: (select: string) => void;
  size?: number;
}

const SizebleSelect: React.FC<AdditionSelectOptions> = ({
  options,
  select,
  setSelect,
  label,
  size,
}): JSX.Element => {
  const handleChange = (e: any) => {
    setSelect(e.target.value);
  };

  return (
    <select
      value={select}
      onChange={handleChange}
      className={styles.select}
      size={size}
    >
      {options.map((option) => (
        <option
          key={option.value}
          value={option.value}
          className={styles.option}
        >
          {option.name}
        </option>
      ))}
    </select>
  );
};

export default SizebleSelect;
