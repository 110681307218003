import { ChannelsFactors } from "./types";

export const CHANNELS = {
  hv: "hh.ru",
  av: "авито",
};

export const ACTIVE_SETTINGS_TABLE_HEADERS = {
  hv: [
    "Стандарт",
    "Стандарт +",
    "Премиум",
    "Кол-во лидов",
    "Стоимость лида",
    "Бюджет",
  ],
  av: [
    "Публикация",
    "Тип Продвижения",
    "Продвижение",
    "Кол-во лидов",
    "Стоимость лида",
    "Бюджет",
  ],
};

export const ACTIVE_SETTINGS_TABLE_DATA = {
  hv: ["input", "input", "input"],
  av: ["input", "х_5","input"],
};

export const CHANNELS_FACTORS_COUNT_OUTPUT_LOGIC: ChannelsFactors = {
  hv: {
    monthly: ["factors_1_count", "factors_2_count", "factors_3_count"],
    weekly: []
  }
    ,
  av: {
    monthly: ["factors_1_count", "factors_3_count"],
    weekly: []
  },
};