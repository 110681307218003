// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap);"]);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.styles_container__I33sr {
  display: flex;
  font-family: Montserrat, Arial, "Helvetica Neue", Helvetica, sans-serif;
  height: 100vh;
}

.styles_main__QPwyR {
  display: flex;
  flex-direction: column;
  width: calc(100% - 247px - 32px);
  overflow: hidden;
  flex-grow: 1;
  margin: 0 32px;
  padding-top: 15px;
}`, "",{"version":3,"sources":["webpack://./src/router/styles.module.sass","webpack://./src/common/styles/variables/font-style.sass"],"names":[],"mappings":"AAGA;EACE,aAAA;EACA,uECJgB;EDKhB,aAAA;AADF;;AAGA;EACE,aAAA;EACA,sBAAA;EACA,gCAAA;EACA,gBAAA;EACA,YAAA;EACA,cAAA;EACA,iBAAA;AAAF","sourcesContent":["@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap')\n@import \"../common/styles/variables/font-style\"\n\n.container\n  display: flex\n  font-family: $font-collection\n  height: 100vh\n\n.main\n  display: flex\n  flex-direction: column\n  width: calc(100% - 247px - 32px) /* remove side bar and margin width */\n  overflow: hidden\n  flex-grow: 1\n  margin: 0 32px\n  padding-top: 15px\n","$error: #c10000\n$font-collection: Montserrat, Arial, \"Helvetica Neue\", Helvetica, sans-serif\n$placeholder: rgba(0, 0, 0, 0.6)\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `styles_container__I33sr`,
	"main": `styles_main__QPwyR`
};
export default ___CSS_LOADER_EXPORT___;
