import { createSlice } from "@reduxjs/toolkit";
import { StateType } from "./types";
import { getHirePlan } from "./actions";

const initialState: StateType = {
  getHirePlanData: null,
  getHirePlanLoading: false,
  getHirePlanError: null,
}

const hirePlanSlicer = createSlice({
  name: 'hirePlan',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getHirePlan.pending, state => {
      state.getHirePlanLoading = true;
      state.getHirePlanError = null;
    })
    .addCase(getHirePlan.fulfilled, (state, action) => {
      state.getHirePlanLoading = false;
      state.getHirePlanData = action.payload;
    })
    .addCase(getHirePlan.rejected, (state, action) => {
      state.getHirePlanLoading = false;
      if (typeof action.payload === "string")
        state.getHirePlanError = action.payload;
    })
  }
})

export default hirePlanSlicer.reducer;