import { Record as RecordT } from "services/store/reducers/media/types";
import { FilteredValue } from "./types";
import { FILTERED_KEYS } from "components/tables/MediaPlanMultiTable/constants";

export function filterData(
  data: (RecordT & { isSelected: boolean })[],
  filters: FilteredValue[],
): (RecordT & { isSelected: boolean })[] {
  return data.map((item) => {
    const isValid = FILTERED_KEYS.every(
      (filterField) =>
        filters
          .find((filter) => filter.key === filterField)
          ?.values.find(
            (value) =>
              value.value ===
              (filterField === "region"
                ? item.data[0].region
                : item[filterField]),
          )?.isSelected,
    );
    return { ...item, isSelected: isValid };
  });
}
