import { useEffect, useMemo } from "react";
import { Controller, FormProvider, useForm } from "react-hook-form";

import classNames from "classnames";
import { createSchema } from "components/tables/PlanUploadsTable/schema";

import { zodResolver } from "@hookform/resolvers/zod";

import CrossIcon from "../../../assets/images/cross-sm.svg";
import { Modal } from "../Modal";
import styles from "./styles.module.sass";

export type FilteredData = {
  value: string;
  isSelected: boolean;
  isKeyShouldBeReplaced?: boolean;
}[];

interface FilterPanelProps {
  isOpen: boolean;
  columnName: string;
  data: FilteredData;
  onClose: () => void;
  onSave: (values: Record<string, boolean>) => void;
}

export default function FilterPanel({
  isOpen,
  columnName,
  data,
  onClose,
  onSave,
}: FilterPanelProps) {
  const defaultValues: Record<string, boolean> = useMemo(
    () =>
      data.reduce((acc, item) => {
        acc[item.value] = item.isSelected ?? false;
        return acc;
      }, {}),
    [data],
  );

  const form = useForm({
    mode: "onChange",
    resolver: zodResolver(createSchema(data.map(({ value }) => value))),
    defaultValues: defaultValues,
  });

  const { control, handleSubmit, reset } = form;

  const resetSelectedItems = () => {
    reset(data.reduce((acc, item) => ({ ...acc, [item.value]: false }), {}));
  };

  const selectAll = () => {
    reset(data.reduce((acc, item) => ({ ...acc, [item.value]: true }), {}));
  };

  const onSubmit = (values: Record<string, boolean>) => {
    onSave(values);
  };

  const onResetFilterValues = () => {
    resetSelectedItems();
    onClose();
  };

  useEffect(() => {
    reset(
      data.reduce((acc, item) => {
        acc[item.value] = item.isSelected ?? false;
        return acc;
      }, {}),
    );
  }, [data, reset]);

  return (
    <Modal
      open={isOpen}
      onClose={onClose}
      classNames="!rounded-[15px] !px-6 !py-3 !w-max"
      closeButtonPosition={{
        top: 12,
        right: 24,
      }}
    >
      <div>
        <h2 className="text-base text-[#0C3953] font-semibold mb-3 mr-6">
          {columnName}
        </h2>
        <div className="mb-[10px] flex items-center">
          <p
            className="text-[11px] text-[#217EC6] mr-5 hover:cursor-pointer"
            onClick={selectAll}
          >
            Выбрать всё
          </p>

          <div
            className="flex items-center hover:cursor-pointer"
            onClick={resetSelectedItems}
          >
            <img src={CrossIcon} alt="Cross Icon" className="mr-[5px]" />
            <span className="text-[11px] text-[#0C3953]">Сбросить</span>
          </div>
        </div>
        <input
          type="text"
          placeholder="Поиск"
          className={classNames(
            "rounded-[10px] py-2 pl-8 text-[11px] text-[#777B89] w-full mb-[9px]",
            styles.search_input,
          )}
        />
        <FormProvider {...form}>
          <form onSubmit={handleSubmit(onSubmit)}>
            <div
              className={classNames(
                "max-h-[50vh] min-w-[190px] pr-[110px]",
                styles.scroll_container,
              )}
            >
              {data.map((item) => {
                return (
                  <Controller
                    key={item.value}
                    control={control}
                    name={item.value}
                    render={({ field: { value = false, ...restField } }) => {
                      return (
                        <div
                          key={item.value}
                          className="flex items-center mb-2"
                        >
                          <label
                            className={classNames(
                              "text-[11px] text-[#0C3953]",
                              styles.checkbox_label,
                            )}
                          >
                            <input
                              id={item.value}
                              type="checkbox"
                              checked={value}
                              {...restField}
                              className={classNames(
                                "border-2 border-black size-4 bg-[#217EC6] mr-2",
                                styles.checkbox,
                              )}
                            />
                            <span></span>
                            {item.isKeyShouldBeReplaced
                              ? item.value.split("_").join(".")
                              : item.value}
                          </label>
                        </div>
                      );
                    }}
                  />
                );
              })}
            </div>
            <div className="flex justify-end gap-[10px] mt-[10px]">
              <button
                className={classNames(styles.button, "bg-white text-[#217EC6]")}
                onClick={onResetFilterValues}
              >
                Отменить
              </button>
              <button
                type="submit"
                className={classNames(styles.button, "bg-[#217EC6] text-white")}
              >
                Сохранить
              </button>
            </div>
          </form>
        </FormProvider>
      </div>
    </Modal>
  );
}
