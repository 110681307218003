// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.no-arrows_noArrows__j74a8 {
  -moz-appearance: textfield;
}
.no-arrows_noArrows__j74a8::-webkit-outer-spin-button, .no-arrows_noArrows__j74a8::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}`, "",{"version":3,"sources":["webpack://./src/common/styles/common-classes/no-arrows.module.sass"],"names":[],"mappings":"AAAA;EACE,0BAAA;AACF;AACE;EAEE,wBAAA;EACA,SAAA;AAAJ","sourcesContent":[".noArrows\n  -moz-appearance: textfield\n\n  &::-webkit-outer-spin-button,\n  &::-webkit-inner-spin-button\n    -webkit-appearance: none\n    margin: 0\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"noArrows": `no-arrows_noArrows__j74a8`
};
export default ___CSS_LOADER_EXPORT___;
