// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.styles_optionsBox__cMA-Y {
  display: flex;
  gap: 10px;
  margin-top: 16px;
  margin-bottom: 20px;
}

.styles_openBtn__UyejT {
  margin: 33px 0 0 auto;
}

.styles_roundedModal__PDjBx {
  max-width: 288px;
  padding: 20px 24px !important;
  border-radius: 16px;
}`, "",{"version":3,"sources":["webpack://./src/pages/media-plan-choose/styles.module.sass"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,SAAA;EACA,gBAAA;EACA,mBAAA;AACF;;AACA;EACE,qBAAA;AAEF;;AAAA;EACE,gBAAA;EACA,6BAAA;EACA,mBAAA;AAGF","sourcesContent":[".optionsBox\n  display: flex\n  gap: 10px\n  margin-top: 16px\n  margin-bottom: 20px\n\n.openBtn\n  margin: 33px 0 0 auto\n\n.roundedModal\n  max-width: 288px\n  padding: 20px 24px !important\n  border-radius: 16px\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"optionsBox": `styles_optionsBox__cMA-Y`,
	"openBtn": `styles_openBtn__UyejT`,
	"roundedModal": `styles_roundedModal__PDjBx`
};
export default ___CSS_LOADER_EXPORT___;
