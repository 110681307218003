import { createSelector } from "reselect";

import { RootState } from "./reducers/rootReducer";

const selectMediaPlan = (state: RootState) => state.mediaplan;
const selectRequirementPlan = (state: RootState) => state.recr;

const selectMediaPlanProfiles = createSelector(
  [selectMediaPlan],
  (mediaplan) => [
    ...new Set(mediaplan.mediaPlanUploadData?.map((row) => row.profile)),
  ],
);

const selectMediaPlanCities = createSelector([selectMediaPlan], (mediaplan) => [
  ...new Set(mediaplan.mediaPlanUploadData?.map((row) => row.city)),
]);

const selectMediaPlanRegions = createSelector(
  [selectMediaPlan],
  (mediaplan) => [
    ...new Set(mediaplan.mediaPlanUploadData?.map((row) => row.region)),
  ],
);

const selectMediaPlanSources = createSelector(
  [selectMediaPlan],
  (mediaplan) => [
    ...new Set(mediaplan.mediaPlanUploadData?.map((row) => row.source)),
  ],
);

const selectMediaPlanSourceTypes = createSelector(
  [selectMediaPlan],
  (mediaplan) => [
    ...new Set(mediaplan.mediaPlanUploadData?.map((row) => row.source_type)),
  ],
);

const selectSortedMediaPlans = createSelector(
  [selectMediaPlan],
  (mediaplan) => [
    ...(mediaplan.mediaUploadsData ? [...mediaplan.mediaUploadsData].sort((a, b) => b.id - a.id) : [])
  ],
)

const selectSortedRequirementPlans = createSelector(
  [selectRequirementPlan],
  (reqrPlan) => [
    ...(reqrPlan.getRecruitmentPlansData ? [...reqrPlan.getRecruitmentPlansData].sort((a, b) => b.id - a.id): [])
  ],
)

export {
  selectMediaPlanProfiles,
  selectMediaPlanCities,
  selectMediaPlanRegions,
  selectMediaPlanSources,
  selectMediaPlanSourceTypes,
  selectSortedMediaPlans,
  selectSortedRequirementPlans
};
