import { ChannelsFactors } from "./types";
import { Record } from "services/store/reducers/media/types";

export function getFactors(plan: Record, sources: string[]): ChannelsFactors {
  const result: ChannelsFactors = sources.reduce((acc, key) => {
    acc[key] = {
      weekly: [],
      monthly: [],
    };
    return acc;
  }, {});
  sources.forEach((key) => {
    plan.data.forEach((innerEntity) => {
      if (innerEntity.source_code === key) {
        result[key].weekly[0] =
          `${+(result[key].weekly[0] ?? 0) + (innerEntity.weekly_plan?.factors_1_count ?? 0)}`;
        result[key].monthly[0] =
          `${+(result[key].monthly[0] ?? 0) + innerEntity.factors_1_count ?? 0}`;
        result[key].weekly[1] =
          `${+(result[key].weekly[1] ?? 0) + (innerEntity.weekly_plan?.factors_2_count ?? 0)}`;
        result[key].monthly[1] =
          `${+(result[key].monthly[1] ?? 0) + innerEntity.factors_2_count ?? 0}`;
        result[key].weekly[2] =
          `${+(result[key].weekly[2] ?? 0) + (innerEntity.weekly_plan?.factors_3_count ?? 0)}`;
        result[key].monthly[2] =
          `${+(result[key].monthly[2] ?? 0) + innerEntity.factors_3_count ?? 0}`;
      }
    });
  });
  return result;
}

export function getWeeklyColumn(
  column: "callbacks_count" | "budget" | "avg_leed_price",
  plan: Record,
  currentChannel: string,
): number {
  const item = plan.data.find((item) => item.source_code === currentChannel);
  if (item?.weekly_plan) {
    return item.weekly_plan[column];
  } else return 0;
}

export function getMonthlyColumn(
  column: "callbacks_count" | "budgets" | "lead_cost",
  plan: Record,
  currentChannel: string,
): number {
  const item = plan.data.find((item) => item.source_code === currentChannel);
  if (item) {
    return item[column];
  } else return 0;
}