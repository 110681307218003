import { Caption } from "common/const";
import { capitalizeFirstLetter, formatCityName } from "common/utils/formatText";
import { handleDate } from "common/utils/handleDate";
import { roundToTwoDecimalPlaces } from "common/utils/roundToTwoDecimalPlaces";

export function handleCell(
  value: string | number | null,
  column: string,
): string {
  let result = value;

  if (typeof value === "number" && !Number.isInteger(value))
    result = roundToTwoDecimalPlaces(value).toString();

  if (
    (column === "date_start" || column === "date_end") &&
    typeof value === "string"
  )
    result = handleDate(value, false, true);

  if (column === "region" && typeof result === "string")
    result = capitalizeFirstLetter(result);

  if (column === "city" && typeof result === "string")
    result = formatCityName(result);

  return result?.toString() ?? "";
}

const getLimitNumber = (number: number, limits: Caption[]): number =>
  limits.slice(0, number + 1).reduce((sum, cur) => sum + cur.unions, 0);

function isInArea(
  columnIndex: number,
  startLimitIndex: number,
  endLimitIndex: number,
  limits?: Caption[],
): boolean {
  if (!limits) return false;
  const columnNumber = columnIndex + 1;
  const startLimit = getLimitNumber(startLimitIndex, limits);
  const endLimit = getLimitNumber(endLimitIndex, limits);
  return columnNumber > startLimit && columnNumber <= endLimit;
}

export function isGrayCell(columnIndex: number, limits?: Caption[]): boolean {
  return isInArea(columnIndex, 0, 1, limits);
}

export function isGrayerCell(columnIndex: number, limits?: Caption[]): boolean {
  return isInArea(columnIndex, 1, 2, limits);
}
