import React from "react";

import classNames from "classnames/dedupe";

import "./styles.scss";

import { Loader } from "../icons/Loader";

interface Props extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  variant?: Variant;
  icon?: React.ReactNode;
  className?: string;
  block?: boolean;
  size?: "small" | "normal";
  loading?: boolean;
  rightIcon?: React.ReactNode;
}

type Variant = "default" | "primary" | "upload" | "download" | "delete";

const rootClassName = "ui-button";

const loaderColorByVariant: Record<Variant, string> = {
  default: "#fff",
  primary: "#217EC6",
  upload: "#0C3953",
  download: "#0057AC",
  delete: "#d32f2f",
};

export const Button: React.FC<Props> = ({
  variant = "default",
  size = "normal",
  className,
  children,
  block,
  icon,
  disabled,
  loading,
  rightIcon,
  ...props
}) => {
  const buttonClassNames = classNames(className, rootClassName, {
    [`${rootClassName}--${variant}`]: true,
    [`${rootClassName}--empty`]: !Boolean(children),
    [`${rootClassName}--block`]: block,
    [`${rootClassName}--disabled`]: disabled,
    [`${rootClassName}--loading`]: loading,
    [`${rootClassName}--${size}`]: size,
  });

  return (
    <button className={buttonClassNames} {...props}>
      {icon && !loading && <span className="ui-button-icon">{icon}</span>}
      {loading && (
        <span className="ui-button-icon ui-button-icon--spinner">
          <Loader color={loaderColorByVariant[variant]} />
        </span>
      )}
      {children && <span className="ui-button-text">{children}</span>}
      {rightIcon && <span className="ui-button-icon-right">{rightIcon}</span>}
    </button>
  );
};
