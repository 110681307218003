import { createAsyncThunk } from "@reduxjs/toolkit";
import axios, { AxiosResponse } from "axios";
import CompareDate from "common/utils/compareDate";
import { handleParams } from "common/utils/handleParams";
import { FetchCandidatesDataParams, CandidatesData, PostCandidatesDataProps } from "./types";
import axiosInstance from "services/store/axiosInstance";

export const fetchCandidatesData = createAsyncThunk(
  "issued/fetchCandidatesData",
  async (params: FetchCandidatesDataParams, { rejectWithValue }) => {
    try {
      const urlParams = handleParams(params, ["client_id"]);
      const response: AxiosResponse<CandidatesData[]> = await axiosInstance.get(
        `/candidates/get-candidates/${params.client_id}/${urlParams}`,
      );
      const data: CandidatesData[] = (response.data).sort(CompareDate);
      return data;
    } catch (error) {
      return axios.isAxiosError(error) && error.response
        ? rejectWithValue(error.response.data)
        : rejectWithValue(error.message);
    }
  },
);

export const postCandidatesData = createAsyncThunk(
  "recruitmentPlan/postRecruitmentPlan",
  async ({params, file}: {params: PostCandidatesDataProps, file: File}, { rejectWithValue }) => {
    try {
      const formData: FormData = new FormData();
      const urlParams = handleParams(params);
      formData.append("file", file);
      const response: AxiosResponse<Response> = await axiosInstance.post(`/candidates/upload-candidates${urlParams}`, formData);     
      return response.data;
    } catch (error) {
      return axios.isAxiosError(error) && error.response
       ? rejectWithValue(error.response.data)
        : rejectWithValue(error.message);
    }
  }
)