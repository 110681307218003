// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.styles_select__xrtsR {
  color: #fff !important;
}
.styles_select__xrtsR fieldset {
  border-color: #fff !important;
}`, "",{"version":3,"sources":["webpack://./src/components/SideBar/selectClient/styles.module.sass"],"names":[],"mappings":"AAAA;EACE,sBAAA;AACF;AAAE;EACE,6BAAA;AAEJ","sourcesContent":[".select\n  color: #fff !important\n  & fieldset\n    border-color: #fff !important\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"select": `styles_select__xrtsR`
};
export default ___CSS_LOADER_EXPORT___;
