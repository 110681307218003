import { useEffect, useRef, useState } from "react";

import { Box, Modal as MUIModal, Typography } from "@mui/material";

import classNames from "classnames";
import {
  EXCEL_ACCEPT_FORMAT,
  HV_AV_DATA_COLUMNS,
  HV_AV_FILTERS,
  SOURCE_OPTIONS,
} from "common/const";
import { useModal } from "common/hooks/useModal";
import useNotify from "common/hooks/useNotify";
import useSetMessages from "common/hooks/useSetMessages";
import { useUploadHandler } from "common/hooks/useUploadHandler";
import { UploadMessage } from "common/interfaces";
import { addOptionalParams } from "common/utils/addOptionalParams";
import { handleDate } from "common/utils/handleDate";
import { Filters, FilterValues } from "components/Filters";
import ErrorTable from "components/tables/ErrorTable";
import HvAvDataTable from "components/tables/HvAvDataTable";
import { H1, H2 } from "components/typography";
import { Button } from "components/UI/Button";
import DatePicker from "components/UI/DatePicker";
import { Loader } from "components/UI/Loader";
import { Message } from "components/UI/Message";
import { Modal } from "components/UI/Modal";
import BasicSelect from "components/UI/Select";
import Upload from "components/UI/Upload";
import { isSourceType } from "pages/settings/typeguards";
import { clearUploadClientsProfilesData } from "services/store/reducers/mapping";
import {
  getClientsProfiles,
  uploadClientsProfiles,
} from "services/store/reducers/mapping/actions";
import { clearData } from "services/store/reducers/ws-data";
import {
  deleteHvAvData,
  fetchHvAvData,
  postHvAvData,
} from "services/store/reducers/ws-data/actions";
import {
  FetchHvAvDataParams,
  SourceType,
} from "services/store/reducers/ws-data/types";
import { useAppDispatch, useAppSelector } from "services/store/store";
import useSessionStorageState from "use-session-storage-state";

import commonStyles from "../common-styles.module.sass";
import { transformErrors } from "./helpers";
import styles from "./styles.module.sass";

const WSDataPage: React.FC = (): JSX.Element => {
  const { uploaderName, clientId } = useAppSelector(
    (state) => state.globalParams,
  );
  const { hvAvData, hvAvPost } = useAppSelector((state) => state.wsdata);
  const {
    getClientsProfilesData,
    getClientsProfilesLoading,
    uploadClientsProfilesData,
    uploadClientsProfilesLoading,
    uploadClientsProfilesError,
  } = useAppSelector((state) => state.mapping);
  const dispatch = useAppDispatch();
  const [isLoadModalOpen, setIsLoadModalOpen] = useState(false);
  const [isUploadModalOpen, setIsUploadModalOpen] = useState(false);
  const [select, setSelect] = useState("");
  const [startDate, setStartDate] = useState<Date | null>(null);
  const [endDate, setEndDate] = useState<Date | null>(null);
  const [sourceType, setSourceType] = useState<SourceType>("hv");
  const [deleteNumber, setDeleteNumber] = useState<number | null>(null);
  const [errors, setErrors] = useState<string[]>();
  const [isTableModalOpen, setIsTableModalOpen] = useState<boolean>(false);
  const [listErrors, setListErrors] = useState<string[] | []>([]);

  const [uploadMessage, setUploadMessage] =
    useSessionStorageState<UploadMessage | null>("upload-message-wsdata");
  const [uploadMessageLink, setUploadMessageLink] = useSessionStorageState<
    string | null
  >("upload-message-wsdata-link");
  const { fileName, file, handleUpload, handleClearUpload } =
    useUploadHandler();
  const { ToastContainer, notifyUploading } = useNotify();
  const { isOpen, openModal, closeModal } = useModal();
  const uploadRef = useRef<HTMLInputElement>(null);

  const handleSelect = (newValue: string): void => {
    setSelect(newValue);
  };
  const handleStartDateSelect = (newValue: Date | null): void => {
    setStartDate(newValue);
  };
  const handleEndDateSelect = (newValue: Date | null): void => {
    setEndDate(newValue);
  };
  const handleButtonClick = (): void => {
    if (uploadRef.current) uploadRef.current.click();
  };
  const handleConfirmation = (id: number): void => {
    setDeleteNumber(id);
    openModal();
  };
  const handleDelete = (): void => {
    closeModal();
    if (deleteNumber !== null)
      dispatch(
        deleteHvAvData({
          source_id: deleteNumber,
          source_type: sourceType,
        }),
      );
  };

  const resetStates = (): void => {
    setUploadMessage(null);
    setUploadMessageLink(null);
    handleClearUpload();
  };

  const handleClearStates = (): void => {
    setUploadMessage(null);
    setUploadMessageLink(null);
  };

  const handleCloseUploadModal = () => {
    setIsUploadModalOpen(false);
    handleClearUpload();
  };

  const handleCloseLoadModal = () => {
    setIsLoadModalOpen(false);
    handleClearUpload();
  };

  const handleSaveClick = (): void => {
    if (!hvAvPost.loading && file && select && startDate && endDate) {
      resetStates();
      const params = {
        client_id: clientId,
        uploader_name: uploaderName,
        date_from: handleDate(startDate),
        date_to: handleDate(endDate),
      };
      dispatch(
        postHvAvData({ params: params, file: file, source_code: select }),
      );
    }
  };

  const handleUploadSaveClick = (): void => {
    if (!uploadClientsProfilesLoading && file) {
      resetStates();
      const params = {
        client_id: clientId,
        uploader_name: uploaderName,
      };
      dispatch(uploadClientsProfiles({ params: params, file: file }));
    }
  };

  const handleUpdateFilters = (filterValues: FilterValues) => {
    if (!isSourceType(filterValues.source_type)) {
      setErrors([HV_AV_FILTERS.errorsPhrases.source_type]);
      return;
    }

    const sendParams: FetchHvAvDataParams = {
      client_id: clientId,
      source_type: filterValues.source_type,
    };
    const optionalFields: FilterValues = {
      date_from: filterValues.date_from,
      date_to: filterValues.date_to,
      uploaded_from: filterValues.uploaded_from,
      uploaded_to: filterValues.uploaded_to,
      profile: filterValues.profile,
      region: filterValues.region,
    };
    addOptionalParams(sendParams, optionalFields);

    dispatch(fetchHvAvData(sendParams));
  };

  useEffect(() => {
    dispatch(
      fetchHvAvData({
        client_id: clientId,
        source_type: "hv",
      }),
    );

    return () => {
      dispatch(clearData());
      dispatch(clearUploadClientsProfilesData());
    };
  }, [dispatch, clientId]);

  useEffect(() => {
    if (hvAvPost.data && hvAvPost.data.response?.error) {
      setListErrors(transformErrors(hvAvPost.data.response.error));
      setIsLoadModalOpen(false);
      setIsTableModalOpen(true);
      dispatch(getClientsProfiles(clientId));
    }
  }, [hvAvPost.data, dispatch]);

  useSetMessages({
    data: hvAvPost.data,
    notifyUploading,
    setUploadMessage,
    excelResponse: true,
    error: hvAvPost.error,
  });

  useSetMessages({
    data: uploadClientsProfilesData,
    notifyUploading,
    setUploadMessage,
    excelResponse: true,
    error: uploadClientsProfilesError,
  });

  return (
    <>
      <section className={classNames(commonStyles.heading)}>
        <H1 color="#0C3953">Данные работных сайтов</H1>
        <Filters
          onUpdateFilters={handleUpdateFilters}
          configuration={HV_AV_FILTERS}
          errors={errors}
          setSourceType={setSourceType}
        />
      </section>
      <section className={classNames(styles.upload)}>
        <div className="flex gap-2">
          <Button variant="download" onClick={() => setIsLoadModalOpen(true)}>
            Загрузка
          </Button>
          <Button variant="download" onClick={() => setIsUploadModalOpen(true)}>
            Загрузить маппинг
          </Button>
        </div>
        {uploadMessage && (
          <Message
            text={uploadMessage.message}
            success={uploadMessage.success}
            link={uploadMessageLink}
            close={handleClearStates}
          />
        )}
      </section>
      <section>
        {hvAvData.loading ? (
          <Loader />
        ) : (
          <HvAvDataTable
            data={hvAvData.data}
            onDeletion={handleConfirmation}
            columns={HV_AV_DATA_COLUMNS}
          />
        )}
        <Modal open={isOpen} onClose={closeModal} classNames="rounded-lg">
          <>
            <H2>Вы действительно хотите удалить запись №{deleteNumber}?</H2>
            <div className={styles.affirmation}>
              <Button variant="delete" onClick={handleDelete}>
                Да
              </Button>
              <Button variant="download" onClick={closeModal}>
                Нет
              </Button>
            </div>
          </>
        </Modal>
      </section>
      <MUIModal open={isLoadModalOpen} onClose={handleCloseLoadModal}>
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            transform: "translate(-50%, -50%)",
            width: 400,
            bgcolor: "background.paper",
            border: "2px solid #000",
            boxShadow: 24,
            p: 4,
            borderRadius: 5,
          }}
        >
          <Typography variant="h6" component="h2" sx={{ marginBottom: 2 }}>
            Загрузка
          </Typography>
          <>
            <BasicSelect
              label="Работный сайт"
              options={SOURCE_OPTIONS}
              variant="standard"
              select={select}
              setSelect={handleSelect}
              className="mb-8"
            />
            <div className={styles.datePickers}>
              <DatePicker value={startDate} setValue={handleStartDateSelect} />
              <span className={styles.divider}>–</span>
              <DatePicker value={endDate} setValue={handleEndDateSelect} />
            </div>
            <div className={styles.confirmation}>
              <Upload
                forwardRef={uploadRef}
                id="upload"
                onUpload={handleUpload}
                accept={EXCEL_ACCEPT_FORMAT}
                label={fileName}
              >
                <Button
                  variant="upload"
                  loading={hvAvPost.loading}
                  disabled={hvAvPost.loading}
                  onClick={handleButtonClick}
                >
                  Выберите файл
                </Button>
              </Upload>
            </div>
          </>
          <div className="flex flex-row w-full mt-10 justify-between px-6">
            <Button
              variant="primary"
              onClick={handleSaveClick}
              loading={hvAvPost.loading}
              disabled={hvAvPost.loading}
            >
              Сохранить
            </Button>
            <Button variant="download" onClick={handleCloseLoadModal}>
              Закрыть
            </Button>
          </div>
        </Box>
      </MUIModal>
      <MUIModal open={isUploadModalOpen} onClose={handleCloseUploadModal}>
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            transform: "translate(-50%, -50%)",
            width: 400,
            bgcolor: "background.paper",
            border: "2px solid #000",
            boxShadow: 24,
            p: 4,
            borderRadius: 5,
          }}
        >
          <Typography variant="h6" component="h2" sx={{ marginBottom: 2 }}>
            Загрузка маппинга
          </Typography>
          <div className={styles.confirmation}>
            <Upload
              forwardRef={uploadRef}
              id="upload"
              onUpload={handleUpload}
              accept={EXCEL_ACCEPT_FORMAT}
              label={fileName}
            >
              <Button
                variant="upload"
                loading={uploadClientsProfilesLoading}
                disabled={uploadClientsProfilesLoading}
                onClick={handleButtonClick}
              >
                Выберите файл
              </Button>
            </Upload>
          </div>
          <div className="flex flex-row w-full mt-10 justify-between px-6">
            <Button
              variant="primary"
              onClick={handleUploadSaveClick}
              loading={uploadClientsProfilesLoading}
              disabled={uploadClientsProfilesLoading}
            >
              Сохранить
            </Button>
            <Button variant="download" onClick={handleCloseUploadModal}>
              Закрыть
            </Button>
          </div>
        </Box>
      </MUIModal>
      <Modal
        open={isTableModalOpen}
        onClose={() => setIsTableModalOpen(false)}
        classNames="rounded-lg"
      >
        <>
          <H2 className="!text-center mb-4">Таблица ошибки в маппинге</H2>
          <section>
            {getClientsProfilesLoading ? (
              <Loader />
            ) : (
              getClientsProfilesData && (
                <ErrorTable
                  data={listErrors}
                  profiles={getClientsProfilesData}
                />
              )
            )}
          </section>
        </>
      </Modal>
      <ToastContainer />
    </>
  );
};

export default WSDataPage;
