import { MessageResponse, Rates } from "services/store/reducers/settings/types";

export function catchCollisions(
  settings: Rates,
  message: MessageResponse
): number | -1 {
  if (
    message.response.error &&
    "id" in settings &&
    typeof settings.id === "number"
  )
    return settings.id;
  return -1;
}
