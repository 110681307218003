// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.styles_slider__BqF1\\+ {
  display: flex;
  gap: 20px;
}`, "",{"version":3,"sources":["webpack://./src/components/Filters/InputSlider/styles.module.sass"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,SAAA;AACF","sourcesContent":[".slider\n  display: flex\n  gap: 20px\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"slider": `styles_slider__BqF1+`
};
export default ___CSS_LOADER_EXPORT___;
