import { NavLink } from "react-router-dom";

import classNames from "classnames";

import styles from "./styles.module.sass";

interface Props {
  isFullLogo: boolean;
}

const Logo: React.FC<Props> = ({ isFullLogo }): JSX.Element => {
  return (
    <div
      className={classNames(styles.logo, !isFullLogo && styles.closed_logo_sm)}
    >
      <NavLink to="/">
        <img
          src={isFullLogo ? "/HR-Rocket-logo.svg" : "/HR-Rocket-logo-sm.svg"}
          alt="logo"
          className={isFullLogo ? styles.full_logo : styles.logo_sm}
        />
      </NavLink>
    </div>
  );
};

export default Logo;
