import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { Response, SendUploadConfigParams, StateType } from "./types";
import { postUploadConfig } from "./actions";

const initialState: StateType = {
  uploadConfigLoading: false,
  uploadConfigData: null,
  uploadConfigError: null,
  uploadConfigParams: null,
}

const configSlice = createSlice({
  name: 'config',
  initialState,
  reducers: {
    setConfigData(state, action: PayloadAction<Omit<SendUploadConfigParams,'recruitment_plan_id'>>) {
      state.uploadConfigParams = action.payload;
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(postUploadConfig.pending, (state) => {
        state.uploadConfigLoading = true;
        state.uploadConfigError = null;
      })
      .addCase(
        postUploadConfig.fulfilled,
        (state, action: PayloadAction<Response>) => {
          state.uploadConfigLoading = false;
          state.uploadConfigData = action.payload;
          state.uploadConfigParams = null;
        },
      )
      .addCase(postUploadConfig.rejected, (state, action) => {
        state.uploadConfigLoading = false;
        if (typeof action.payload === "string")
          state.uploadConfigError = action.payload;
      });
  }
})

export const { setConfigData } = configSlice.actions;

export default configSlice.reducer