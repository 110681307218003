// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.styles_intro__jqJ\\+E {
  width: 100%;
  max-width: 380px;
  display: flex;
  flex-direction: column;
  gap: 20px;
}`, "",{"version":3,"sources":["webpack://./src/pages/main/styles.module.sass"],"names":[],"mappings":"AAAA;EACE,WAAA;EACA,gBAAA;EACA,aAAA;EACA,sBAAA;EACA,SAAA;AACF","sourcesContent":[".intro\n  width: 100%\n  max-width: 380px\n  display: flex\n  flex-direction: column\n  gap: 20px\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"intro": `styles_intro__jqJ+E`
};
export default ___CSS_LOADER_EXPORT___;
