import { useState } from "react";

import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";

import { Caption, MediaPlanColumn, PlanUploadColumn } from "common/const";
import { Pagination } from "components/UI/Pagination";
import { MediaPlanUpload } from "services/store/reducers/media/types";
import { PlanLine } from "services/store/reducers/recruitment/types";

import commonStyles from "../common-styles.module.sass";
import { handleCell, isGrayCell, isGrayerCell } from "./helpers";
import styles from "./styles.module.sass";

interface Props {
  data: MediaPlanUpload[] | PlanLine[];
  columns: readonly MediaPlanColumn[] | readonly PlanUploadColumn[];
  captions?: Caption[];
  perPage?: number;
}

export default function MediaPlanUploadTable({
  data,
  perPage,
  columns,
  captions,
}: Props) {
  const [page, setPage] = useState(0);
  const [rowsPerPage] = useState(perPage ?? 30);

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  return (
    <>
      <Paper className={commonStyles.table}>
        <TableContainer sx={{ maxHeight: "calc(100vh - 300px)" }}>
          <Table stickyHeader>
            <TableHead>
              {captions && (
                <TableRow>
                  {captions.map((column, index) => (
                    <TableCell
                      colSpan={column.unions}
                      className={`${commonStyles.tableHead} ${styles.caption} ${index % 3 === 1 ? styles.gray : index % 3 === 2 ? styles.grayer : ""}`}
                      key={index}
                    >
                      {column.label}
                    </TableCell>
                  ))}
                </TableRow>
              )}
              <TableRow>
                {columns.map(
                  (
                    column: MediaPlanColumn | PlanUploadColumn,
                    index: number,
                  ) => {
                    const headStyles = [
                      commonStyles.tableHead,
                      styles.tableHead,
                      captions ? styles.captionIndent : "",
                    ];
                    if (isGrayCell(index, captions))
                      headStyles.push(styles.gray);
                    else if (isGrayerCell(index, captions))
                      headStyles.push(styles.grayer);
                    return (
                      <TableCell className={headStyles.join(" ")} key={index}>
                        {column.label}
                      </TableCell>
                    );
                  },
                )}
              </TableRow>
            </TableHead>
            <TableBody>
              {data
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row: MediaPlanUpload | PlanLine, id: number) => {
                  return (
                    <TableRow
                      className={styles.tableRow}
                      role="checkbox"
                      tabIndex={-1}
                      key={id}
                    >
                      {columns.map(
                        (
                          column: MediaPlanColumn | PlanUploadColumn,
                          index: number,
                        ) => {
                          let value: string | number | null = handleCell(
                            row[column.id],
                            column.id,
                          );
                          const cellStyles = [
                            commonStyles.dataCell,
                            styles.dataCell,
                          ];
                          if (isGrayCell(index, captions))
                            cellStyles.push(styles.gray);
                          else if (isGrayerCell(index, captions))
                            cellStyles.push(styles.grayer);
                          return (
                            <TableCell
                              className={cellStyles.join(" ")}
                              key={column.id}
                            >
                              {value}
                            </TableCell>
                          );
                        },
                      )}
                    </TableRow>
                  );
                })}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
      <Pagination
        total={data.length}
        limit={rowsPerPage}
        currentPage={page}
        onPageChange={handleChangePage}
        className={commonStyles.pagination}
      />
    </>
  );
}
