import { createAsyncThunk } from "@reduxjs/toolkit";
import { FetchMediaPlanParams, FetchMediaUploadsParams, GetPublicationPlanParams, LearnMLParams, MediaPlanResponse, MediaPlansResponse, MediaUpload, PublicationPlan, Region, SendCustomMediaPlanParams, SendUltimateUploadParams, UpdateMediaPlanToCustomParams, getMPByClustersParams, startMLParams } from "./types";
import { handleParams } from "common/utils/handleParams";
import axios, { AxiosResponse } from "axios";
import { PUBLISH_API_URL } from "api/endpoint";
import axiosInstance from "services/store/axiosInstance";

export const fetchMediaPlans = createAsyncThunk(
  "media/fetchMediaPlans",
  async (params: FetchMediaUploadsParams, { rejectWithValue }) => {
    try {
      const urlParams = handleParams(params);
      const response: AxiosResponse<MediaUpload[]> = await axiosInstance.get(
        `/ml/list-ml-outputs${urlParams}`, {
        }
      );
      if (response.error) {
        return []
      } else return response.data;
    } catch (error) {
      return axios.isAxiosError(error) && error.response
        ? rejectWithValue(error.response.data)
        : rejectWithValue(error.message);
    }
  },
);

export const fetchMediaPlan = createAsyncThunk(
  "media/fetchMediaPlan",
  async (params: FetchMediaPlanParams, { rejectWithValue }) => {
    try {
      const urlParams = handleParams(params, ["upload"]);
      const response: AxiosResponse<MediaPlanResponse> = await axiosInstance.get(
        `/media-plan/get-clients-media-plan/${params.upload}${urlParams}`,

      );
      return response.data;
    } catch (error) {
      return axios.isAxiosError(error) && error.response
        ? rejectWithValue(error.response.data)
        : rejectWithValue(error.message);
    }
  },
);

export const fetchMediaPlanMulti = createAsyncThunk(
  "media/fetchMediaPlanMulti",
  async (upload_id: number, { rejectWithValue }) => {
    try {
      const response: AxiosResponse<MediaPlansResponse> = await axiosInstance.get(
        `/ml/ml-output?upload_id=${upload_id}`
      );
      return response.data;
    } catch (error) {
      return axios.isAxiosError(error) && error.response
        ? rejectWithValue(error.response.data)
        : rejectWithValue(error.message);
    }
  },
);

export const postDuplicateMediaPlanToCustom = createAsyncThunk(
  "media/postDuplicateMediaPlanToCustom",
  async (params: UpdateMediaPlanToCustomParams, { rejectWithValue }) => {
    try {
      const urlParams = handleParams(params);
      const response = await axiosInstance.post(
       `/ml/duplicate-media-plan-to-custom${urlParams}`
      );
      return response.data;
    } catch (error) {
      return axios.isAxiosError(error) && error.response
        ? rejectWithValue(error.response.data)
        : rejectWithValue(error.message);
    }
  },
);

export const postUltimateUpload = createAsyncThunk(
  "media/postUltimateUpload",
  async (params: SendUltimateUploadParams, { rejectWithValue }) => {
    try {
      const urlParams = handleParams(params);
      const response = await axiosInstance.post(
 `/media-plan/ultimate-upload${urlParams}`,
      );
      return response.data;
    } catch (error) {
      return axios.isAxiosError(error) && error.response
        ? rejectWithValue(error.response.data)
        : rejectWithValue(error.message);
    }
  },
);

export const postCustomMediaPlan = createAsyncThunk(
  "media/postCustomMediaPlan",
  async (params: SendCustomMediaPlanParams, { rejectWithValue }) => {
    try {
      const urlParams = handleParams(params);
      const response = await axiosInstance.post<Region>(
`/ml/custom-media-plan${urlParams}`,
    );
      return response.data;
    } catch (error) {
      return axios.isAxiosError(error) && error.response
        ? rejectWithValue(error.response.data)
        : rejectWithValue(error.message);
    }
  },
);

export const startML = createAsyncThunk(
  "media/startML",
  async (params: startMLParams, { rejectWithValue }) => {
    try {
      const urlParams = handleParams(params);
      const response = await axiosInstance.post(
     `/ml/start-ml${urlParams}`
      );
      return response.data;
    } catch (error) {
      return axios.isAxiosError(error) && error.response  
        ? rejectWithValue(error.response.data)
        : rejectWithValue(error.message);
    }
  }
)

export const startLearnMl = createAsyncThunk(
  "media/startLearnMl",
  async (params: LearnMLParams, { rejectWithValue }) => {
    try {
      const urlParams = handleParams(params);
      const response = await axiosInstance.post(
        `/ml/learn-ml${urlParams}`,
      );
      return response.data;
    } catch (error) {
      return axios.isAxiosError(error) && error.response
        ? rejectWithValue(error.response.data)
        : rejectWithValue(error.message);
    }
  }
)

export const getPublicationPlan = createAsyncThunk(
  "media/getPublicationPlan",
  async (params: GetPublicationPlanParams, { rejectWithValue }) => {
    try {
      const urlParams = handleParams(params);
      const response = await axiosInstance.get(
        `${PUBLISH_API_URL}/media_plan/get_process_publications_plan${urlParams}`,
      )
      return response.data as PublicationPlan[];
    } catch (error) {
      return axios.isAxiosError(error) && error.response
       ? rejectWithValue(error.response.data)
        : rejectWithValue(error.message);
    }
  }
)

export const getMediaPlansByClusters = createAsyncThunk(
  "media/getMediaPlansByClusters",
  async (params: getMPByClustersParams, { rejectWithValue }) => {
    try {
      const urlParams = handleParams(params);
      const response = await axiosInstance.get(
        `${PUBLISH_API_URL}/media_plan/get_process_mpclusters${urlParams}`,
      )
      return response.data;
    } catch (error) {
      return axios.isAxiosError(error) && error.response
       ? rejectWithValue(error.response.data)
        : rejectWithValue(error.message);
    }
  }
)