import { Column } from "./types";

export const columns: readonly Column[] = [
  { id: 'id', label: 'id объекта', allow: ['client', 'admin'] },
  { id: 'name', label: 'Название объекта', allow: ['client', 'admin'] },
  { id: 'address', label: 'Адрес объекта', allow: ['client', 'admin'] },
  { id: 'city', label: 'Населённый пункт', allow: ['client', 'admin'] },
  { id: 'lanti', label: 'Широта', allow: ['admin']},
  { id: 'longi', label: 'Долгота', allow: ['admin']},
  { id: 'cluster1', label: 'Кластер №1', allow: ['admin']},
  { id: 'cluster2', label: 'Кластер №2', allow: ['admin']},
  { id: 'cluster3', label: 'Кластер №3', allow: ['admin']},
  { id: 'require_clusterisation', label: 'Кластеризация', allow: ['admin']},
]