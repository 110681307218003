import { Fragment, useEffect, useState } from "react";

import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";

import classNames from "classnames";
import { Button } from "components/UI/Button";
import RowCheckbox from "components/UI/RowCheckbox";
import { PublicationPlan } from "services/store/reducers/media/types";

import commonStyles from "../common-styles.module.sass";
import { columns } from "./constant";
import { getFactorName } from "./helpers";
import styles from "./styles.module.sass";

interface IPublishPlanTable {
  data: PublicationPlan[];
}

const PublishPlanTable: React.FC<IPublishPlanTable> = ({
  data,
}): JSX.Element => {
  const [rows, setRows] = useState<
    { data: PublicationPlan[]; isSelected: boolean; city: string }[]
  >([]);

  const handleToggleRow = (row: number) => {
    const newRows = [...rows];
    newRows[row].isSelected = !newRows[row].isSelected;
    setRows(newRows);
  };

  const handleToggleAllRows = () => {
    const newRows = [...rows];
    if (newRows.some((row) => row.isSelected)) {
      newRows.forEach((row) => (row.isSelected = false));
    } else {
      newRows.forEach((row) => (row.isSelected = true));
    }
    setRows(newRows);
  };

  useEffect(() => {
    if (data && data.length > 0) {
      const groupedDataByCity = Object.values(
        data.reduce<Record<string, typeof data>>((acc, item) => {
          const { city } = item;
          if (!acc[city]) {
            acc[city] = [];
          }
          acc[city].push(item);
          return acc;
        }, {}),
      ).map((group) => ({
        data: group,
        isSelected: false,
        city: group[0].city,
      }));
      setRows(groupedDataByCity);
    }
  }, [data]);

  return (
    <>
      <div className={styles.control}>
        <Button variant="download">Сохранить в excel</Button>
        <Button variant="primary" onClick={handleToggleAllRows}>
          Свернуть / Развернуть
        </Button>
      </div>
      <Paper className={styles.paper}>
        <TableContainer className={commonStyles.table_container}>
          <Table stickyHeader className="whitespace-nowrap">
            <TableHead className="sticky top-0">
              <TableRow>
                <TableCell className={styles.header__cell}></TableCell>
                {columns.map((column) => (
                  <TableCell className={styles.header__cell} key={column.id}>
                    {column.label}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {rows.map((row, id) => (
                <Fragment key={id}>
                  <TableRow key={id} tabIndex={-1} role="checkbox">
                    <TableCell
                      className={classNames(
                        styles.body__cell,
                        row.isSelected ? styles.body__cell_active : null,
                      )}
                    >
                      {row.data && row.data.length > 0 && (
                        <RowCheckbox
                          oneOpener
                          isSelected={row.isSelected}
                          rowIndex={id}
                          toggleSelection={handleToggleRow}
                        />
                      )}
                    </TableCell>
                    {columns.map((column) => {
                      let value = row[column.id];
                      if (column.id === "demand") {
                        value = row.data.reduce(
                          (acc, row) => acc + row.demand,
                          0,
                        );
                      } else if (column.id === "total_leads") {
                        value = row.data.reduce(
                          (acc, item) => acc + item.total_leads,
                          0,
                        );
                      }
                      return (
                        <TableCell
                          key={column.id}
                          className={classNames(
                            column.id === "city" ? "capitalize" : "",
                            styles.body__cell,
                            row.isSelected ? styles.body__cell_active : null,
                          )}
                        >
                          {value || "-"}
                        </TableCell>
                      );
                    })}
                  </TableRow>
                  {row.isSelected &&
                    row.data?.map((item, activeId) => (
                      <TableRow key={activeId} tabIndex={-1}>
                        <TableCell className={styles.body__cell}></TableCell>
                        {columns.map((column) => {
                          const factorName = getFactorName(item);
                          return (
                            <TableCell
                              className={classNames(styles.body__cell)}
                              key={column.id}
                            >
                              {column.id === "factor"
                                ? factorName
                                : column.id === "city"
                                  ? "-"
                                  : item[column.id]}
                            </TableCell>
                          );
                        })}
                      </TableRow>
                    ))}
                </Fragment>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
    </>
  );
};

export default PublishPlanTable;
