// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.styles_filterManagers__JOPq2 {
  display: flex;
  gap: 15px;
  flex-direction: column;
}

.styles_group__rSGOf {
  display: flex;
  gap: 20px;
}

.styles_textInput__z19pB {
  width: 100%;
  max-width: 265px;
  height: 40px;
  text-transform: capitalize;
}
.styles_textInput__z19pB > input {
  box-sizing: border-box;
  width: 100%;
  max-width: 265px;
  height: 40px;
}`, "",{"version":3,"sources":["webpack://./src/components/Filters/FilterManagers/styles.module.sass"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,SAAA;EACA,sBAAA;AACF;;AACA;EACE,aAAA;EACA,SAAA;AAEF;;AAKA;EAJE,WAAA;EACA,gBAAA;EACA,YAAA;EAIA,0BAAA;AAAF;AAEE;EACE,sBAAA;EATF,WAAA;EACA,gBAAA;EACA,YAAA;AAUF","sourcesContent":[".filterManagers\n  display: flex\n  gap: 15px\n  flex-direction: column\n\n.group\n  display: flex\n  gap: 20px\n\n@mixin input-size\n  width: 100%\n  max-width: 265px\n  height: 40px\n\n.textInput\n  @include input-size\n  text-transform: capitalize\n\n  & > input\n    box-sizing: border-box\n    @include input-size\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"filterManagers": `styles_filterManagers__JOPq2`,
	"group": `styles_group__rSGOf`,
	"textInput": `styles_textInput__z19pB`
};
export default ___CSS_LOADER_EXPORT___;
