import React, { useEffect, useState } from "react";

import classNames from "classnames";
import { FilterConfiguration } from "common/const";
import useDebouncedFunction from "common/hooks/useDebouncedFunction";
import { H2 } from "components/typography";
import { Drawer } from "components/UI/Drawer";
import HorizontalLine from "components/UI/HorizontalLine";
import { MUIIconButton } from "components/UI/MUIIconButton";
import { SourceType } from "services/store/reducers/ws-data/types";

import ErrorMessages from "./ErrorMessages";
import { FilterManagers } from "./FilterManagers";
import styles from "./styles.module.sass";

interface Props extends React.HTMLAttributes<HTMLSpanElement> {
  onUpdateFilters: (filterValues: FilterValues) => void;
  configuration: FilterConfiguration;
  apiFilters?: { [key: string]: number | string | null } | null;
  errors?: string[];
  setSourceType?: (value: SourceType) => void;
}

export type FilterValues = {
  [key: string]: number | string;
};

export const Filters: React.FC<Props> = ({
  apiFilters,
  onUpdateFilters,
  configuration,
  errors = [],
  className,
  children,
  setSourceType,
  ...props
}) => {
  const [filtersOpen, setFiltersOpen] = useState(false);
  const [filterValues, setFilterValues] = useState<FilterValues>(
    configuration.defaults,
  );
  const [sending, setSending] = useState<boolean>(false);

  const debouncedOnUpdateFilters = useDebouncedFunction(onUpdateFilters, 500);

  const combinedClassNames = classNames(styles.filters, className);
  const combinedButtonClassNames = classNames(
    styles.button,
    filtersOpen && styles.activated,
  );

  const toggleFilters = () => setFiltersOpen(!filtersOpen);

  const handleFilterChange = (
    newValue: number | number[] | string,
    filter: string,
  ) => {
    if (typeof newValue === "number" || typeof newValue === "string") {
      setFilterValues((prevValues) => ({
        ...prevValues,
        [filter]: newValue,
      }));
      setSending(true);
    }
  };

  useEffect(() => {
    if (apiFilters) {
      Object.keys(apiFilters).forEach((key) => {
        setFilterValues((prevValues) => ({
          ...prevValues,
          [key]: apiFilters[key] ?? configuration.defaults[key],
        }));
      });
    }
  }, [apiFilters, configuration]);

  useEffect(() => {
    if (sending) {
      setSending(false);
      debouncedOnUpdateFilters(filterValues);
    }
  }, [filterValues, sending, debouncedOnUpdateFilters]);

  return (
    <span className={combinedClassNames} {...props}>
      <MUIIconButton
        className={combinedButtonClassNames}
        onClick={toggleFilters}
        icon={<img src="/filter.svg" alt="filter icon" />}
      />
      <Drawer
        anchor="right"
        isOpen={filtersOpen}
        className={styles.filterPanel}
        setIsOpen={setFiltersOpen}
      >
        <div className={styles.header}>
          <img src="/filter-icon.svg" alt="icon of filter" />
          <H2 color="#262732" className={styles.heading}>
            Фильтр
          </H2>
        </div>
        <HorizontalLine />
        <FilterManagers
          configuration={configuration}
          values={filterValues}
          onFilterChanged={handleFilterChange}
          errors={errors}
        />
        <HorizontalLine />
        {children}
        <ErrorMessages errors={errors} />
      </Drawer>
    </span>
  );
};
