import {
  Dispatch,
  Fragment,
  RefObject,
  SetStateAction,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useNavigate } from "react-router";
import { CSSTransition } from "react-transition-group";

import DownloadIcon from "@mui/icons-material/Download";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";

import classNames from "classnames";
import {
  MediaPlanMultiColumn,
  MediaPlanMultiColumnId,
  OBJECT_LIST_ROUTE,
  Plans,
} from "common/const";
import { useEffectOnce } from "common/hooks/useEffectOnce";
import useNotify from "common/hooks/useNotify";
import { H1 } from "components/typography";
import { Button } from "components/UI/Button";
import FilterButton from "components/UI/FilterButton";
import FilterPanel from "components/UI/FilterPanel";
import { filterData } from "components/UI/FilterPanel/helpers";
import { FilteredValue } from "components/UI/FilterPanel/types";
import { HiddenLink } from "components/UI/HiddenLink";
import { MUIIconButton } from "components/UI/MUIIconButton";
import MultipleSelect, { Option } from "components/UI/MultipleSelect";
import {
  fetchMediaPlanMulti,
  postDuplicateMediaPlanToCustom,
} from "services/store/reducers/media/actions";
import {
  Record as RecordT,
  SpecificPlan,
} from "services/store/reducers/media/types";
import { useAppDispatch, useAppSelector } from "services/store/store";

import commonStyles from "../common-styles.module.sass";
import SettingsRow from "../SettingsRow";
import { FILTERED_KEYS, INITIAL_EMPTY_ROW } from "./constants";
import {
  getLeadBudgetRange,
  getLeadCountRange,
  getRandomNeed,
  handleValue,
  isChosenMoreThanCustom,
  isObjectChanged,
  splitArrayById,
} from "./helpers";
import styles from "./styles.module.sass";

interface Props {
  uploadId: number;
  clientId: number;
  data: SpecificPlan[];
  columns: readonly MediaPlanMultiColumn[];
  plans: Plans;
  filteredOptions: Option[];
  selectedPlan?: string;
  getRef: () => RefObject<HTMLAnchorElement>;
  onDownload: () => void;
  onSelectPlan: (planKey: string) => void;
  setFilteredOptions: Dispatch<SetStateAction<Option[]>>;
}

export default function MediaPlanMultiTable({
  uploadId,
  data,
  columns: allColumns,
  plans,
  filteredOptions,
  selectedPlan,
  getRef,
  onDownload,
  onSelectPlan,
  setFilteredOptions,
}: Props) {
  const dispatch = useAppDispatch();
  const { customMediaPlanError, mediaPlanSources } = useAppSelector(
    (state) => state.mediaplan,
  );
  const { ToastContainer, notifyUploading } = useNotify();
  const navigate = useNavigate();

  const [columns, setColumns] = useState<MediaPlanMultiColumn[]>([]);
  const [isFilterModalOpen, setIsFilterModalOpen] = useState(false);
  const [selectedColumnId, setSelectedColumnId] =
    useState<MediaPlanMultiColumnId>();
  const [filteredBy, setFilteredBy] = useState<MediaPlanMultiColumnId[]>([]);
  const [rows, setRows] = useState<(RecordT & { isSelected: boolean })[]>([]);
  const [initialFilteredData, setInitialFilteredData] = useState<
    FilteredValue[]
  >([]);
  const [filteredData, setFilteredData] = useState<FilteredValue[]>([]);
  const [isNeedToFilter, setIsNeedToFilter] = useState(false);
  const [activeSettings, setActiveSettings] = useState<number | null>(null);

  const shouldRefreshData = useRef(false);

  const splittedColumns = useMemo(() => {
    return splitArrayById(columns, filteredOptions);
  }, [columns, filteredOptions]);

  const onSave = (values: Record<string, boolean>) => {
    setFilteredData((previousState) => [
      ...previousState.filter((category) => category.key !== selectedColumnId),
      ...(previousState.find((category) => category.key === selectedColumnId)
        ? [
            {
              key:
                previousState.find(
                  (category) => category.key === selectedColumnId,
                )?.key ?? FILTERED_KEYS[0],
              values: Object.keys(values).map((key) => ({
                value: key,
                isSelected: values[key],
              })),
            },
          ]
        : []),
    ]);

    setIsNeedToFilter(true);
    setIsFilterModalOpen(false);

    if (selectedColumnId) {
      setFilteredBy((previousState) => [
        ...new Set([...previousState, selectedColumnId]),
      ]);
    }
  };

  const onModalClose = () => {
    setIsFilterModalOpen(false);
    setSelectedColumnId(undefined);
  };

  const selectPlan = async (plan: string) => {
    onSelectPlan(plan);

    let currentPlanKey = Object.keys(plans).find(
      (planKey) => plans[planKey].label === plan,
    );

    if (currentPlanKey === "opt") currentPlanKey = "optimal";
    if (currentPlanKey) {
      await dispatch(
        postDuplicateMediaPlanToCustom({
          ml_output_upload_id: uploadId,
          ml_output_media_plan_type: currentPlanKey,
        }),
      );
      dispatch(fetchMediaPlanMulti(uploadId));
    }
  };

  const getPlansHeaders = (plans: Plans): JSX.Element => {
    const availablePlans = data
      .map((planData) => planData.plan)
      .filter((planData) => {
        const visiblePlan = filteredOptions.find(
          (filteredPlan) => filteredPlan.value === planData,
        );
        if (visiblePlan && visiblePlan.isSelected) {
          return true;
        }
        return false;
      });

    return (
      <>
        {availablePlans.map((name) => (
          <TableCell
            key={name}
            colSpan={4}
            className={`!py-6 !px-5 rounded-t-lg`}
            style={{
              backgroundColor: plans[name].bgColor,
              color: plans[name].textColor,
            }}
          >
            <div className="flex flex-row">
              {plans[name].label !== "Утвержденный" ? (
                <img
                  src={
                    selectedPlan === plans[name].label
                      ? "/selected-plan-button.svg"
                      : "/plan-button.svg"
                  }
                  alt="plan button"
                  className={styles.plan_button}
                  onClick={() => selectPlan(plans[name].label)}
                />
              ) : null}
              <div>
                <p className="text-sm font-bold">{plans[name].label}</p>
                <p className="text-[12px]">{plans[name].description}</p>
              </div>
            </div>
          </TableCell>
        ))}
      </>
    );
  };

  const getColumnsNumber = () => data.length * 4 + 4;

  useEffect(() => {
    if (customMediaPlanError) {
      notifyUploading(customMediaPlanError, "error");
    }
  }, [customMediaPlanError, notifyUploading]);

  useEffect(() => {
    if (data.length) {
      const commonHeadersCount = 4;
      const specificPlanHeadersCount = 4;
      const columnsCount =
        commonHeadersCount + data.length * specificPlanHeadersCount;

      setColumns([...allColumns.slice(0, columnsCount)]);
    }
  }, [data, allColumns]);

  useEffect(() => {
    if (data && data.length) {
      setRows(
        data[0].data.map((row) => {
          const transformedRow: RecordT = INITIAL_EMPTY_ROW;
          Object.keys(row).forEach((key) => {
            transformedRow[key] = row[key];
          });
          return { ...transformedRow, need: getRandomNeed(), isSelected: true };
        }),
      );
    }
  }, [data, dispatch]);

  const [initiliazed, setInitiliazed] = useState(false);

  useEffect(() => {
    if (
      !initiliazed &&
      data[0].data.length < data[1].data.length &&
      isChosenMoreThanCustom(filteredOptions)
    ) {
      const emptyRows: (RecordT & { isSelected: boolean })[] = [...rows];
      for (let i = rows.length; i < data[1].data.length; i++) {
        emptyRows.push({
          budgets: 0,
          callbacks_count: 0,
          isSelected: true,
          client_id: 0,
          need: 0,
          city: data[1].data[i].city,
          profile: data[1].data[i].profile,
          data: [...data[1].data[i].data],
          factors_1_count: 0,
          factors_2_count: 0,
          factors_3_count: 0,
        });
      }
      setRows(emptyRows);
      setInitiliazed(true);
    } else if (
      !isChosenMoreThanCustom(filteredOptions) &&
      rows.length > data[0].data.length
    ) {
      const noEmptyRows = rows.splice(0, data[0].data.length);
      setRows(noEmptyRows);
      setInitiliazed(false);
    }
  }, [data, rows, filteredOptions, initiliazed]);

  useEffect(() => {
    if (filteredData && selectedColumnId) {
      const isChange = isObjectChanged(
        initialFilteredData.find(({ key }) => key === selectedColumnId),
        filteredData.find(({ key }) => key === selectedColumnId),
      );

      if (!isChange) {
        setFilteredBy((filters) =>
          filters.filter((filter) => filter !== selectedColumnId),
        );
      }
    }
  }, [filteredData, initialFilteredData, selectedColumnId]);

  useEffectOnce(() => {
    const filteredData = FILTERED_KEYS.map((field) => ({
      key: field,
      values: [
        ...new Set(
          rows.map((row) =>
            field === "region" ? row.data[0].region : row[field],
          ),
        ),
      ].map((value) => ({
        value,
        isSelected: true,
      })),
    }));

    setFilteredData(filteredData);
    setInitialFilteredData(filteredData);
  }, !!rows.length);

  useEffect(() => {
    if (filteredData && isNeedToFilter) {
      setRows(filterData(rows, filteredData));
      setIsNeedToFilter(false);
    }
  }, [filteredData, isNeedToFilter, rows]);

  useEffect(() => {
    if (activeSettings === null && shouldRefreshData.current) {
      dispatch(fetchMediaPlanMulti(uploadId));
      shouldRefreshData.current = false;
    }
  }, [activeSettings, dispatch, uploadId]);

  const selectFilteredPlans = (planValue: string, isSelected: boolean) => {
    if (
      filteredOptions.filter((opt) => opt.isSelected).length === 1 &&
      !isSelected
    ) {
      return;
    }
    setFilteredOptions((prevState) =>
      prevState.map((plan) =>
        plan.value === planValue ? { ...plan, isSelected } : plan,
      ),
    );
  };

  return (
    <>
      <Paper className={commonStyles.table}>
        <TableContainer
          className={classNames(
            commonStyles.table_container,
            styles.table_mediaPlan,
          )}
        >
          <Table stickyHeader className=" whitespace-nowrap">
            <TableHead className="sticky top-0">
              <TableRow className="!bg-red-500">
                <TableCell className="!border-b-0" colSpan={4}>
                  <section
                    className={classNames(
                      commonStyles.heading,
                      "flex gap-6 items-center",
                    )}
                  >
                    <H1 color="#0C3953">
                      Медиаплан №{uploadId} <HiddenLink ref={getRef()} />
                      <MUIIconButton
                        icon={
                          <DownloadIcon
                            className={commonStyles.download}
                            color="success"
                          />
                        }
                        onClick={onDownload}
                      />
                    </H1>
                    <MultipleSelect
                      options={filteredOptions}
                      setSelect={(selectedValue: string, isSelected: boolean) =>
                        selectFilteredPlans(selectedValue, isSelected)
                      }
                    />
                  </section>
                </TableCell>
                {getPlansHeaders(plans)}
              </TableRow>
              <TableRow>
                {Object.keys(splittedColumns)
                  .sort((a, b) => {
                    if (a === "common") return -1;
                    if (b === "common") return 1;
                    return parseInt(a) - parseInt(b);
                  })
                  .map((columnKey) =>
                    splittedColumns[columnKey].map(
                      (column: MediaPlanMultiColumn, index: number) => {
                        return (
                          <TableCell
                            key={`${index}-${column.id}`}
                            className={classNames(
                              "!text-xxs !py-[9px] !leading-[14px] line-clamp-2 !border-none !text-grey-600 whitespace-normal",
                              {
                                "cursor-pointer":
                                  index < 3 && columnKey === "common",
                                "min-w-[150px]":
                                  index < 3 && columnKey === "common",
                                "max-w-[110px]": index === 3,
                              },
                            )}
                            onClick={() => {
                              if (index < 3 && columnKey === "common") {
                                setIsFilterModalOpen(true);
                                setSelectedColumnId(column.id);
                              }
                            }}
                          >
                            {column.label}
                            {index < 3 && columnKey === "common" && (
                              <FilterButton
                                isActive={
                                  column.id === selectedColumnId ||
                                  filteredBy.includes(column.id)
                                }
                              />
                            )}
                          </TableCell>
                        );
                      },
                    ),
                  )}
              </TableRow>
              <TableRow>
                <TableCell
                  className={classNames(
                    "!py-2  !text-[#fff] box-border !font-semibold !bg-[#314660]",
                  )}
                  colSpan={2}
                ></TableCell>
                <TableCell
                  className={
                    "!text-[13px] leading-[20px] !py-2 !text-[#fff] !font-semibold !bg-[#314660]"
                  }
                >
                  Итого:
                </TableCell>
                <TableCell
                  className={classNames(
                    "!text-xxs !py-2 !text-[#fff] !font-semibold !bg-[#314660]",
                  )}
                ></TableCell>
                {data
                  .filter(
                    (plan) =>
                      filteredOptions.find(
                        (option) => option.value === plan.plan,
                      )?.isSelected,
                  )
                  .map((plan) => {
                    return (
                      <Fragment key={plan.plan}>
                        <TableCell
                          className={classNames(
                            "!text-xxs !py-2 !font-semibold",
                          )}
                          style={{
                            backgroundColor: plans[plan.plan].bgColor,
                            color: plans[plan.plan].textColor,
                          }}
                        >
                          {getLeadCountRange(
                            plan.summary?.callbacks_count,
                            0.8,
                            1.2,
                          )}
                        </TableCell>
                        <TableCell
                          className={classNames(
                            "!text-xxs !py-2 !font-semibold",
                          )}
                          style={{
                            backgroundColor: plans[plan.plan].bgColor,
                            color: plans[plan.plan].textColor,
                          }}
                        >
                          {getLeadBudgetRange(
                            plan.summary?.budgets /
                              plan.summary?.callbacks_count,
                            0.8,
                            1.2,
                          )}
                        </TableCell>
                        <TableCell
                          className={classNames(
                            "!text-xxs !py-2 !font-semibold",
                            "text-nowrap",
                          )}
                          style={{
                            backgroundColor: plans[plan.plan].bgColor,
                            color: plans[plan.plan].textColor,
                          }}
                        >
                          {new Intl.NumberFormat("ru-RU", {
                            style: "currency",
                            currency: "RUB",
                          }).format(plan.summary?.budgets)}
                        </TableCell>
                        <TableCell
                          className={classNames(
                            "!text-xxs !py-2 !font-semibold",
                          )}
                          style={{
                            backgroundColor: plans[plan.plan].bgColor,
                            color: plans[plan.plan].textColor,
                          }}
                        >
                          {Math.floor(plan.summary?.callbacks_count * 0.15)}
                        </TableCell>
                      </Fragment>
                    );
                  })}
              </TableRow>
            </TableHead>
            <TableBody>
              {rows
                .filter((row) => row.isSelected)
                .map((row, id) => (
                  <Fragment key={id}>
                    <TableRow
                      className={styles.tableRow}
                      role="checkbox"
                      tabIndex={-1}
                    >
                      <TableCell
                        className={classNames(
                          "!text-xxs !text-[#0C3953] !w-[165px] !py-[7px] !pl-4 !pr-2 h-[50px] leading-[14px] capitalize whitespace-normal",
                          activeSettings === id && "!bg-[#EDF2FC] !border-none",
                        )}
                      >
                        {handleValue(row.data[0].region, "region", {
                          rowIndex: id,
                          activeSettings,
                          setActiveSettings,
                        })}
                      </TableCell>
                      <TableCell
                        className={classNames(
                          "!text-xxs !text-[#0C3953] !w-[165px] whitespace-normal !py-[7px] leading-[14px]",
                          activeSettings === id && "!bg-[#EDF2FC] !border-none",
                        )}
                      >
                        {row.city}
                      </TableCell>
                      <TableCell
                        className={classNames(
                          "!text-xxs !text-[#0C3953] !py-[7px] !w-[165px] leading-[14px] whitespace-normal",
                          activeSettings === id && "!bg-[#EDF2FC] !border-none",
                        )}
                      >
                        {row.profile}
                      </TableCell>
                      <TableCell
                        className={classNames(
                          "!text-xxs !text-[#0C3953] !w-[60px] !py-[7px] leading-[14px]",
                          activeSettings === id && "!bg-[#EDF2FC] !border-none",
                        )}
                      >
                        {row.need || "-"}
                      </TableCell>
                      {data
                        .filter(
                          (plan) =>
                            filteredOptions.find(
                              (option) => option.value === plan.plan,
                            )?.isSelected,
                        )
                        .map((plan) => (
                          <Fragment key={plan.plan}>
                            <TableCell
                              className={classNames(
                                "!text-xxs !text-[#0C3953] !w-[80px] !py-[7px] leading-[14px]",
                                activeSettings === id &&
                                  "!bg-[#EDF2FC] !border-none",
                              )}
                              style={{
                                backgroundColor: plans[plan.plan].bgDataColor,
                              }}
                            >
                              {getLeadCountRange(
                                plan.data[id]?.callbacks_count,
                                0.8,
                                1.2,
                              )}
                            </TableCell>
                            <TableCell
                              className={classNames(
                                "!text-xxs !text-[#0C3953] !w-[100px] !py-[7px] leading-[14px]",
                                activeSettings === id &&
                                  "!bg-[#EDF2FC] !border-none",
                              )}
                              style={{
                                backgroundColor: plans[plan.plan].bgDataColor,
                              }}
                            >
                              {getLeadBudgetRange(
                                plan.data[id]?.budgets /
                                  plan.data[id]?.callbacks_count || 0,
                                0.8,
                                1.2,
                              )}
                            </TableCell>
                            <TableCell
                              className={classNames(
                                "!text-xxs !text-[#0C3953] !py-[7px] !w-[100px] leading-[14px]",
                                activeSettings === id &&
                                  "!bg-[#EDF2FC] !border-none",
                              )}
                              style={{
                                backgroundColor: plans[plan.plan].bgDataColor,
                              }}
                            >
                              {plan.data[id]?.budgets || "-"}
                              {plan.data[id]?.budgets ? " ₽" : ""}
                            </TableCell>
                            <TableCell
                              className={classNames(
                                "!text-xxs !text-[#0C3953]  !py-[7px] leading-[14px]",
                                activeSettings === id &&
                                  "!bg-[#EDF2FC] !border-none",
                              )}
                              style={{
                                backgroundColor: plans[plan.plan].bgDataColor,
                              }}
                            >
                              {Math.round(
                                plan.data[id]?.callbacks_count * 0.15,
                              ) || "-"}
                            </TableCell>
                          </Fragment>
                        ))}
                    </TableRow>
                    <CSSTransition
                      in={activeSettings === id}
                      timeout={300}
                      unmountOnExit
                      classNames={{
                        enter: styles.rowExpandEnter,
                        enterActive: styles.rowExpandEnterActive,
                        exit: styles.rowExpandExit,
                        exitActive: styles.rowExpandExitActive,
                      }}
                    >
                      <SettingsRow
                        editable={filteredOptions.some(
                          (opt) => opt.value === "custom" && opt.isSelected,
                        )}
                        setActiveSettings={setActiveSettings}
                        uploadId={uploadId}
                        columnsNumber={getColumnsNumber()}
                        data={data[0].data[id] || data[1].data[id]}
                        shouldRefresh={shouldRefreshData}
                        sources={mediaPlanSources || []}
                      />
                    </CSSTransition>
                  </Fragment>
                ))}
            </TableBody>
          </Table>
        </TableContainer>
        <Button
          className={styles.toPlanBtn}
          onClick={() => navigate(OBJECT_LIST_ROUTE)}
        >
          В план публикаций
        </Button>
      </Paper>
      <FilterPanel
        isOpen={isFilterModalOpen}
        columnName={
          columns.find(({ id }) => id === selectedColumnId)?.label ?? ""
        }
        data={
          selectedColumnId
            ? filteredData.find((item) => item.key === selectedColumnId)
                ?.values || []
            : []
        }
        onClose={onModalClose}
        onSave={onSave}
      />
      <ToastContainer />
    </>
  );
}
