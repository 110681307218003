// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.styles_table__q2Zw9 {
  overflow: hidden;
  box-shadow: none !important;
}

.styles_tableHead__GxQ4R {
  border-bottom: 1px solid #C6CED8 !important;
  color: #54687D !important;
  line-height: 16px !important;
  padding: 13px 16px !important;
}

.styles_dataCell__MZwDs {
  padding: 8px 16px !important;
  border-bottom: 1px solid #f2f2f2 !important;
  color: #0C3953 !important;
}
.styles_dataCell__MZwDs a {
  color: #217EC6 !important;
}

.styles_pagination__hoKsP {
  margin-top: 18px;
}

.styles_hoverable__FxuqU {
  cursor: pointer;
}

.styles_table_container__qohfj {
  overflow-y: auto;
  max-height: calc(100vh - 228px);
}

.styles_loader_container__gF7BL {
  height: calc(100vh - 150px);
  display: flex;
  align-items: center;
  justify-content: center;
}

.styles_caption__wtRQB {
  line-height: 20px !important;
}

.styles_tableRow__REDbe:hover > .styles_dataCell__MZwDs {
  background-color: rgba(0, 0, 0, 0.04) !important;
}

.styles_tableHead__GxQ4R,
.styles_dataCell__MZwDs {
  font-size: 13px !important;
  line-height: 14px !important;
}

.styles_captionIndent__Pc7c0 {
  top: 47px !important;
}

.styles_gray__FoAZv {
  background-color: #F9F9F9 !important;
}

.styles_grayer__7LzNJ {
  background-color: #F3F3F3 !important;
}`, "",{"version":3,"sources":["webpack://./src/components/tables/common-styles.module.sass","webpack://./src/components/tables/MediaPlanUploadTable/styles.module.sass"],"names":[],"mappings":"AAGA;EACE,gBAAA;EACA,2BAAA;ACFF;;ADIA;EACE,2CAAA;EACA,yBAAA;EACA,4BAAA;EACA,6BAAA;ACDF;;ADGA;EACE,4BAAA;EACA,2CAAA;EACA,yBAAA;ACAF;ADEE;EACE,yBAAA;ACAJ;;ADEA;EACE,gBAAA;ACCF;;ADCA;EACE,eAAA;ACEF;;ADAA;EACE,gBAAA;EACA,+BAAA;ACGF;;ADDA;EACE,2BAAA;EACA,aAAA;EACA,mBAAA;EACA,uBAAA;ACIF;;AAnCA;EACE,4BAAA;AAsCF;;AAnCE;EACE,gDAAA;AAsCJ;;AApCA;;EAEE,0BAAA;EACA,4BAAA;AAuCF;;AArCA;EACE,oBAAA;AAwCF;;AAtCA;EACE,oCAAA;AAyCF;;AAvCA;EACE,oCAAA;AA0CF","sourcesContent":["$padding-top: 13px\n$border-bottom-width: 1px\n\n.table\n  overflow: hidden\n  box-shadow: none !important\n\n.tableHead\n  border-bottom: 1px solid #C6CED8 !important\n  color: #54687D !important\n  line-height: 16px !important\n  padding: $padding-top 16px !important\n\n.dataCell\n  padding: 8px 16px !important\n  border-bottom: $border-bottom-width solid #f2f2f2 !important\n  color: #0C3953 !important\n\n  & a\n    color: #217EC6 !important\n\n.pagination\n  margin-top: 18px\n\n.hoverable\n  cursor: pointer\n\n.table_container\n  overflow-y: auto\n  max-height: calc(100vh - 228px)\n\n.loader_container\n  height: calc(100vh - 150px)\n  display: flex\n  align-items: center\n  justify-content: center\n","@import \"../common-styles.module\"\n\n$caption-line-height: 20px\n\n.caption\n  line-height: $caption-line-height !important\n\n.tableRow\n  &:hover > .dataCell\n    background-color: rgba(0, 0, 0, 0.04) !important\n\n.tableHead,\n.dataCell\n  font-size: 13px !important\n  line-height: 14px !important\n\n.captionIndent\n  top: $padding-top * 2 + $caption-line-height + $border-bottom-width !important\n\n.gray\n  background-color: #F9F9F9 !important\n\n.grayer\n  background-color: #F3F3F3 !important\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"table": `styles_table__q2Zw9`,
	"tableHead": `styles_tableHead__GxQ4R`,
	"dataCell": `styles_dataCell__MZwDs`,
	"pagination": `styles_pagination__hoKsP`,
	"hoverable": `styles_hoverable__FxuqU`,
	"table_container": `styles_table_container__qohfj`,
	"loader_container": `styles_loader_container__gF7BL`,
	"caption": `styles_caption__wtRQB`,
	"tableRow": `styles_tableRow__REDbe`,
	"captionIndent": `styles_captionIndent__Pc7c0`,
	"gray": `styles_gray__FoAZv`,
	"grayer": `styles_grayer__7LzNJ`
};
export default ___CSS_LOADER_EXPORT___;
