import { MediaPlanMultiData, Plans } from "common/const";
import { Option } from "components/UI/MultipleSelect";
import { SpecificPlan } from "services/store/reducers/media/types";

export function getTotal(
  type: keyof MediaPlanMultiData,
  from: MediaPlanMultiData[],
) {
  return parseFloat(
    from.reduce((acc, cur) => acc + +cur[type], 0).toFixed(1),
  ).toString();
}

export const transformPlansObjectToArray = (
  allPlans: Plans,
  actualPlans: SpecificPlan[],
): Option[] => {
  const hasCustomPlan = actualPlans.some((plan) => plan.plan === "custom");
  return actualPlans.map((actualPlan) => {
    return {
      name: allPlans[actualPlan.plan].label,
      value: actualPlan.plan,
      isSelected: hasCustomPlan ? actualPlan.plan === "custom" : true,
    };
  });
};
