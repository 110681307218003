import { useEffect } from "react";

import { Response } from "services/store/reducers/recruitment/types";
import { PostHvAvDataResponse } from "services/store/reducers/ws-data/types";

interface ISetMessages {
  data: (Response & PostHvAvDataResponse) | null;
  notifyUploading: (fileName: string, status: "success" | "error") => void;
  setUploadMessage: (message: { message: string; success: boolean }) => void;
  error: string | null;
  excelResponse?: boolean;
}
export default function useSetMessages({
  data,
  error,
  excelResponse = false,
  notifyUploading,
  setUploadMessage,
}: ISetMessages) {
  useEffect(() => {
    if (data && data.response?.error) {
      notifyUploading("", "error");
      setUploadMessage({
        message: `${excelResponse ? data.response.error : data.error}`,
        success: false,
      });
    } else if (data && data.response?.message) {
      notifyUploading("", "success");
      setUploadMessage({
        message: `${excelResponse ? data.response.message : data.message}`,
        success: true,
      });
    }
    if (error && !data) {
      setUploadMessage({
        message: `${error}`,
        success: false,
      });
    }
  }, [notifyUploading, data, setUploadMessage, error, excelResponse]);
}
