import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { CandidatesData, Response, StateType } from "./types";
import { fetchCandidatesData, postCandidatesData } from "./actions";

const initialState: StateType = {
  loading: false,
  error: null,
  data: null,
  uploadCandidatesData: null,
  uploadCandidatesLoading: false,
  uploadCandidatesError: null,
};

const issuedSlice = createSlice({
  name: "issued",
  initialState,
  reducers: {
    clearData(state) {
      state.data = null;
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchCandidatesData.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(
        fetchCandidatesData.fulfilled,
        (state, action: PayloadAction<CandidatesData[]>) => {
          state.loading = false;
          state.data = action.payload;
        },
      )
      .addCase(fetchCandidatesData.rejected, (state, action) => {
        state.loading = false;
        if (typeof action.payload === "string")
          state.error = action.payload;
      });
    builder.addCase(postCandidatesData.pending, state => {
      state.uploadCandidatesLoading = true;
      state.uploadCandidatesError = null;
    })
    .addCase(postCandidatesData.fulfilled, (state, action) => {
      state.uploadCandidatesData = action.payload as Response;
      state.uploadCandidatesLoading = false;
    })
    .addCase(postCandidatesData.rejected, (state, action) => {
      state.uploadCandidatesLoading = false;
      if (typeof action.payload === "string")
        state.uploadCandidatesError = action.payload;
    });
  },
});

export const {clearData} = issuedSlice.actions;
export const issuedReducer = issuedSlice.reducer;
